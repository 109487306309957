import React, { Key, createRef, useEffect, useRef, useState } from 'react';
import { Layout,Breadcrumb,Tag, Dropdown, Menu, message, Modal, Button, Space, Carousel,Image, Avatar, Form, Input, InputNumber, Tooltip, } from 'antd';
import { Route, useHistory,Link, } from 'react-router-dom';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BulbOutlined,
  LogoutOutlined,
  EditOutlined,
} from '@ant-design/icons';
import './App.less';
import { LOGIN_USER_ROLE, ROLE_UN_LOGIN } from './consts';
import MyMenu from './menuRoute/myMenu';
import MyRoute from './menuRoute/myRoute';
import { exactMenuRoutes, menuRoutes, rootSubmenuKeys } from './menuRoute';
import KeepAlive, { AliveScope, useAliveController } from 'react-activation'
import { InfoData, UserGuideListDTO, queryUserGuideList, queryUserRole, updateUserInfo } from './api';
import { CarouselRef } from 'antd/lib/carousel';
import Login from './pages/login';
const { Header, Sider, Content } = Layout;
const {Item} = Form;

interface FormData {
  weChatName:string,
  phoneNum:number,
}

function App(props: { location: { pathname: string; }; }) {
  const aliveController = useAliveController()
  const [collapsed, setCollapsed] = useState(false);
  const [employee, setEmployee] = useState<string>();
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedKey, setSelectedKey] = useState<string>();
  const history = useHistory();
  const [bread,setBread] = useState<{path:string,title:string[]}>()
  const [tag,setTag] = useState<{path:string,title:string[]}[]>(JSON.parse(sessionStorage.getItem('tagItem') || '[]') || [])
  const [recordTag,setRecordTag] = useState<{path:string,title:string[]}>()
  const pathname_one = props.location.pathname.split('/')[1];
  const pathname_two = props.location.pathname.split('/')[2];
  const [isKeepAlive,setIsKeepAlive] = useState<boolean>(true)
  const [tagWidth,setTagWidth] = useState<boolean>(true)
  const [userGuide,setUserGuide] = useState(false)
  const [userGuideList, setUserGuideList] = useState<UserGuideListDTO[]>([]);
  const [current,setCurrent] = useState(0);
  const [modalVisible,setModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm<FormData>();
  const [disabled,setDisabled] = useState<boolean>(true);
  const [infoData,setInfoData] = useState<{nickName?:string,phoneNum?:number,id:Key,avatar?:string,}>(JSON.parse( sessionStorage.getItem('userInfo') || 'null'));
  
  const roleMenu = (role: string) => {
    const oneMenu = exactMenuRoutes[role].oneMenuRoute as string;
    const towMenu = exactMenuRoutes[role].twoMenuRoute as string;

    if(pathname_one && !pathname_two){
      setOpenKeys([]);
      setSelectedKey(pathname_one);
    }else{
      setOpenKeys(pathname_one? [pathname_one]: [oneMenu]);
      setSelectedKey(pathname_one? pathname_two: towMenu);
    }
  }

  const instance = useRef(null);
  const calcTableScrollHeight = (divDom:Element)=>{
    const divEle = divDom.getBoundingClientRect().width
    const divH = divDom.getBoundingClientRect().height
   let arrWidth= Array.from(divDom.childNodes).reduce((per,cur:any)=>{
      return per+cur.getBoundingClientRect().width
    },0)
    if(divH > 45) {
      aliveController.drop(tag.shift()?.path || '')
      setTag(tag)
    }
    setTagWidth(arrWidth  > divEle -330  ? false : true)
  }

  const getUserGuideList = async()=>{
    const {list} = await queryUserGuideList({
      pageNo: 1,
      pageSize: 9999,
      menuUrl:bread?.path.slice(1,bread?.path.length)
    });
    if(list.length > 0) {
      setUserGuide(!userGuide)
    }else{
      message.warning('暂未设置！')
    }
    if(userGuideList.length === 0 || userGuideList[0].id !== list[0].id ){
      setUserGuideList(list)
      setCurrent(0)
    }
    
  }

  useEffect(()=>{
    const dom = instance.current
    if(dom) calcTableScrollHeight(dom)
  },[tag.length,bread])


  useEffect(()=>{
    const emp = sessionStorage.getItem(LOGIN_USER_ROLE) as string;
    emp && setEmployee(emp);
    roleMenu(emp);
    const newTag = menuRoutes.find(i=>i.key === openKeys[0])?.childRoutes.find(r=>r.path === selectedKey)?.bread
    if(newTag && history.location.pathname === '/' && !tag.some(item=>item.path === newTag.path)){
      tag.push(newTag)
      setBread(newTag)
      setTag(tag)
    }
  },[employee, pathname_one, pathname_two, collapsed]);

  useEffect(()=>{
    form.setFieldsValue({
      weChatName: infoData?.nickName || undefined,
      phoneNum: infoData?.phoneNum || undefined,
    })
  },[infoData]);

  useEffect(()=>{
    if(modalVisible){
      setInfoData(JSON.parse(sessionStorage.getItem('userInfo') || ''))
    }
  },[modalVisible])

  const onOpenChange = (keys: any[] ) => {
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    const rootRoute = menuRoutes.find(item => item.key === latestOpenKey);
    const childRoute = rootRoute?.childRoutes;
    if(childRoute?.length === 1){
      setOpenKeys([rootRoute?.key as string]);
      setSelectedKey(childRoute[0].path);
      history.push({pathname: `/${rootRoute?.key}/${childRoute[0].path}`,})
  }
  }

  const getBread = (Bread:{path:string,title:string[]})=>{
    
    if((Bread.path === history.location.pathname &&  !(bread?.path === history.location.pathname) )){
      setBread(Bread)
      setIsKeepAlive(tag.some(item =>item.path === history.location.pathname))
      if(!tag.some(item =>item.path === Bread.path)){
        tag.push(Bread)
        setTag(tag)
        if(!tagWidth){
          aliveController.drop(tag.shift()?.path || '')
        }
      }
    }
  }
  useEffect(()=>{
    sessionStorage.setItem('tagItem',JSON.stringify(tag))
    setIsKeepAlive(tag.some(item =>item.path === history.location.pathname))
  },[tag.length])
  let ele = <Content
  className='site-layout-background'
  style={{
    margin: ' 0 16px',
    padding: '0 24px',
    minHeight: 280,
  }}
>
  <Route path='/' component={MyRoute}/>
</Content>

  let dom 
  if (isKeepAlive) {
    dom =<KeepAlive cacheKey="UNIQUE_ID" id={history.location.pathname} name={history.location.pathname}>
           {ele}
          </KeepAlive>
  }else{
    dom =ele
  }

  const handleMenuClick = (e:any)=>{
    if(recordTag){
      const index = tag.findIndex(item=>item.path === recordTag.path)
      const newIndex = tag.findIndex(item=>item.path === history.location.pathname)
      switch (e.key){
        case '1' : 
        if(index===0 && recordTag.path === history.location.pathname ){
          history.push({pathname:tag[index+1].path})
        }else if (recordTag.path === history.location.pathname && index > 0 ){
          history.push({pathname:tag[index-1].path})
        }
        setTag(pre=> pre.filter(item2=>item2.path!==recordTag.path))
          aliveController.drop(recordTag.path)
        break;
        case '2' : 
        setTag(pre=>pre.filter((item,i)=>{
          if(index <= newIndex) history.push({pathname:recordTag.path})
         return i <= index
        }))
        break;
        case '3' : 
        setTag(pre=>pre.filter((item,i)=>{
          if(index >= newIndex) history.push({pathname:recordTag.path})
         return i >= index
        }))
        break;
        case '4' : 
        setTag(pre => pre.filter(item2=>item2.path ===recordTag.path))
        history.push({pathname:recordTag.path})
        tag.filter(item=>item.path !== recordTag.path).forEach(item =>{
          aliveController.drop(item.path)
        })
        break;
      }
      
    }
    
  }

  const carRef = createRef<CarouselRef>()
  const next = () => {
    carRef.current?.next()
  }
  const prev = () => {
    carRef.current?.prev()
  }
  const goTo = (slideNumber:number) => {
    carRef.current?.goTo(slideNumber)
  }
  window.addEventListener("popstate",function(e){
    setUserGuide(false)
  })

  const handleOk = async(data:InfoData)=>{
    const {data:res} = await updateUserInfo(data);
    setInfoData({
      nickName:res?.weChatName,
      phoneNum:res?.phoneNum,
      id: res?.accountNo,
      avatar:res?.uri,
    })
    form.resetFields();
  }

  const menu = (
    <Menu className='contextMenu' onClick={handleMenuClick}>
      <Menu.Item key="1" disabled={tag.length === 1}>关闭</Menu.Item>
      <Menu.Item key="2" disabled={tag.length === 1 || tag.findIndex(item=>item.path === recordTag?.path) === tag.length-1 }>关闭右侧菜单</Menu.Item>
      <Menu.Item key="3" disabled={tag.length === 1 || tag.findIndex(item=>item.path === recordTag?.path) === 0}>关闭左侧菜单</Menu.Item>
      <Menu.Item key="4" disabled={tag.length === 1}>关闭其他</Menu.Item>
    </Menu>
  );
  return (
    <AliveScope>
      {
        sessionStorage.getItem(LOGIN_USER_ROLE) === ROLE_UN_LOGIN ? (
          <Route path='/login' component={Login} />
        ):(
          <>
  <Layout className='app'>
    <Sider className='app-sider' trigger={null} collapsible collapsed={collapsed}>
          <MyMenu
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={[selectedKey as string]}
            getBread={getBread}
          />
    </Sider>
  <Layout className='site-layout'>
  <Header className='site-layout-header-box' style={{ padding: 0 }}>
    <div className='site-layout-header'>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: () => setCollapsed(!collapsed),
      })}
      <Breadcrumb style={{'marginBottom': '5px'}} className='bread'>
        {
          bread?.title.map((item, i)=>(
            <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
      <div className='avatar-wrapper'>
        <Avatar 
          className='img' 
          src={infoData?.avatar} 
        />
        <div className='update-info'>
          <Avatar size={50} src={infoData?.avatar} />
          <Tooltip title={infoData?.nickName}>
            <span className='info-name'>{infoData?.nickName?.slice(0,6)}</span>
          </Tooltip>
          <div>
            <EditOutlined className='icon' />
            <span onClick={()=>{
              setModalVisible(true);
            }}>编辑资料</span>
          </div>
          <div>
          <LogoutOutlined className='icon' />
          <span
            onClick={async ()=>{
              sessionStorage.removeItem('token');
              await queryUserRole();
            }}
          >退出登录</span>
          </div>
        </div>
      </div>
      <Button type="primary" icon={<BulbOutlined />} className='site-layout-header-btn' onClick={()=>{
            getUserGuideList()
          }}>
            用户指引
      </Button>
    </div>
  </Header>
  <div className='site-layout-tag' ref={instance}>
  {
    tag.map((item,i)=>{
      return (
        (
          <Dropdown key={i} overlay={menu} trigger={['contextMenu']} onVisibleChange={()=>setRecordTag(item)}>
            <Tag
              color={ history.location.pathname === item.path || history.location.pathname === '/' ?"#108ee9":'' }
              // closable={tag.length <= 1 ? false : true}
              style={{'cursor':'pointer'}}
              onClose={ async(e)=>{
                // e.preventDefault();
                // if(i===0 && item.path === history.location.pathname){
                //   history.push({pathname:tag[i+1].path})
                // }else if (item.path === history.location.pathname && i > 0){
                //   history.push({pathname:tag[i-1].path})
                // }
                // if(tag.length > 1){
                //   setTag(pre=> pre.filter(item2=>item2.path!==item.path))
                //   aliveController.drop(item.path)
                // }
              }}>
              <Link style={{userSelect:'none'}} to={item.path} onClick={()=>{
              }}>{item.title[item.title.length - 1]}</Link>
            </Tag>
          </Dropdown>
        )
      )
    }
    )
  }
</div>
{dom}
</Layout>
</Layout>
<Modal style={{top:10}} 
  title={`${bread?.title[1]} (第${current+1}页 / 总共${userGuideList[0]?.detailList?.length}页)`} visible={userGuide}  className='user-guide-modal'
    onCancel={()=>setUserGuide(!userGuide)}
    footer={[
      <Button key="back" type="primary" onClick={prev} disabled={current === 0}>
        上一页
      </Button>,
      <Button key="submit" type="primary" onClick={next} disabled={  current === userGuideList[0]?.detailList?.length!-1}>
        下一页 
      </Button>,
      <Button key="close" type="primary" onClick={()=>setUserGuide(!userGuide)}>
        关闭 
      </Button>,
    ]}
  >
    <Space style={{'width':'100%','overflowX':'auto','justifyContent':`${userGuideList[0]?.detailList?.length &&  userGuideList[0]?.detailList.length > 7 ? 'left': 'center' }`}} className='user-guide-modal-tag-list'>
      {
        userGuideList[0]?.detailList?.map((item,i)=>(
          <Tag color={current === i ?'#f50' : '' }  className='user-guide-modal-tag' onClick={()=>goTo(i)}>{item.detailName}</Tag>
        ))
      }
    </Space>
    <Carousel 
      // dotPosition='top' 
      ref={carRef}
      infinite={false}
      draggable
      afterChange={(current)=>{
        setCurrent(current)
      }}
      >
        {
           userGuideList[0]?.detailList?.map(item=>(
            <div className='user-guide-box' >
              <Image height={450} className='user-guide-box-img' src={item.imgFile.url}/>
              <p className='user-guide-box-counter'>
                {item.pictureDesc}
              </p>
            </div>
          ))
        }
    </Carousel>
  </Modal>
  <Modal 
    visible={modalVisible}
    title='编辑'
    onCancel={()=>{
      setModalVisible(false);
    }}
    onOk={async ()=>{
      await form.validateFields();
      const {weChatName,phoneNum} = form.getFieldsValue();
      const data = {
        accountNo:infoData!.id,
        weChatName: weChatName === infoData?.nickName ? infoData?.nickName : weChatName,
        phoneNum: phoneNum === infoData?.phoneNum ? infoData?.phoneNum : phoneNum,
      }
      if(data?.phoneNum || data?.weChatName) {
        handleOk(data);
      }
      setModalVisible(false);
    }}
    okButtonProps={{ disabled}}
  >
    <Form name='info' form={form}>
      <Item 
        label='微信昵称' 
        name='weChatName'
        rules={[{
          required:true,message:'请输入昵称'
        },
        {
          validator:async(rule,val)=>{
            if(val.length >=13){
              throw new Error('昵称的长度最多12')
            }
          },
          message:'昵称的长度最多12'
        }
        ]}
      >
        <Input 
          style={{ width: '70%' }} 
          placeholder='请输入'
          onChange={(e)=>{
            if(e.target.value){
              setDisabled(false);
            }else {
              setDisabled(true);
            }
          }} 
        />
      </Item>
      <Item 
        label='手机号' 
        name='phoneNum'
        rules={[{
          validator:async(rule,val)=>{
            if(val){
              const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
              if(!reg.test(val)) {
                throw new Error('请输入正确手机号')
              }
            }
          },
          message:'请输入正确手机号'
        }]}
      >
        <InputNumber 
          style={{ width: '70%' }} 
          placeholder='请输入'
          onChange={(val)=>{
            if(val){
              setDisabled(false);
            }else {
              setDisabled(true);
            }
          }}
        />
      </Item>
    </Form>
  </Modal>
          </>
        )
      }
      <div className='footer-copyright'>
        备案号：
        <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备2023045492号-1</a >
      </div>
      
    </AliveScope>
  );
}

export default App;
