import { menuRoutes } from './index'
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { MyMenuProps}  from './index.d'
import { LOGIN_USER_ROLE } from '../consts';
import { useEffect, useState } from 'react';
import { VipGradeRole } from '../api';
const { SubMenu } = Menu;

export default function MyMenu ( props: MyMenuProps ) {
  const {
    openKeys, onOpenChange, selectedKeys,getBread,
  } = props;
  const [role, setRole] = useState<string>();

  useEffect(()=>{
    const emp = sessionStorage.getItem(LOGIN_USER_ROLE) as string;
    emp && setRole(emp);
  },[role]);
  const  speciallRole = [
    {
      id:!VipGradeRole,
      title:'会员评级',
    },
    {
      id:!JSON.parse(sessionStorage.getItem('userRole') || ''),
      title:'物品购买操作',
    },
  ]
  return (
    <Menu 
      className='app-menu' 
      theme='dark' 
      mode='inline' 
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={selectedKeys}
    >
      {
        (menuRoutes.filter(i =>{
         return  i.role.some(r => r === role)
        })).map(item => {
          const { key, childRoutes, icon, title } = item;
          return (
            <SubMenu key={key} title={title} icon={icon}>
              {
                (childRoutes.filter(i=>i.role.some(r => r === role)).filter(i=>{
                  const newRole = speciallRole.map(j=>{if(j.id) return j.title })
                  return !newRole.includes(i.title)
                })).map(term => {
                  const { path, title, icon,bread} = term;
                    getBread(bread)
                  return (
                    <Menu.Item key={path} icon={icon}>
                    <Link to={`/${key}/${path}`} 
                    >{title} </Link>
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
          )        
        })
      }
    </Menu>
  )
}