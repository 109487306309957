import loadable from '@loadable/component';
import { ROLE_ADMIN, ROLE_COMMODITY_SELECT, ROLE_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER, ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_SENIOR_EMPLOYEE, ROLE_SENIOR_SHIPPER, ROLE_SHIPPER, ROLE_WITHDRAW, ROLE_UN_LOGIN, ROLE_UN_DEVICE, ROLE_HIGH_GUEST } from '../consts';
import {
  SettingOutlined,
  TrophyOutlined,
  GiftOutlined,
  ProfileOutlined,
  BarChartOutlined,
  MoneyCollectOutlined,
  ContactsOutlined,
  CrownOutlined,
  ShopOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  ExceptionOutlined,
  UploadOutlined,
  AuditOutlined,
  SolutionOutlined,
  DesktopOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  AccountBookOutlined,
  CreditCardOutlined,
  BuildOutlined,
  BarcodeOutlined,
  PayCircleOutlined,
  ShoppingOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Menu, ExactMenuRoute } from './index.d';

// 配置一级菜单key
export const rootSubmenuKeys = [
  'welcome',
  'system',
  'game',
  'product',
  'order',
  'vip',
  'audit',
  'voucher',
  'operation',
];

// 配置 不同权限第一次进入项目的页面
export const exactMenuRoutes: Record<string, ExactMenuRoute> = {

  [ROLE_UN_LOGIN]: {
    // oneMenuRoute: '',
    // twoMenuRoute: '',
    component: loadable(() => import('../pages/login/index')),
  },
  [ROLE_UN_DEVICE]: {
    // oneMenuRoute: '',
    // twoMenuRoute: '',
    component: loadable(() => import('../pages/402/index')),
  },
  [ROLE_SENIOR_ADMIN]: {
    oneMenuRoute: 'game',
    twoMenuRoute: 'gameTemplate',
    component: loadable(() => import('../pages/game/GameDefine')),
  },
  [ROLE_HIGH_GUEST]: {
    oneMenuRoute: 'welcome',
    twoMenuRoute: 'control',
    component: loadable(() => import('../pages/Welcome')),
  },
  [ROLE_ADMIN]: {
    oneMenuRoute: 'game',
    twoMenuRoute: 'gameTemplate',
    component: loadable(() => import('../pages/game/GameDefine')),
  },
  [ROLE_EMPLOYEE]: {
    oneMenuRoute: 'game',
    twoMenuRoute: 'gameOnSale',
    component: loadable(() => import('../pages/game/GameOnSale')),
  },
  [ROLE_SENIOR_EMPLOYEE]: {
    oneMenuRoute: 'game',
    twoMenuRoute: 'gameOnSale',
    component: loadable(() => import('../pages/game/GameOnSale')),
  },
  [ROLE_SHIPPER]: {
    oneMenuRoute: 'order',
    twoMenuRoute: 'orderNotYetShipped',
    component: loadable(() => import('../pages/order/OrderNotYetShipped')),
  },
  [ROLE_SENIOR_SHIPPER]: {
    oneMenuRoute: 'order',
    twoMenuRoute: 'orderNotYetShipped',
    component: loadable(() => import('../pages/order/OrderNotYetShipped')),
  },
  [ROLE_COMMODITY_SELECT]: {
    oneMenuRoute: 'product',
    twoMenuRoute: 'productEdit',
    component: loadable(() => import('../pages/product/ProductEdit')),
  },
  [ROLE_EMPLOYEE_SENIOR_SHIPPER]: {
    oneMenuRoute: 'product',
    twoMenuRoute: 'productEdit',
    component: loadable(() => import('../pages/product/ProductEdit')),
  },
  [ROLE_OPERATOR]: {
    oneMenuRoute: 'operation',
    twoMenuRoute: 'dailyOperation',
    component: loadable(() => import('../pages/operation/DailyOperation')),
  },
  [ROLE_SENIOR_ADMIN_ADD]: {
    oneMenuRoute: 'game',
    twoMenuRoute: 'gameTemplate',
    component: loadable(() => import('../pages/game/GameDefine')),
  },
  [ROLE_WITHDRAW]: {
    oneMenuRoute: 'vip',
    twoMenuRoute: 'vipManage',
    component: loadable(() => import('../pages/vip/VipManage')),
  },
}


// 配置 其他菜单
export const menuRoutes: Menu[] = [
  {
    key: 'welcome',
    title: '控制台',
    icon: <DesktopOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_HIGH_GUEST],
    childRoutes: [
      {
        path: 'control',
        title: '控制台',
        bread: {
          path: '/welcome/control',
          title: ['首页', '控制台']
        },
        icon: <DesktopOutlined />,
        component: loadable(() => import('../pages/Welcome')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN]
      },
    ]
  },
  {
    key: 'system',
    title: '系统管理',
    icon: <SettingOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
    childRoutes: [
      {
        path: 'userGuide',
        title: '用户指引',
        bread: {
          path: '/system/userGuide',
          title: ['系统管理', '用户指引']
        },
        icon: <GiftOutlined />,
        component: loadable(() => import('../pages/system/UserGuide')),
        role: [ROLE_OPERATOR],
      },
      {
        path: 'awardsManage',
        title: '奖励管理',
        bread: {
          path: '/system/awardsManage',
          title: ['系统管理', '奖励管理']
        },
        icon: <GiftOutlined />,
        component: loadable(() => import('../pages/system/AwardsManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'giftManage',
        title: '赠予管理',
        bread: {
          path: '/system/giftManage',
          title: ['系统管理', '赠予管理']
        },
        icon: <GiftOutlined />,
        component: loadable(() => import('../pages/system/GiftManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'condition',
        title: '配置管理',
        bread: {
          path: '/system/condition',
          title: ['系统管理', '配置管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/system/Condition')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'posterManage',
        title: '广告管理',
        bread: {
          path: '/system/posterManage',
          title: ['系统管理', '广告管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/system/PosterManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'kitchenGodList',
        title: '厨神榜管理',
        bread: {
          path: '/system/kitchenGodList',
          title: ['系统管理', '厨神榜管理']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/system/KitchenGodList')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'rankingListManage',
        title: '排行榜管理',
        bread: {
          path: '/system/rankingListManage',
          title: ['系统管理', '排行榜管理']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/system/RankingListManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'rankingReward',
        title: '排行榜奖励',
        bread: {
          path: '/system/rankingReward',
          title: ['系统管理', '排行榜奖励']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/system/RankingReward')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'taskManage',
        title: '任务管理',
        bread: {
          path: '/system/taskManage',
          title: ['系统管理', '任务管理']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/system/TaskManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'periodicActivityManage',
        title: '周期活动模版管理',
        bread: {
          path: '/system/periodicActivityManage',
          title: ['系统管理', '周期活动模版管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/system/PeriodicActivityManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'hiddenGoodsProbability',
        title: '明信片配置',
        bread: {
          path: '/system/hiddenGoodsProbability',
          title: ['系统管理', '明信片配置']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/system/HiddenGoodsProbability')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      // {
      //   path: 'appAppraise',
      //   title: '评价管理',
      //   bread: {
      //     path: '/system/appAppraise',
      //     title: ['系统管理', '评价管理']
      //   },
      //   icon: <CalendarOutlined />,
      //   component: loadable(() => import('../pages/system/AppAppraise')),
      //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      // },
    ]
  },
  {
    key: 'game',
    title: '游戏管理',
    icon: <TrophyOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
    childRoutes: [
      {
        path: 'gameOnSale',
        title: '上架管理',
        bread: {
          path: '/game/gameOnSale',
          title: ['游戏管理', '上架管理']
        },
        icon: <UploadOutlined />,
        component: loadable(() => import('../pages/game/GameOnSale')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'gameOnSaleBox',
        title: '上架套盒管理',
        bread: {
          path: '/game/gameOnSaleBox',
          title: ['游戏管理', '上架套盒管理']
        },
        icon: <UploadOutlined />,
        component: loadable(() => import('../pages/game/GameOnSale/box')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'gameOnSalePoints',
        title: '上架积分管理',
        bread: {
          path: '/game/gameOnSalePoints',
          title: ['游戏管理', '上架积分管理']
        },
        icon: <UploadOutlined />,
        component: loadable(() => import('../pages/game/GameOnSale/points')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'gameOnSaleMealticket',
        title: '上架饭票管理',
        bread: {
          path: '/game/gameOnSaleMealticket',
          title: ['游戏管理', '上架饭票管理']
        },
        icon: <UploadOutlined />,
        component: loadable(() => import('../pages/game/GameOnSale/mealticket')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'gameTemplate',
        title: '模板管理',
        bread: {
          path: '/game/gameTemplate',
          title: ['游戏管理', '模板管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/GameDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'batchListingGameDefine',
        title: '批量上架模版管理',
        bread: {
          path: '/game/batchListingGameDefine',
          title: ['游戏管理', '批量上架模版管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/BatchListingGameDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'batchListingRecord',
        title: '批量上架模版记录',
        bread: {
          path: '/game/batchListingRecord',
          title: ['游戏管理', '批量上架模版记录']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/BatchListingRecord')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SENIOR_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'infinityGameDefine',
        title: '无限套管理',
        bread: {
          path: '/game/infinityGameDefine',
          title: ['游戏管理', '无限套管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/InfinityGameDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'pagodaGameDefine',
        title: '塔模版管理',
        bread: {
          path: '/game/pagodaGameDefine',
          title: ['游戏管理', '塔模版管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/pagodaGameDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'pagodaInfinityGameDefine',
        title: '塔层无限套管理',
        bread: {
          path: '/game/pagodaInfinityGameDefine',
          title: ['游戏管理', '塔层无限套管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/pagodaInfinityGameDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'integralReward',
        title: '积分抽赏管理',
        bread: {
          path: '/game/integralReward',
          title: ['游戏管理', '积分抽赏管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/IntegralReward')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'mealticketGame',
        title: '饭票抽赏管理',
        bread: {
          path: '/game/mealticketGame',
          title: ['游戏管理', '饭票抽赏管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/MealticketGame')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'hiddenPool',
        title: '管理隐藏池',
        bread: {
          path: '/game/hiddenPool',
          title: ['游戏管理', '管理隐藏池']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/HiddenPool')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'unenforcedGame',
        title: '上新管理',
        bread: {
          path: '/game/unenforcedGame',
          title: ['游戏管理', '上新管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/game/UnenforcedGame')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
    ]
  },
  {
    key: 'product',
    title: '商品管理',
    icon: <ShopOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_SENIOR_EMPLOYEE, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_COMMODITY_SELECT, ROLE_EMPLOYEE_SENIOR_SHIPPER],
    childRoutes: [
      {
        path: 'productCategory',
        title: '管理商品分类',
        bread: {
          path: '/product/productCategory',
          title: ['商品管理', '管理商品分类']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/ProductCategory')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'productEdit',
        title: '管理商品',
        bread: {
          path: '/product/productEdit',
          title: ['商品管理', '管理商品']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/ProductEdit')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_SENIOR_EMPLOYEE, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_COMMODITY_SELECT, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'stockControl',
        title: '库存管理',
        bread: {
          path: '/product/stockControl',
          title: ['商品管理', '库存管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/stockControl')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'ProductPresell',
        title: '预售商品列表',
        bread: {
          path: '/product/ProductPresell',
          title: ['商品管理', '预售商品列表']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/ProductPresell')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_SENIOR_EMPLOYEE, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_COMMODITY_SELECT, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'product4PointsEdit',
        title: '管理积分商品',
        bread: {
          path: '/product/product4PointsEdit',
          title: ['商品管理', '管理积分商品']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/Product4PointsEdit')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'itemPool',
        title: '管理物品池',
        bread: {
          path: '/product/itemPool',
          title: ['商品管理', '管理物品池']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/product/ItemPool')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
    ]
  },
  {
    key: 'order',
    title: '订单管理',
    icon: <FileTextOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_EMPLOYEE_SENIOR_SHIPPER],
    childRoutes: [
      {
        path: 'orderNotYetShipped',
        title: '待发货订单',
        bread: {
          path: '/order/orderNotYetShipped',
          title: ['订单管理', '待发货订单']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/order/OrderNotYetShipped')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'orderNotYetShippedGoods',
        title: '待发货物品详情',
        bread: {
          path: '/order/orderNotYetShippedGoods',
          title: ['订单管理', '待发货物品详情']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/order/OrderNotYetShippedGoods')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      {
        path: 'orderShipped',
        title: '已发货订单',
        bread: {
          path: '/order/orderShipped',
          title: ['订单管理', '已发货订单']
        },
        icon: <FileDoneOutlined />,
        component: loadable(() => import('../pages/order/OrderShipped')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
      // {
      //   path: 'orderDiscount',
      //   title: '折现订单',
      //   bread: {
      //     path: '/order/orderDiscount',
      //     title: ['订单管理', '折现订单']
      //   },
      //   icon: <FileDoneOutlined />,
      //   component: loadable(() => import('../pages/order/OrderDiscount')),
      //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      // },
      {
        path: 'orderActiveShipment',
        title: '活动发货订单',
        bread: {
          path: '/order/orderActiveShipment',
          title: ['订单管理', '活动发货订单']
        },
        icon: <FileDoneOutlined />,
        component: loadable(() => import('../pages/order/OrderActiveShipment')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'orderTransfer',
        title: '转移订单',
        bread: {
          path: '/order/orderTransfer',
          title: ['订单管理', '转移订单']
        },
        icon: <FileSearchOutlined />,
        component: loadable(() => import('../pages/order/OrderTransfer')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'productTransferedRecords',
        title: '转移记录查询',
        bread: {
          path: '/order/productTransferedRecords',
          title: ['订单管理', '转移记录查询']
        },
        icon: <FileSearchOutlined />,
        component: loadable(() => import('../pages/order/ProductTransferedRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'returnOrderQuery',
        title: '退回订单查询',
        bread: {
          path: '/order/returnOrderQuery',
          title: ['订单管理', '退回订单查询']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/order/ReturnOrderQuery')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER, ROLE_EMPLOYEE_SENIOR_SHIPPER],
      },
    ]
  },
  {
    key: 'vip',
    title: '会员管理',
    icon: <CrownOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_WITHDRAW, ROLE_ADMIN],
    childRoutes: [
      {
        path: 'vipManage',
        title: '管理会员',
        bread: {
          path: '/vip/vipManage',
          title: ['会员管理', '管理会员']
        },
        icon: <ContactsOutlined />,
        component: loadable(() => import('../pages/vip/VipManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_WITHDRAW,],
      },
      {
        path: 'vipGrade',
        title: '会员评级',
        bread: {
          path: '/vip/vipGrade',
          title: ['会员管理', '会员评级']
        },
        icon: <ContactsOutlined />,
        component: loadable(() => import('../pages/vip/VipGrade')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_WITHDRAW, ROLE_ADMIN],
      },
      {
        path: 'gameRecords',
        title: '游戏记录',
        bread: {
          path: '/vip/gameRecords',
          title: ['会员管理', '游戏记录']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/GameRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'prizeRecords',
        title: '名人堂记录',
        bread: {
          path: '/vip/prizeRecords',
          title: ['会员管理', '名人堂记录']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/PrizeRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'userPrizeRecords',
        title: '用户名人堂记录',
        bread: {
          path: '/vip/userPrizeRecords',
          title: ['会员管理', '用户名人堂记录']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/UserPrizeRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'shovelRecord',
        title: '厨神池铲子记录',
        bread: {
          path: '/vip/shovelRecord',
          title: ['会员管理', '厨神池铲子记录']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/ShovelRecord')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'knapsackStatistic',
        title: '背包统计',
        bread: {
          path: '/vip/knapsackStatistic',
          title: ['会员管理', '背包统计']
        },
        icon: <BarChartOutlined />,
        component: loadable(() => import('../pages/vip/KnapsackStatistic')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'userKnapsackStatistic',
        title: '用户背包统计',
        bread: {
          path: '/vip/userKnapsackStatistic',
          title: ['会员管理', '用户背包统计']
        },
        icon: <BarChartOutlined />,
        component: loadable(() => import('../pages/vip/UserKnapsackStatistic')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'userRealTimeKnapsackStatistic',
        title: '实时用户背包统计',
        bread: {
          path: '/vip/userRealTimeKnapsackStatistic',
          title: ['会员管理', '实时用户背包统计']
        },
        icon: <BarChartOutlined />,
        component: loadable(() => import('../pages/vip/UserRealTimeKnapsackStatistic')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'userRealTimeAmount',
        title: '实时用户金额统计',
        bread: {
          path: '/vip/userRealTimeAmount',
          title: ['会员管理', '实时用户金额统计']
        },
        icon: <BarChartOutlined />,
        component: loadable(() => import('../pages/vip/UserRealTimeAmount')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'dailyKnapsackStatistic',
        title: '每日背包统计',
        bread: {
          path: '/vip/dailyKnapsackStatistic',
          title: ['会员管理', '每日背包统计']
        },
        icon: <BarChartOutlined />,
        component: loadable(() => import('../pages/vip/DailyKnapsackStatistic')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,],
      },
      // {
      //   path: 'userRecyclingRecords',
      //   title: '用户回收记录查询',
      //   bread: {
      //     path: '/vip/userRecyclingRecords',
      //     title: ['会员管理', '用户回收记录查询']
      //   },
      //   icon: <FileSearchOutlined />,
      //   component: loadable(() => import('../pages/vip/UserRecyclingRecords')),
      //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      // },
      {
        path: 'dailyOperation',
        title: '用户统计数据',
        bread: {
          path: '/vip/dailyOperation',
          title: ['会员管理', '用户统计数据']
        },
        icon: <FundViewOutlined />,
        component: loadable(() => import('../pages/vip/UserDailyOperation')),
        role: [ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,]
      },
      {
        path: 'topupRecords',
        title: '充值记录',
        bread: {
          path: '/vip/topupRecords',
          title: ['会员管理', '充值记录']
        },
        icon: <MoneyCollectOutlined />,
        component: loadable(() => import('../pages/vip/TopupRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,],
      },
      {
        path: 'withdrawRecords',
        title: '退款记录',
        bread: {
          path: '/vip/withdrawRecords',
          title: ['会员管理', '退款记录']
        },
        icon: <MoneyCollectOutlined />,
        component: loadable(() => import('../pages/vip/WithdrawRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_WITHDRAW],
      },
      {
        path: 'billsRecords',
        title: '流水记录',
        bread: {
          path: '/vip/billsRecords',
          title: ['会员管理', '流水记录']
        },
        icon: <MoneyCollectOutlined />,
        component: loadable(() => import('../pages/vip/BillsRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,],
      },
      {
        path: 'profitLoss',
        title: '盈亏查询',
        bread: {
          path: '/vip/profitLoss',
          title: ['会员管理', '盈亏查询']
        },
        icon: <MoneyCollectOutlined />,
        component: loadable(() => import('../pages/vip/ProfitLoss')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'reckoning',
        title: '账单明细',
        bread: {
          path: '/vip/reckoning',
          title: ['会员管理', '账单明细']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/Reckoning')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
      },
      {
        path: 'userCollectionRecord',
        title: '用户领取记录管理',
        bread: {
          path: '/vip/userCollectionRecord',
          title: ['会员管理', '用户领取记录管理']
        },
        icon: <ProfileOutlined />,
        component: loadable(() => import('../pages/vip/UserCollectionRecord')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,],
      },
      {
        path: 'welfareCard',
        title: '优惠卡管理',
        bread: {
          path: '/vip/welfareCard',
          title: ['会员管理', '优惠卡管理']
        },
        icon: <PayCircleOutlined />,
        component: loadable(() => import('../pages/vip/WelfareCard')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN,]
      },
      {
        path: 'welfareCardRecord',
        title: '优惠卡购买记录',
        bread: {
          path: '/vip/welfareCardRecord',
          title: ['会员管理', '优惠卡购买记录']
        },
        icon: <PayCircleOutlined />,
        component: loadable(() => import('../pages/vip/WelfareCardRecord')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD]
      },
      {
        path: 'upOrderSearch',
        title: '交易所订单查询',
        bread: {
          path: '/vip/upOrderSearch',
          title: ['会员管理', '交易所订单查询']
        },
        icon: <PayCircleOutlined />,
        component: loadable(() => import('../pages/vip/UpOrderSearch')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD]
      },

    ]
  },
  {
    key: 'audit',
    title: '审核管理',
    icon: <AuditOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN],
    childRoutes: [
      {
        path: 'articleReview',
        title: '审核操作',
        bread: {
          path: '/audit/articleReview',
          title: ['审核管理', '审核操作']
        },
        icon: <SolutionOutlined />,
        component: loadable(() => import('../pages/audit/ArticleReview')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN]
      },
      // {
      //   path: 'cookCircleReview',
      //   title: '文章审核',
      //   bread: {
      //     path: '/audit/cookCircleReview',
      //     title: ['审核管理', '文章审核']
      //   },
      //   icon: <SolutionOutlined />,
      //   component: loadable(() => import('../pages/audit/CookCirclePeview')),
      //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN]
      // },
      {
        path: 'articlePurchase',
        title: '物品购买操作',
        bread: {
          path: '/audit/articlePurchase',
          title: ['审核管理', '物品购买操作']
        },
        icon: <SolutionOutlined />,
        component: loadable(() => import('../pages/audit/ArticlePurchase')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN]
      },
    ]
  },
  {
    key: 'voucher',
    title: '兑换卷管理',
    icon: <AccountBookOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD],
    childRoutes: [
      {
        path: 'voucherDefine',
        title: '兑换卷查询',
        bread: {
          path: '/voucher/voucherDefine',
          title: ['兑换卷管理', '兑换卷查询']
        },
        icon: <CreditCardOutlined />,
        component: loadable(() => import('../pages/voucher/VoucherDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD],
      }
    ]
  },
  {
    key: 'operation',
    title: '运维管理',
    icon: <FundProjectionScreenOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD],
    childRoutes: [
      {
        path: 'dailyOperation',
        title: '运维查询',
        bread: {
          path: '/operation/dailyOperation',
          title: ['运维管理', '运维查询']
        },
        icon: <FundViewOutlined />,
        component: loadable(() => import('../pages/operation/DailyOperation')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD]
      },
    ]
  },
  {
    key: 'cube',
    title: '合成管理',
    icon: <AccountBookOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD],
    childRoutes: [
      {
        path: 'cubePool',
        title: '管理合成池',
        bread: {
          path: '/cube/cubePool',
          title: ['商品管理', '管理合成池']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/cube/CubePool')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_ADMIN, ROLE_ADMIN],
      },
      {
        path: 'cubeManag',
        title: '合成物品',
        bread: {
          path: '/cube/cubeManag',
          title: ['会员管理', '合成物品']
        },
        icon: <PayCircleOutlined />,
        component: loadable(() => import('../pages/cube/CubeManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD]
      },
      {
        path: 'cubeRecords',
        title: '合成记录',
        bread: {
          path: '/cube/cubeRecords',
          title: ['会员管理', '合成记录']
        },
        icon: <PayCircleOutlined />,
        component: loadable(() => import('../pages/cube/CubeRecords')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD]
      },
    ]
  },
  {
    key: 'storeSystem',
    title: '商城系统管理',
    icon: <SettingOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
    childRoutes: [
      {
        path: 'storePosterManage',
        title: '广告管理',
        bread: {
          path: '/storeSystem/storePosterManage',
          title: ['商城系统管理', '广告管理']
        },
        icon: <CalendarOutlined />,
        component: loadable(() => import('../pages/storeSystem/PosterManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
      },
      {
        path: 'addressManage',
        title: '商家地址管理',
        bread: {
          path: '/storeSystem/addressManage',
          title: ['商城系统管理', '商家地址管理']
        },
        icon: <HomeOutlined />,
        component: loadable(() => import('../pages/storeSystem/AddressManage')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
      },
    ]
  },
  // {
  //   key: 'storeProduct',
  //   title: '商城商品管理',
  //   icon: <ShopOutlined />,
  //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
  //   childRoutes: [
  //     // {
  //     //   path: 'productCategory',
  //     //   title: '管理商城商品分类',
  //     //   bread: {
  //     //     path: '/storeProduct/productCategory',
  //     //     title: ['商城商品管理', '管理商城商品分类']
  //     //   },
  //     //   icon: <CalendarOutlined />,
  //     //   component: loadable(() => import('../pages/storeProduct/ProductCategory')),
  //     //   role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
  //     // },
  //     {
  //       path: 'productEdit',
  //       title: '管理商城商品',
  //       bread: {
  //         path: '/storeProduct/productEdit',
  //         title: ['商城商品管理', '管理商城商品']
  //       },
  //       icon: <CalendarOutlined />,
  //       component: loadable(() => import('../pages/storeProduct/ProductEdit')),
  //       role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
  //     },
  //   ]
  // },
  {
    key: 'storeVoucher',
    title: '商城优惠卷管理',
    icon: <AccountBookOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
    childRoutes: [
      {
        path: 'storeVoucherDefine',
        title: '商城优惠卷查询',
        bread: {
          path: '/storeVoucher/storeVoucherDefine',
          title: ['商城优惠卷管理', '商城优惠卷查询']
        },
        icon: <CreditCardOutlined />,
        component: loadable(() => import('../pages/storeVoucher/VoucherDefine')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD],
      }

    ]
  },
  {
    key: 'storeOrder',
    title: '商城订单管理',
    icon: <FileTextOutlined />,
    role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER,ROLE_ADMIN],
    childRoutes: [
      {
        path: 'storeOrderNotYetShipped',
        title: '商城待发货订单',
        bread: {
          path: '/storeOrder/storeOrderNotYetShipped',
          title: ['商城订单管理', '商城待发货订单']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/OrderNotYetShipped')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER,ROLE_ADMIN],
      },
      {
        path: 'storeOrderShipped',
        title: '商城已发货订单',
        bread: {
          path: '/storeOrder/storeOrderShipped',
          title: ['商城订单管理', '商城已发货订单']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/OrderShipped')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER,ROLE_ADMIN],
      },
      {
        path: 'orderCancelltion',
        title: '商城取消订单',
        bread: {
          path: '/storeOrder/orderCancelltion',
          title: ['商城订单管理', '商城取消订单']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/OrderCancellation')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER,ROLE_ADMIN],
      },
      {
        path: 'orderReceipt',
        title: '商城已收货订单',
        bread: {
          path: '/storeOrder/orderReceipt',
          title: ['商城订单管理', '商城已收货订单']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/OrderReceipt')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SHIPPER, ROLE_SENIOR_SHIPPER,ROLE_ADMIN],
      },
      {
        path: 'readyReturnMerchandise',
        title: '商城待发货售后',
        bread: {
          path: '/storeOrder/readyReturnMerchandise',
          title: ['商城订单管理', '商城待发货售后']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/ReadyReturnMerchandise')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD,ROLE_ADMIN],
      },
      {
        path: 'returnMerchandise',
        title: '商城已发货售后',
        bread: {
          path: '/storeOrder/returnMerchandise',
          title: ['商城订单管理', '商城已发货售后']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/ReturnMerchandise')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD,ROLE_ADMIN],
      },
      {
        path: 'exchangeGoods',
        title: '商城换货记录',
        bread: {
          path: '/storeOrder/exchangeGoods',
          title: ['商城订单管理', '商城换货记录']
        },
        icon: <ExceptionOutlined />,
        component: loadable(() => import('../pages/storeOrder/ExchangeGoods')),
        role: [ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD,ROLE_ADMIN],
      },
    ]
  }
];
