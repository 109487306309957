import { Key } from 'react';
import { PosterType } from '../../../api';

export const POSTER_TYPE_SWIPPER: PosterType = '1';

export interface GameType {
  id: Key;
  name: string;
}

export const gameStatesOptions: GameType[] = [
  {
    id: 1,
    name: '上架',
  },
  {
    id: 2,
    name: '未上架',
  },
];

export const LEADERBOADRD_TYPE_COMMODITY = 3;

export const leaderboardTypesOptions: GameType[] = [
  {
    id: 1,
    name: '流水',
  },
  {
    id: 2,
    name: '非酋',
  },
  {
    id: LEADERBOADRD_TYPE_COMMODITY,
    name: '商品',
  },
];

export const MODE_SPECIFIC = 1;
export const MODE_RANGE = 2;
export const MODE_CONDITION = 3;
export const MODE_TOP = 4;
export const modeTypesOptions: GameType[] = [
  {
    id: MODE_SPECIFIC,
    name: '具体名次',
  },
  {
    id: MODE_RANGE,
    name: '范围名次',
  },
  {
    id: MODE_CONDITION,
    name: '满足条件',
  },
  {
    id: MODE_TOP,
    name: '封顶条件',
  },
];

export const REWARD_INTEGRAL = 1;
export const REWARD_MONEY = 2;
export const REWARD_COMMODITY = 3;
export const REWARD_MEALTICKET = 4;
export const REWARD_EQUITY_MEALTICKET = 5;
export const REWARD_TOKEN = 6;
export const rewardTypesOptions: GameType[] = [
  {
    id: REWARD_INTEGRAL,
    name: '积分',
  },
  {
    id: REWARD_MONEY,
    name: '金额',
  },
  {
    id: REWARD_COMMODITY,
    name: '物品',
  },
  {
    id: REWARD_MEALTICKET,
    name: '饭票',
  },
  {
    id: REWARD_EQUITY_MEALTICKET,
    name: '对等饭票',
  },
  {
    id: REWARD_TOKEN,
    name: '厨神币',
  },
];
