import { debounce } from 'lodash-es';
import Fingerprint2 from 'fingerprintjs2';
import { LOGIN_BROWSER_ID } from '../consts';

export const createFingerprint = debounce(()=>{
 const fingerprint = Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
   const values = components.map(component => component.value); // 配置的值的数组
   const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
   localStorage.setItem(LOGIN_BROWSER_ID, murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
 });
},500)
