import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Key } from 'react'
import ReactDOM from 'react-dom'
import { message, notification, Spin } from 'antd'

import {
  ParamsQueryPoints,
  QueryTestParams,
  QueryTestResult,
  ConsoleData,
  ParamsQueryByCategory,
  CategoryPO,
  ProductSpecDTO,
  queryProductCategoryListParams,
  Pagination,
  IntegrationProductPo,
  GameDefinePo,
  GamePo,
  queryVipMembersParams,
  UserDTO,
  ApplyForDeliveryPo,
  QueryApplyForDeliveryPoListParams,
  ParamsQueryGame,
  ParamsQueryGameRecords,
  GameRecordsPo,
  ParamsQueryTopupRecords,
  OrderPo,
  WithdrawRecordsPo,
  ParamsQueryWithdrawRecords,
  ParamsQueryBillsRecords,
  AmountRecordsPo,
  ParamsqueryAwardsSettings,
  VipLevelCollocationPo,
  ParamsQueryGarageKits,
  KnapsackPo,
  queryPosterListParams,
  PosterListPo,
  ConsoleDataDurationType,
  ParamsQueryDailyStatistics,
  DailyStatisticsItem,
  DailyStatistics,
  Result,
  ParamsQueryKnapsackStatistics,
  ParamsQueryUserKnapsackStatistics,
  ParamsQueryDailyKnapsackStatistics,
  KnapsackAllStatisticsDTO,
  KnapsackAllDailyStatisticsDTO,
  KnapsackDailyStatisticsByAccountDTO,
  ParamsGiveGift,
  ParamsQueryGiveGiftRecords,
  GiveGiftsRecordPo,
  AddUnlimitedDrawDefineDTO,
  OnSaleInfinityGameDefineParams,
  AuditShipConclusion,
  ParamsQueryUserRealTimeKnapsackStatistics,
  QueryProductTransferedListParams,
  ProductTransferRecordsDTO,
  ParamsQueryUserRealTimeAmount,
  UserRealTimeAmountInterfaceDTO,
  ParamsqueryChefListFileLists,
  ChefListFileListPo,
  ParamsquerychefListDataByFileId,
  ChefListFileListDto,
  ParamsQueryByRanking,
  SaveRankingsPrizePoolDTO,
  ParamsQueryPointsDraw,
  AddOrUpdatePointsDrawDefineDTO,
  OnSalePointsDraGameDefineParams,
  ParamsqueryGiftUserFileLists,
  GiftUserFileListPo,
  ParamsqueryGiftUserByFileId,
  GiftUserFileListDTO,
  saveSignInRulesConfigDTO,
  ParamsQueryProfitLoss,
  ProfitLossDTO,
  QueryAuditListParams,
  AuditListPo,
  ParamsQueryUserRecyclingRecords,
  UserRecyclingRecordsDTO,
  ParamsQueryByItemPool,
  SaveItemPoolDTO,
  ParamsQueryByHiddenPool,
  SaveHiddenPoolDTO,
  ParamsPrizeGameRecords,
  PrizeRecordsPo,
  ParamsQueryDailyOperations,
  ParamsQueryDailyOperationTitles,
  ParamsExportDailyOperationTitles,
  AddressPo,
  UserAddressPo,
  ResponseResult,
  ExChangeCodeList,
  ExportExChangeCodeList,
  ExchangCodeListPo,
  ParamsQueryBatchListing,
  BatchListingPo,
  SaveBatchListingPo,
  OnSaleBatchListingParams,
  ParamsOrderNotYetShippedGoods,
  OrderNotYetShippedGoodPo,
  ParamsQueryUserCollectionRecords,
  UserCollectionRecordsPo,
  SelectRankingRewardIDDTO,
  OnSaleRankingRewardsParams,
  SelectRankingRewardDTO,
  ParamsQueryByRankingReward,
  ParamsShovelRecord,
  ShovelRecordsPo,
  changeAvatarParams,
  ParamsQueryProductEditRecords,
  ProductEditRecordsPo,
  RelatedTemplatPo,
  ParamsUserDataBeforeGiftData,
  ParamsQueryAllCondition,
  AllConditionDTO,
  ConditionDefineDTO,
  SignColorParams,
  RankingsBigPrize,
  AddPagodaDrawDefineDTO,
  ParamsQueryByStock,
  ParamsQueryByStockRecord,
  ParamsExportWithdrawRecords,
  WelfareParams,
  CardData,
  WelfareCardRecordParams,
  WelfareCardRecordListItem,
  TransformWelfareCardRecordListItem,
  WelfareCardNameListItem,
  UserRoleData,
  updateShippedAmountData,
  PurchaseOnBehalfOfParams,
  UpdataCommentNameDTO,
  ParamsVipGradeList,
  VipGradeListDTO,
  insertVipGradeDTO,
  QueryActivityParams,
  QueryActivityListItem,
  TransformQueryActivityListItem,
  QueryActivityByIdDetail,
  TransformQueryActivityByIdDetail,
  TransformQueryActivityParams,
  ParamsQueryUserGuideList,
  UserGuideListDTO,
  searchArticleParams,
  auditArticleParams,
  ParamsQueryByCubePool,
  SaveCubePoolDTO,
  CobeCompoundListPo,
  CompoundCube,
  ParamsQueryByCubeRecord,
  CubeRecordDTO,
  relDTO,
  OrderDetail,
  InfoData,
  TopupByMealticketParams,
  QueryBigPrizeListItem,
  RageInfoParams,
  QueryParams,
  TowerFloorRecordList,
  ProductReplenishStockParams,
  configUploadImgParams,
  productImgDetailsPo,
  productImgDetailsParams,
  updateProductImgDetailsParams,
  hiddenPostConfigParams,
  hiddenPostConfigPo,
  saveHiddenPostConfigParams,
  ConfigGivenPo,
  OuterAdvertisingConfigParams,
  OuterAdvertisingConfigPo,
  AddAppConfigParams,
  AppConfigAppraise,
  AppConfigAppraisePo,
} from './index.d';

import {
  QUERY_TEST,
  URL_QUERY_CONTROL_DATA,
  URL_QUERY_PRODUCT_CATEGORY_LIST,
  URL_QUERY_PRODUCT_CATEGORY_OPTIONS,
  URL_ADD_PRODUCT_CATEGORY,
  URL_UPDATE_PRODUCT_CATEGORY,
  URL_DELETE_PRODUCT_CATEGORY_LIST,
  URL_QUERY_PRODUCT_SPEC,
  URL_ADD_GAME_DEFINE,
  URL_QUERY_GAME_LIST,
  URL_QUERY_GAME_BOX_LIST,
  URL_PULL_OFF_GAME_BOX,
  URL_ADD_PRODUCT_SPEC,
  PRODUCT_BASE_CATEGORY_ID_IP,
  PRODUCT_BASE_CATEGORY_ID_TYPE,
  PRODUCT_BASE_CATEGORY_ID_CATEGORY,
  URL_UPDATE_PRODUCT_SPEC,
  URL_QUERY_PRODUCT_FOR_POINTS_LIST,
  URL_UPDATE_PRODUCT_FOR_POINTS_LIST,
  URL_QUERY_GAME_DEFINE_LIST,
  URL_QUERY_INFINITY_GAME_DEFINE_LIST,
  URL_UPDATE_GAME_DEFINE,
  URL_UPDATE_INFINITY_GAME_DEFINE,
  URL_ADD_GAME,
  URL_UPDATE_GAME,
  GAME_BASE_CATEGORY_ID_GRADE,
  URL_LOGIN,
  URL_QUERY_VIP_MEMBERS,
  URL_TOPUP,
  URL_QUERY_NOT_YET_SHIPPED_ORDER,
  URL_QUERY_SHIPPED_ORDER,
  URL_DELETE_PRODUCT_BY_ID,
  URL_DELETE_GAME_DEFINE_BY_ID,
  URL_QUERY_GAME_RECORDS,
  URL_QUERY_TOPUP_RECORDS,
  URL_QUERY_WITHDRAW_RECORDS,
  URL_QUERY_BILLS_RECORDS,
  URL_QUERY_REWARDS_SETTINGS,
  URL_ADD_REWARD_SETTING,
  URL_DELETE_REWARD_SETTING,
  URL_QUERY_GARAGE_KITS,
  URL_BLOCKED_ACCOUNT,
  URL_UNBLOCKED_ACCOUNT,
  URL_CONFIRM_WITHDRAW,
  URL_QUERY_POSTER_LIST,
  URL_SHIP_AUDIT,
  URL_ADD_POSTER,
  URL_DELETE_POSTER,
  URL_TOPUP_BY_GRANT,
  URL_SEND_BACK_SHIP,
  URL_QUERY_UNENFORCED_GAME_LIST,
  CONSOLE_DATE_DURATION_TYPE_CURRENT_DAY,
  CONSOLE_DATE_DURATION_TYPE_CURRENT_MON,
  CONSOLE_DATE_DURATION_TYPE_CURRENT_YEAR,
  URL_CLONE_GAME_DEFINE,
  URL_WITHDRAW_BY_WECHAT,
  URL_WITHDRAW_THROUGH_GO,
  URL_QUERY_RECKONING,
  URL_QUERY_KNAPSACK_STATISTIC,
  URL_QUERY_USER_KNAPSACK_STATISTIC,
  URL_QUERY_DAILY_KNAPSACK_STATISTIC,
  URL_GIVE_GIFT,
  URL_QUERY_GIVE_GIFT_RECORDS,
  URL_QUERY_USER_ROLE,
  URL_QUERY_INFINITY_GAME_DEFINE_DETAILS,
  INFINITY_GAME_BASE_CATEGORY_ID_GRADE,
  URL_ON_SALE_INFINITY_GAME_DEFINE,
  URL_OFF_SHELVE_INFINITY_GAME_DEFINE,
  URL_QUERY_USER_REAL_TIME_KNAPSACK_STATISTIC,
  URL_QUERY_PRODUCT_TRANSFERED_RECORDS,
  URL_QUERY_USER_REAL_TIME_DATA,
  URL_CHEF_LIST_FILE_LIST,
  URL_CHEF_LIST_FILE_DEMO,
  URL_DEL_CHEF_LIST_FILE,
  URL_DEL_CHEF_LIST_DARABY_FILEID,
  URL_QUERY_RANKING_LIST_MANAGE__LIST,
  URL_UPDATE_QUERY_RANKING_GAME,
  URL_DELETE_GAME_RANKING_BY_ID,
  URL_QUERY_RANKING_DETAILS,
  URL_OFF_ON_RANKING_GAME,
  URL_GET_CHEF_LIST_FILE_LIST,
  URL_ADD_POINTS_GAME,
  URL_QUERY_GAME_POINTS_LIST,
  URL_QUERY_POINTS_DRAW_DEFINE_LIST,
  URL_UPDATA_POINTS_DRAW_DEFINE,
  URL_QUERY_POINTS_DRAW_DEFINE_BY,
  URL_ON_POINTS_DRAW_DEFINE,
  URL_POINTS_DRA_DELETE_GAME_DEFINE_BY_ID,
  URL_GIFT_USER_DEMO,
  URL_GIFT_USER_FILE_LIST,
  URL_GIFT_USER_FILE_LIST_ID,
  URL_UPLOAD_GIFT_USER_FILE_CONFIRM,
  URL_UPDATE_SIGN_IN_MANAGE,
  URL_QUERY_ID_SIGN_IN_MANAGE,
  URL_DELETE_SIGN_IN_MANAGE_BY_ID,
  URL_OFF_ON_SIGN_IN_MANAGE,
  URL_QUERY_SIGN_IN_MANAGE,
  URL_QUERY_PROFITLOSS,
  URL_QUERY_AUDIT_LIST,
  URL_AUDIT_CONFIRM,
  URL_USER_RECYCLING_RECORDS,
  URL_QUERY_ITEM_POOL,
  URL_QUERY_ITEM_POOL_DETAILS,
  URL_UPDATE_ITEM_POOL,
  URL_DELETE_ITEM_POOL_BY_ID,
  URL_DELETE_HIDDEN_POOL_BY_ID,
  URL_OFF_ON_HIDDEN_POOL,
  URL_QUERY_HIDDEN_POOL,
  URL_QUERY_HIDDEN_POOL_DETAILS,
  URL_UPDATE_HIDDEN_POOL,
  URL_QUERY_PIRIZE_RECORDS,
  URL_QUERY_DAILY_OPERATION_TITLE,
  URL_QUERY_DAILY_OPERATION_DATA,
  URL_EXPORT_DAILY_OPERATION_DATA,
  URL_QUERY_ADMINISTRATIVE_DIVISION_OPTIONS,
  URL_QUERY_USER_DEFAULT_ADDRESS,
  URL_QUERY_USER_ADDRESS_LIST,
  SUPERIOR_ADMINISTRATIVE_DIVISION_CODE,
  URL_QUERY_EXCHANGE_CODE_LIST,
  URL_EXPORT_EXCHANGE_CODE_LIST,
  URL_QUERY_EXCHANGE_LIST,
  URL_OFF_SHELVE_EXCHANGE,
  URL_ON_SALE_EXCHANGE,
  URL_QUERY_GRANDO_PRIZE_GRADE_LIST,
  URL_QUERY_BATECH_LISTING,
  URL_QUERY_ID_BATECH_LISTING,
  URL_SAVE_BATECH_LISTING,
  URL_DELETE_BATECH_LISTING,
  URL_OFF_ON_BATECH_LISTING,
  URL_QUERY_PRODUCT_INGO_BY,
  URL_EXPORT_PRODUCT_INGO_BY,
  URL_WITHDRAW_BY_ALIPAY_THROUGH_GO,
  URL_WITHDRAW_BY_ALIPAY,
  URL_QUERY_GAME_USER_COLLLECTUON_RECORDS,
  URL_QUERY_RANKING_REWARD_DETAILS,
  URL_OFF_ON_RANKING_REWARD_GAME,
  URL_DELETE_GAME_RANKING_REWARD_BY_ID,
  URL_UPDATE_QUERY_RANKING_REWARD_GAME,
  URL_QUERY_RANKING_REWARD_LIST,
  URL_QUERY_SHOVEL_RECORD,
  URL_QUERY_BATECH_LISTING_RECORD,
  URL_QUERY_ID_BATECH_LISTING_RECORD,
  URL_QUERY_DISCOUNT_LIST,
  URL_QUERY_EVENT_ITEMS_LIST,
  URL_APPLY_SHIPMENT_PENDING,
  URL_QUERY_TRANSFER_LIST,
  URL_QUERY_MEALTICKET_GAME,
  URL_QUERY_MEALTICKET_GAME_ID,
  URL_UPDATA_MEALTICKET_GAME,
  URL_ON_SALE_MEALTICKET_GAME,
  URL_DELETE_MEALTICKET_GAME,
  URL_QUERY_GAME_MEALTICKET_LIST,
  URL_TOPUP_BY_MEALTICKET,
  URL_Change_AVATAR,
  URL_OPERATION_TYPE,
  URL_QUERY_PRODUCT_RECORDS,
  URL_ADANDON_GAME_DEFIN,
  URL_ADANDON_PRODUCT_DEFIN,
  URL_QUERY_RELATEDTEMPLATE,
  URL_QUERY_USER_GIFT_DATA,
  URL_UPDATE_PRESALE,
  URL_QUERY_ALL_CONDITION,
  URL_QUERY_CONDITION_BY_ID,
  URL_UPDATE_CONDITION,
  URL_APPLY_COLOR_FLAG,
  URL_QUERY_RETURN_LIST,
  URL_QUERY_RANKING_BIH_PRIZE,
  URL_QUERY_PAGODA_GAME_DEFINE_LIST,
  URL_QUERY_PAGODA_BY_ID,
  URL_UPDATE_PAGODA_GAME_DEFINE,
  URL_ON_SALE_PAGODA_GAME_DEFINE,
  URL_OFF_SHELVE_PAGODA_GAME_DEFINE,
  URL_Add_PAGODA_GAME_DEFINE,
  URL_QUERY_USER_PIRIZE_RECORDS,
  URL_QUERY_PAGODA_INFINITY_GAME_DEFINE_LIST,
  URL_QUERY_STOCK_LIST,
  URL_QUERY_ADD_STOCK,
  URL_QUERY_PUT_STOCK,
  URL_QUERY_STOCK_RECORD,
  URL_EXPORT_WITHDRAW_RECORDS,
  URL_QUERY_CONTROL_DATA_CATALOG,
  URL_QUERY_WELFARE_CARD_LIST,
  URL_ADD_WELFARE_CARD,
  URL_UPDATE_WELFARE_CARD,
  URL_UPLOAD_CARD,
  URL_DOWN_CARD,
  URL_DELETE_CARD,
  URL_RECOVER_CARD,
  URL_GET_CARD_RECORD_LIST,
  URL_GET_CARD_NAME_LIST,
  URL_UPDATA_SHIPPED_AMOUNT,
  URL_UPLOAD_REWARDS_LIST,
  URL_DOWNLOAD_TEMPLATE,
  URL_PURCHASE_ON_BEHALF_OF,
  RESPONSE_CODE_SUCCEED,
  URL_UPDATA_COMMENTNAME,
  URL_QUERY_VIPGRADE_LIST,
  URL_INSERT_GVIPGRADE,
  URL_UPDATE_GVIPGRADE,
  URL_QUERY_ACTIVITY_LIST,
  URL_QUERY_ACTIVITY_INFO_BY_ID,
  URL_UPDATE_ACTIVITY_INFO,
  URL_ON_SHELF_ACTIVITY,
  URL_OFF_ACTIVITY,
  URL_REMOVE_ACTIVTIY,
  URL_RESET_ACTIVITY,
  URL_QUERY_USERGUIDE_LIST,
  URL_INSERT_USERGUIDE,
  URL_UPDATE_USERGUIDE,
  URL_DELETE_USERGUIDE,
  URL_QUERY_ALL_MENU_INFO,
  URL_GET_ARTICLE_LIST,
  URL_AUDIT_ARTICLE,
  URL_QUERY_CUBEPOOL_LIST,
  URL_EDIT_CUBEPOOL_LIST,
  URL_DEL_CUBEPOOL_LIST,
  URL_QUERY_CUBEPOOL_DETAILS,
  URL_QUERY_CUBE_COMPOUND_LIST,
  URL_COMPOUND_CUBE,
  URL_QUERY_CUBE_RECORDS,
  URL_QUERY_CUBE_RECORDS_DETAIL,
  URL_QUERY_ORDER_DETAIL,
  URL_UPDATE_USERINFO,
  URL_TOPUP_BY_TOKENS,
  URL_QUERY_BIG_PRIZE,
  URL_QUERY_LOGIN,
  URL_QUERY_RAGE_NUM,
  URL_TOWER_FLOOR_RECORD,
  URL_ON_PRODUCT_SPEC,
  URL_OFF_PRODUCT_SPEC,
  URL_CUBE_UP_DOWN,
  URL_PRODUCT_REPLENISH_STOCK,
  JOIN_BOURSE_BLACKLIST,
  REMOVE_BOURSE_BLACKLIST,
  URL_UPLOAD_COVER_IMAGE,
  URL_GET_PRODUCT_IMG_DETAILS,
  URL_UPDATE_PRODUCT_IMG_DETAILS,
  URL_HIDDEN_POST_CONFIG_LIST,
  URL_GET_HIDDEN_POST_CONFIG_BY_ID,
  URL_DEL_HIDDEN_POST_CONFIG_BY_ID,
  URL_UP_AND_DOWN_HIDDEN_POST_CONFIG,
  URL_SAVE_HIDDEN_POST_CONFIG,
  URL_FIND_SYSTEM_CONFIG_GIVEN_BY_ID,
  URL_DOWNLOAD_TXT,
  URL_QUERY_OUTER_ADVERTISING_CONFIG_LIST,
  URL_ADD_OUTER_ADVERTISING_CONFIG,
  URL_UPDATE_OUTER_ADVERTISING_CONFIG,
  URL_DEL_OUTER_ADVERTISING_CONFIG,
  URL_QUERY_ADVERTISING_COMMENT_LIST,
  URL_DOWN_WAIT_EXCEL_LIST,
  URL_SYNC_PRESALE,
  REMOVE_ACTION_BLACKLIST,
  JOIN_ACTION_BLACKLIST,
  // BD,
} from './consts'
import { Category } from '../../types/category'
import {
  ItemPoolsSepc,
  MainItemPool,
  Product4PointsSepc,
  ProductSpec,
} from '../../types/product'
import {
  transform2IntegrationProductPo,
  transform2ParamsQueryByCategory,
  transform2CategoryPO,
  transformIntegrationProductPo,
  transformProductSpecDTO,
  transfrom2ProductSpecDTO,
  transfromPagination,
  transfromCategoryPo,
  transformGameDefinePo,
  transform2GameDefinePo,
  transform2GamePo,
  transformGamePo,
  transformUserDto,
  transform2ServerAmount,
  transformApplyForDeliveryPo,
  transfromGameRecordsPo,
  transformOrderPo,
  transformWithdrawRecordsPo,
  transformAmountRecordsPo,
  transformVipLevelCollocationPo,
  transformKnapsackPo,
  transformPosterListPo,
  transformDailyStatisticsItem,
  transformKnapsackAllStatisticsDTO,
  transformKnapsackAllDailyStatisticsDTO,
  transformKnapsackDailyStatisticsByAccountDTO,
  transformGiveGiftsRecordPo,
  transformGamePo2GameBox,
  transformGamePo2GamePoint,
  transformAddUnlimitedDrawDefineDTO,
  transform2AddUnlimitedDrawDefineDTO,
  transformProductTransferRecordsDTO,
  transformUserRealTimeAmountDTO,
  transformChefListFileListPo,
  transformUri,
  transformChefListFileListDto,
  transformSaveLeaderboardConfigPo,
  transform2SaveLeaderboardConfigDTO,
  transformGetChefListFileListPo,
  transformAddOrUpdatePointsDrawDefineDTO,
  transform2AddPointsDrawDefineDTO,
  transformGiftUserFileListPo,
  transformGiftUserFileListDTO,
  transformSaveSignInRulesConfigPo,
  transform2SaveSignInRulesConfigDTO,
  transformProfitLossDTO,
  transformAuditListPo,
  transformUserRecyclingRecordsDTO,
  transformSaveSaveItemPoolDTO,
  transformSaveItemPoolDTO,
  transform2SaveItemPoolDTO,
  transformSaveHiddenPoolDTO,
  transform2SaveHiddenPoolDTO,
  transfromPrizeRecordsPo,
  transfromDailyOperationTitlePo,
  transformUserAddressPo,
  transformExChangListDTO,
  transformExGameDefinePo,
  transformBatchListingPo,
  transformSaveBatchListingPo,
  transform2SaveBatchListingPo,
  transformOrderNotYetShippedGoodPo,
  transfromUserCollectionRecordsPo,
  transformSelectRankingRewardIDDTO,
  transform2SaveRankingRewardDTO,
  transformSelectRankingRewardDTO,
  transfromShovelRecordsPo,
  transformMealticketGameDTO,
  transform2MealticketGameDTO,
  transformGamePo2GameMealticke,
  transfromProductEditRecordsPo,
  transfromRelatedTemplate,
  transformAllConditiondDTO,
  transformConditiondDefineDTO,
  transformPagodaGame,
  transform2PagodaGame,
  transform2AddPagodaGame,
  transformStockLisDefineDTO,
  transformStockRecordDTO,
  transformWelfareCardList,
  tranformUpdateCardData,
  transformWelfareCardReacordListItem,
  transformCardName,
  transformVipGradeListDTO,
  transformQueryActivityListItem,
  transformQueryActivityByIdDetail,
  transformUpdateActivityData,
  transformQueryActivityListParams,
  transformUserGuideListDTO,
  transform2InsertUserGuideDefineDTO,
  transformArticleList,
  transform2SaveCubePoolDTO,
  transformSaveCubePoolDTO,
  transfromCobeCompoundListPo,
  transfromCobeRecordListPo,
  transfromCobeRecordDetailsPo,
  transformOrderDetailList,
  transformBigPrizeListItem,
  TranformTowerRecordListItem,
  transformAddressPo,
  transformProductImgDetails,
  transformHiddenPostConfig,
  transformConfigGiven,
} from './transform';
import {
  BatchListings,
  Game,
  GameBox,
  GameState,
  HiddenPoolGame,
  InfinityGameDefine,
  LimitedGameDefine,
  PointsDrawDefine,
  SaveBatchListings,
} from '../../types/game'
import { AddPagodaReward, PagodaRewards } from '../../types/pagoda'
import {
  LOGIN_BROWSER_ID,
  LOGIN_TOKEN_KEY,
  LOGIN_USER_ID,
  LOGIN_USER_ROLE,
  ROLE_UN_DEVICE,
  ROLE_UN_LOGIN,
} from '../consts'
import {
  GameRecord,
  TopupRecord,
  VipMember,
  WithdrawRecord,
  BillsRecord,
  ReckoningItem,
  KnapsackStatistic,
  DailyKnapsackStatistic,
  UserKnapsackStatistic,
  ProfitLosss,
  UserRecyclingRecords,
  PrizeRecords,
  UserCollectionRecords,
  ShovelRecords,
  ProductEditRecords,
  UserRealTimeAmountInterface,
  VipGrade,
} from '../../types/vip'
import { DeliveryOrder, TransferRecord } from '../../types/order'
import { ArticleReviewAudit } from '../../types/audit'
import {
  AddRankingReward,
  Address,
  AwardsSetting,
  GiftManageFile,
  GiftManageFileList,
  GiveGiftRecord,
  KitchenGod,
  KitchenGodFileList,
  KitchenGodsSetting,
  RankingList,
  RankingPoster,
  SelectRankingRewarIDwebs,
  SelectRankingRewars,
  SignInManagePoster,
} from '../../types/system'
import axios from 'axios'
import { transformFromAstSync } from '@babel/core'
import { VoucherDefine } from '../../types/voucher'
import { StockListDefine, StockRecordDefine } from '../../types/stockControl'
import { tranformCardListItem } from '../../types/weekCard'
import { ArticleData } from '../../types/forum';
import { formatTimestamp } from '../utils/datetimeUtils';

export * from './index.d'

Axios.defaults.timeout = 120000

let requestCount = 0
let loadingDom: Element | null = null

const CODE_REQ_SUCC = '00000'

const showLoading = () => {
  const ID = 'loading'
  let dom = null
  if (requestCount === 0) {
    dom = document.createElement('div')
    dom.setAttribute('id', ID)
    dom.style.position = 'absolute'
    dom.style.top = '0px'
    dom.style.right = '0px'
    dom.style.bottom = '0px'
    dom.style.left = '0px'
    dom.style.background = 'rgba(0, 0, 0, 0.3)'
    dom.style.zIndex = '1001'

    document.body.appendChild(dom)
    ReactDOM.render(
      <Spin
        tip='加载中...'
        size='large'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'tranlate(-50%, -50%)',
        }}
      />,
      dom
    )
  } else {
    dom = document.querySelector(`#${ID}`)
  }
  requestCount += 1
  return dom
}

const hideLoading = (dom?: Element) => {
  requestCount -= 1
  if (requestCount === 0 && dom) {
    document.body.removeChild(dom)
  }
}

interface CustomConfig extends AxiosRequestConfig {
  silence?: boolean
}

// const TOKEN = '8cc8a0ffe802268737869221c183e3c9';

Axios.interceptors.request.use((config: CustomConfig) => {
  const { silence } = config
  if (!silence) {
    loadingDom = showLoading()
  }
  delete config.silence
  const token = sessionStorage.getItem(LOGIN_TOKEN_KEY)
  const browserId = localStorage.getItem(LOGIN_BROWSER_ID)
  return {
    ...config,
    headers: {
      'X-Token': token,
      'X-Mac': browserId !== null ? browserId : undefined,
    },
  }
})

Axios.interceptors.response.use(
  (res) =>
    new Promise((resolve, reject) => {
      if (loadingDom) hideLoading(loadingDom)
      const { data } = res
      if (data.code && data.code !== CODE_REQ_SUCC) {
        notification.error({
          message: data.msg,
        })
        reject(data.msg)
      } else resolve(res)
    }),
  (err) => {
    // if(err.config.timeout ===  Axios.defaults.timeout) message.error('请求超时，请检查网络')
    if (loadingDom) hideLoading(loadingDom)
    if (err?.response?.status === 401) {
      // window.location.href = '/goapi/v1/qr/redirect'
      window.location.hash = '/login'
      sessionStorage.setItem(LOGIN_USER_ROLE, 'unLogin');
      return Promise.resolve(ROLE_UN_LOGIN)
    }
    if (err?.response?.status === 402) {
      sessionStorage.setItem(LOGIN_USER_ROLE, ROLE_UN_DEVICE)
      window.location.hash = '/402'
      if (err?.response.data)
        localStorage.setItem(LOGIN_USER_ID, JSON.stringify(err?.response.data))
      return Promise.resolve(ROLE_UN_DEVICE)
    }
    if (err?.response?.status === 500) {
      notification.error({
        message: '系统异常，请稍后再试！',
      })
    }
    return Promise.reject(err)
  }
)

export const queryTest = (params: QueryTestParams) => {
  return Axios.get<QueryTestResult[]>(QUERY_TEST, { params })
}

// 控制台页面数据
const queryConsoleData = async (durationType: Key): Promise<ConsoleData[]> => {
  const res = await Axios.get<ConsoleData[]>(URL_QUERY_CONTROL_DATA, {
    params: {
      timeType: durationType,
    },
  })
  return (res.data || []).map((group) => {
    const { title, items } = group
    return {
      title,
      items: items.map((item) => {
        const { title, value } = item
        return {
          title,
          value: (title || '').indexOf('元') === -1 ? value : value / 100,
        }
      }),
    }
  })
}

export const queryConsoleDataCurrentData = (data: Key) => {
  return queryConsoleData(data)
}

// export const queryConsoleDataCurrentDay = () => {
//   return queryConsoleData(CONSOLE_DATE_DURATION_TYPE_CURRENT_DAY);
// };

// export const queryConsoleDataCurrentMon = () => {
//   return queryConsoleData(CONSOLE_DATE_DURATION_TYPE_CURRENT_MON);
// };

// export const queryConsoleDataCurrentYear = () => {
//   return queryConsoleData(CONSOLE_DATE_DURATION_TYPE_CURRENT_YEAR);
// };

export const queryProductCategoryList = async (
  params: queryProductCategoryListParams
): Promise<Pagination<Category>> => {
  const params2Req = transform2ParamsQueryByCategory(params)

  const res = await Axios.get<Pagination<CategoryPO>>(
    URL_QUERY_PRODUCT_CATEGORY_LIST,
    {
      params: params2Req,
    }
  )

  return transfromPagination<CategoryPO, Category>(res.data, (list) => {
    return (list || []).map((po) => {
      return transfromCategoryPo(po)
    })
  })
}

// 查询商品分类详情数据
export const queryProductCategoryDetails = (params: ParamsQueryByCategory) => {
  return Axios.get<CategoryPO>(URL_QUERY_PRODUCT_CATEGORY_LIST, {
    params,
  })
}

// 查询商品分类下拉列表
export const queryProductCategoryOptions = async (
  keyword?: string,
  parentCategoryId?: Key
) => {
  const params: ParamsQueryByCategory = {
    pageNo: 1,
    pageSize: 10000,
    name: keyword || undefined,
    superCategoryId: parentCategoryId,
  }
  const config: CustomConfig = {
    params,
    silence: true,
  }
  const res = await Axios.get<CategoryPO[]>(
    URL_QUERY_PRODUCT_CATEGORY_OPTIONS,
    config
  )
  const productCategoryPoList = res.data || []
  return productCategoryPoList.map((po) => {
    return transfromCategoryPo(po)
  })
}

export const queryProductIpOptions = async (keyword?: string) => {
  return queryProductCategoryOptions(keyword, PRODUCT_BASE_CATEGORY_ID_IP)
}

export const queryProductTypeOptions = async (keyword?: string) => {
  return queryProductCategoryOptions(keyword, PRODUCT_BASE_CATEGORY_ID_TYPE)
}

export const queryProductCategoriesOptions = async (keyword?: string) => {
  return queryProductCategoryOptions(keyword, PRODUCT_BASE_CATEGORY_ID_CATEGORY)
}

// 添加商品分类，返回分类id
export const addProductCategory = async (category: Category) => {
  const categoryPo = transform2CategoryPO(category)

  const res = await Axios.post<string>(URL_ADD_PRODUCT_CATEGORY, categoryPo)
  return res.data
}

// 更新商品分类数据, 返回分类id
export const updateProductCategory = (category: Category) => {
  const productCategoryPO = transform2CategoryPO(category)
  return Axios.post<string>(URL_UPDATE_PRODUCT_CATEGORY, productCategoryPO)
}

// 删除商品分类
export const deleteProductCategories = (ids: Key[]) => {
  return Axios.post<void>(URL_DELETE_PRODUCT_CATEGORY_LIST, {
    idList: ids,
  })
}

// 查询商品详情
export const queryProductSpecList = async (
  params: ParamsQueryByCategory
): Promise<Pagination<ProductSpec>> => {
  const {
    superCategoryId,
    categoryId,
    parentCategoryId,
    name,
    minRecoverPrice,
    maxRecoverPrice,
    abandonState,
    pageNo,
    pageSize,
    productState,
  } = params
  const params4Query = {
    superCategoryId,
    parentCategoryId: parentCategoryId || categoryId,
    name,
    abandonState,
    minAmount: minRecoverPrice
      ? transform2ServerAmount(minRecoverPrice)
      : minRecoverPrice,
    maxAmount: maxRecoverPrice
      ? transform2ServerAmount(maxRecoverPrice)
      : maxRecoverPrice,
    pageNo,
    pageSize,
    productState,
  }
  const res = await Axios.get<Pagination<ProductSpecDTO>>(
    URL_QUERY_PRODUCT_SPEC,
    {
      params: params4Query,
    }
  )
  return transfromPagination<ProductSpecDTO, ProductSpec>(res.data, (list) => {
    return (list || []).map((dto) => {
      return transformProductSpecDTO(dto)
    })
  })
}

export const queryProductList = async (
  params: ParamsQueryByCategory
): Promise<Pagination<ProductSpec>> => {
  const {
    superCategoryId,
    categoryId,
    parentCategoryId,
    name,
    pageNo,
    pageSize,
    maxAmount,
    minAmount,
    sort,
    sortField,
    preSale,
    abandonState,
    startTime,
    endTime,
    status,
    orderPrice,
    saleOut,
    mallStatus,
  } = params
  const params4Query = {
    orderPrice: orderPrice ? transform2ServerAmount(orderPrice) : orderPrice,
    startTime,
    endTime,
    superCategoryId,
    parentCategoryId: parentCategoryId || categoryId,
    name,
    maxAmount: maxAmount ? transform2ServerAmount(maxAmount) : maxAmount,
    minAmount: minAmount ? transform2ServerAmount(minAmount) : minAmount,
    sort,
    sortField,
    preSale: preSale === undefined ? undefined : preSale === 1 ? true : false,
    abandonState: abandonState ? abandonState : '1',
    pageNo,
    pageSize,
    status,
    saleOut,
    mallStatus,
  }
  const res = await Axios.get<Pagination<ProductSpecDTO>>(
    URL_QUERY_PRODUCT_SPEC,
    {
      params: params4Query,
    }
  )
  return transfromPagination<ProductSpecDTO, ProductSpec>(res.data, (list) => {
    return (list || []).map((dto) => {
      return transformProductSpecDTO(dto)
    })
  })
}

export const addProductSpec = async (productSpec: ProductSpec) => {
  try {
    const data = transfrom2ProductSpecDTO(productSpec)
    let res
    if (data) {
      res = await Axios.post(URL_ADD_PRODUCT_SPEC, data)
    }
    message.success('商品添加成功！')
    return res?.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

// 修改商品详情 todo 缺少接口
export const updateProductSpec = async (productSpec: ProductSpec) => {
  try {
    const data = transfrom2ProductSpecDTO(productSpec)
    let res
    if (data) {
      res = await Axios.post(URL_UPDATE_PRODUCT_SPEC, data)
    }
    message.success('商品更新成功！')
    return res?.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

// 删除商品详情， todo 等后端实现
export const deleteProductSpecList = (productSpecs: ProductSpecDTO[]) => { }

// 查询积分商品，todo 后台接口出入参需要重新设计
export const queryProduct4PointsList = async (
  params: ParamsQueryByCategory
) => {
  const res = await Axios.get<Pagination<IntegrationProductPo>>(
    URL_QUERY_PRODUCT_FOR_POINTS_LIST,
    {
      params,
    }
  )
  return transfromPagination<IntegrationProductPo, Product4PointsSepc>(
    res.data,
    (list) => {
      return list.map((dto) => {
        return transformIntegrationProductPo(dto)
      })
    }
  )
}

// 修改积分商品列表，todo 后台接口出入参需要重新设计
export const updateProduct4PointsList = async (
  product4PointsSpecList: Product4PointsSepc[]
) => {
  const poList = product4PointsSpecList.map((spec) =>
    transform2IntegrationProductPo(spec)
  )
  try {
    const res = await Axios.post<string[]>(
      URL_UPDATE_PRODUCT_FOR_POINTS_LIST,
      poList
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 删除积分商品列表，todo 后台接口出入参需要重新设计
export const deleteProduct4PointsList = () => { }

export const queryGameDefineList = async (params: ParamsQueryByCategory) => {
  const res = await Axios.get<Pagination<GameDefinePo>>(
    URL_QUERY_GAME_DEFINE_LIST,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<GameDefinePo, LimitedGameDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformGameDefinePo(gameDefinePo)
      })
    }
  )
}

export const queryInfinityGameDefineList = async (
  params: ParamsQueryByCategory
) => {
  const res = await Axios.get<Pagination<AddUnlimitedDrawDefineDTO>>(
    URL_QUERY_INFINITY_GAME_DEFINE_LIST,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<AddUnlimitedDrawDefineDTO, InfinityGameDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformAddUnlimitedDrawDefineDTO(gameDefinePo)
      })
    }
  )
}

export const queryInfinityGameDefineDetails = async (
  gameDefineId: Key
): Promise<InfinityGameDefine> => {
  const res = await Axios.get<Result<AddUnlimitedDrawDefineDTO>>(
    URL_QUERY_INFINITY_GAME_DEFINE_DETAILS,
    {
      params: {
        defineId: gameDefineId,
      },
    }
  )
  return transformAddUnlimitedDrawDefineDTO(res.data.data)
}

// 新增游戏模版, todo 出入参需要后端再重新设计
export const addGameDefine = async (
  gameDefine: LimitedGameDefine
): Promise<LimitedGameDefine> => {
  const gameDefinePo = transform2GameDefinePo(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<GameDefinePo>>(
      URL_ADD_GAME_DEFINE,
      gameDefinePo
    )
    message.success('新增游戏模版成功！')
    return transformGameDefinePo(res.data)
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 更新游戏模版 todo 后端缺接口
export const updateGameDefine = async (
  gameDefine: LimitedGameDefine
): Promise<LimitedGameDefine> => {
  const gameDefinePo = transform2GameDefinePo(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<GameDefinePo>>(
      URL_UPDATE_GAME_DEFINE,
      gameDefinePo
    )
    message.success('更新游戏模版成功！')
    return transformGameDefinePo(res.data)
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

export const addInfinityGameDefine = async (
  gameDefine: InfinityGameDefine
): Promise<Key> => {
  const gameDefinePo = transform2AddUnlimitedDrawDefineDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_INFINITY_GAME_DEFINE,
      gameDefinePo
    )
    message.success(
      gameDefinePo.type === '17' ? '新增塔层无限抽模版成功！' : res.msg
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

export const updateInfinityGameDefine = async (
  gameDefine: InfinityGameDefine
): Promise<Key> => {
  const gameDefinePo = transform2AddUnlimitedDrawDefineDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_INFINITY_GAME_DEFINE,
      gameDefinePo
    )
    message.success(
      gameDefinePo.type === '17' ? '修改塔层无限抽模版成功！' : res.msg
    )
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

// 删除游戏模版列表 todo 后端缺接口, 暂不提供
export const deleteGameDefineList = () => { }

// 查询游戏列表
export const queryGameList = async (
  params: ParamsQueryGame
): Promise<Pagination<Game>> => {
  const res = await Axios.get<Pagination<GamePo>>(URL_QUERY_GAME_LIST, {
    params,
  })
  return transfromPagination<GamePo, Game>(res.data, (list) => {
    return (list || []).map((gamePo) => {
      return transformGamePo(gamePo)
    })
  })
}

// 查询游戏套盒列表
export const queryGameBoxList = async (
  params: ParamsQueryGame
): Promise<Pagination<GameBox>> => {
  const res = await Axios.get<Pagination<GamePo>>(URL_QUERY_GAME_BOX_LIST, {
    params,
  })
  return transfromPagination<GamePo, GameBox>(res.data, (list) => {
    return (list || []).map((gamePo) => {
      return transformGamePo2GameBox(gamePo)
    })
  })
}

//查询积分游戏列表
export const queryGamePointsList = async (
  params: ParamsQueryGame
): Promise<Pagination<GameBox>> => {
  const res = await Axios.get(URL_QUERY_GAME_POINTS_LIST, {
    params,
  })
  return transfromPagination<GamePo, GameBox>(res.data, (list) => {
    return (list || []).map((gamePo) => {
      return transformGamePo2GamePoint(gamePo)
    })
  })
}

export const pullOffGameBox = async (id: Key) =>
  Axios.post(URL_PULL_OFF_GAME_BOX, { id })

// 查询未生效的游戏列表
export const queryUnenforcedGameList = async (
  params: ParamsQueryGame
): Promise<Pagination<Game>> => {
  const res = await Axios.get<Pagination<GamePo>>(
    URL_QUERY_UNENFORCED_GAME_LIST,
    {
      params,
    }
  )
  return transfromPagination<GamePo, Game>(res.data, (list) => {
    return (list || []).map((gamePo) => {
      return transformGamePo(gamePo)
    })
  })
}

// 新增游戏
export const addGame = async (game: Game): Promise<string> => {
  const gamePo = transform2GamePo(game)
  try {
    const { data: res } = await Axios.post<Result<string>>(URL_ADD_GAME, gamePo)
    message.success(res.msg)
    return res.data
  } catch (e: any) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 更新游戏
export const updateGame = async (game: any) => {
  const gamePo = transform2GamePo(game)
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_UPDATE_GAME,
      gamePo
    )
    message.success(res.msg)
    return res.data
  } catch (e: any) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 删除游戏列表 todo 后端缺接口, 暂不实现
export const deleteGameList = () => { }

// 查询游戏奖品等级下拉框
export const queryGameProductGradeOptions = () => {
  return queryProductCategoryOptions('', GAME_BASE_CATEGORY_ID_GRADE)
}

// 查询无限抽游戏奖品等级下拉框
export const queryInfinityGameProductGradeOptions = () => {
  return queryProductCategoryOptions('', INFINITY_GAME_BASE_CATEGORY_ID_GRADE)
}

// 查询积分抽游戏奖品等级下拉框
export const queryMealticketGameProductGradeOptions = async () => {
  const res = await queryProductCategoryOptions(
    '',
    INFINITY_GAME_BASE_CATEGORY_ID_GRADE
  )
  return res.filter(
    (i) => !['138', '137', '136', '135', '133'].includes(i.id as string)
  )
}

// 根据分类名称和父类id查询游戏分类详情列表 todo 后端缺接口
export const queryGameCategoryList = () => { }

// 新增游戏分类详情 todo 后端缺接口
export const addGameCategrory = () => { }

// 修改游戏分类详情 todo 后端缺接口
export const updateGameCatgeory = () => { }

// 删除游戏分类详情列表 todo 后端缺接口
export const deleteGameCategroyList = () => { }

// 登录

export const login = (loginCode: string) => {
  const url = (window.location.origin === 'https://test.cookactoys.cn' || window.location.origin === 'http://localhost:3000') ? '/goapi/v1/qr/login' : URL_LOGIN
  return Axios.get(url, {
    params: {
      code: loginCode,
    },
  })
}

export const queryVipMembers = async (params: queryVipMembersParams) => {
  const { data } = await Axios.get<Pagination<UserDTO>>(URL_QUERY_VIP_MEMBERS, {
    params,
  })
  return transfromPagination<UserDTO, VipMember>(data, (list) => {
    return (list || []).map((dto) => transformUserDto(dto))
  })
}

// 更换头像
export const changeAccountAvatar = async (params: changeAvatarParams) => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_Change_AVATAR,
      params
    )
    notification.success({
      message: res.msg as string,
    })
    return true
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

export const topup = async (walletId: string, amount: number, desc: string) => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(URL_TOPUP, {
      id: walletId,
      balance: transform2ServerAmount(amount),
      description: desc,
    })
    notification.success({
      message: res.msg as string,
    })
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}
export const topupByGrant = async (
  walletId: string,
  amount: number,
  desc: string
) => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(URL_TOPUP_BY_GRANT, {
      id: walletId,
      balance: transform2ServerAmount(amount),
      description: desc,
    })
    notification.success({
      message: res.msg as string,
    })
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

export const queryNotYetShippedOrder = async (
  params: QueryApplyForDeliveryPoListParams
): Promise<Pagination<DeliveryOrder>> => {
  const {
    accountNo,
    shipperName,
    productName,
    mobileNo,
    pageNo,
    pageSize,
    state,
    pendingState,
    flagColor,
    flagState,
    duration,
    categoryId,
    ipId,
    priceSort
  } = params
  const [start, end] = duration || [];
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_NOT_YET_SHIPPED_ORDER,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        pendingState,
        flagColor,
        flagState,
        startTime: formatTimestamp(start),
        endTime: formatTimestamp(end),
        categoryId,
        ipId,
        priceSort: priceSort || undefined,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryReturnOrder = async (
  params: QueryApplyForDeliveryPoListParams
): Promise<Pagination<DeliveryOrder>> => {
  const {
    accountNo,
    shipperName,
    productName,
    mobileNo,
    pageNo,
    pageSize,
    state,
    startTime,
    endTime,
  } = params
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_RETURN_LIST,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        startTime,
        endTime,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryShippedOrder = async (
  params: QueryApplyForDeliveryPoListParams
) => {
  const {
    accountNo,
    shipperName,
    mobileNo,
    productName,
    pageNo,
    pageSize,
    state,
    startTime,
    endTime,
    applyNo,
  } = params
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_SHIPPED_ORDER,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        startTime,
        endTime,
        applyNo: applyNo || undefined,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryOrderDiscount = async (
  params: QueryApplyForDeliveryPoListParams
) => {
  const {
    accountNo,
    shipperName,
    mobileNo,
    productName,
    pageNo,
    pageSize,
    state,
    startTime,
    endTime,
    discountType,
  } = params
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_DISCOUNT_LIST,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        startTime,
        endTime,
        discountType,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryOrderTransfer = async (
  params: QueryApplyForDeliveryPoListParams
) => {
  const {
    accountNo,
    shipperName,
    mobileNo,
    productName,
    pageNo,
    pageSize,
    state,
    startTime,
    endTime,
  } = params
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_TRANSFER_LIST,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        startTime,
        endTime,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryOrderActiveShipment = async (
  params: QueryApplyForDeliveryPoListParams
) => {
  const {
    accountNo,
    shipperName,
    mobileNo,
    productName,
    pageNo,
    pageSize,
    state,
    startTime,
    endTime,
  } = params
  const res = await Axios.get<Pagination<ApplyForDeliveryPo>>(
    URL_QUERY_EVENT_ITEMS_LIST,
    {
      params: {
        accountNo: accountNo || undefined,
        name: shipperName || undefined,
        mobile: mobileNo || undefined,
        productName: productName || undefined,
        pageNo,
        pageSize,
        includeLuckyBag: state,
        startTime,
        endTime,
      },
    }
  )
  return transfromPagination<ApplyForDeliveryPo, DeliveryOrder>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformApplyForDeliveryPo(po))
    }
  )
}

export const queryProductTransferedRecords = async (
  params: QueryProductTransferedListParams
) => {
  const { accountNo, targetAccountNo, productName, pageNo, pageSize } = params
  const { data: res } = await Axios.get<
    Result<Pagination<ProductTransferRecordsDTO>>
  >(URL_QUERY_PRODUCT_TRANSFERED_RECORDS, {
    params: {
      accountId: accountNo || undefined,
      targetAccountId: targetAccountNo || undefined,
      productName: productName || undefined,
      pageNo,
      pageSize,
    },
  })
  return transfromPagination<ProductTransferRecordsDTO, TransferRecord>(
    res.data,
    (list) => {
      return (list || []).map((dto) => transformProductTransferRecordsDTO(dto))
    }
  )
}

export const deleteProductByIdWithBackendValidate = (id: Key) => {
  return Axios.post(URL_DELETE_PRODUCT_BY_ID, {
    id,
    check: true,
  })
}

export const deleteProductById = (id: Key) => {
  return Axios.post(URL_DELETE_PRODUCT_BY_ID, {
    id,
    check: false,
  })
}

export const deleteGameDefineByIdWithBackendValidate = (id: Key) => {
  return Axios.post(URL_DELETE_GAME_DEFINE_BY_ID, {
    id,
    check: true,
  })
}

export const deleteGameDefineById = (id: Key) => {
  return Axios.post(URL_DELETE_GAME_DEFINE_BY_ID, {
    id,
    check: false,
  })
}

export const queryGameRecords = async (params: ParamsQueryGameRecords) => {
  const res = await Axios.get<Pagination<GameRecordsPo>>(
    URL_QUERY_GAME_RECORDS,
    {
      params,
    }
  )
  return transfromPagination<GameRecordsPo, GameRecord>(res.data, (list) =>
    (list || []).map((po) => transfromGameRecordsPo(po))
  )
}

export const queryTopupRecords = async (params: ParamsQueryTopupRecords) => {
  const res = await Axios.get<Pagination<OrderPo>>(URL_QUERY_TOPUP_RECORDS, {
    params,
  })
  return transfromPagination<OrderPo, TopupRecord>(res.data, (list) => {
    return (list || []).map((po) => transformOrderPo(po))
  })
}

export const queryWithdrawRecords = async (
  params: ParamsQueryWithdrawRecords
) => {
  const res = await Axios.get<Pagination<WithdrawRecordsPo>>(
    URL_QUERY_WITHDRAW_RECORDS,
    {
      params,
    }
  )
  return transfromPagination<WithdrawRecordsPo, WithdrawRecord>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformWithdrawRecordsPo(po))
    }
  )
}

export const queryBillsRecords = async (params: ParamsQueryBillsRecords) => {
  const res = await Axios.get<Pagination<AmountRecordsPo>>(
    URL_QUERY_BILLS_RECORDS,
    {
      params: {
        accountNo: params.accountNo,
        businessKeyType: params.recordType,
        type: params.businessType,
        pageNo: params.pageNo,
        pageSize: params.pageSize,
      },
    }
  )
  return transfromPagination<AmountRecordsPo, BillsRecord>(res.data, (list) => {
    return (list || []).map((po) => transformAmountRecordsPo(po))
  })
}

export const queryAwardsSettings = async (
  params: ParamsqueryAwardsSettings
) => {
  const res = await Axios.get<Pagination<VipLevelCollocationPo>>(
    URL_QUERY_REWARDS_SETTINGS,
    {
      params,
    }
  )
  return transfromPagination<VipLevelCollocationPo, AwardsSetting>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformVipLevelCollocationPo(po))
    }
  )
}

export const addAwardsSetting = async (setting: VipLevelCollocationPo) => {
  await Axios.post<Result<VipLevelCollocationPo>>(
    URL_ADD_REWARD_SETTING,
    setting
  )
}

export const deleteAwardsSetting = async (id: Key) => {
  const setting = {
    id,
  }

  try {
    await Axios.post<Result<VipLevelCollocationPo>>(
      URL_DELETE_REWARD_SETTING,
      setting
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const queryGarageKits = async (params: ParamsQueryGarageKits) => {
  const res = await axios.get<Pagination<KnapsackPo>>(URL_QUERY_GARAGE_KITS, {
    params,
  })
  return transfromPagination(res.data, (list) =>
    (list || []).map((po) => transformKnapsackPo(po))
  )
}

export const blockAccount = async (walletId: Key) => {
  return axios.post<{ id: Key }>(URL_BLOCKED_ACCOUNT, {
    id: walletId,
  })
}

export const unblockAccount = async (walletId: Key) => {
  return axios.post<{ id: Key }>(URL_UNBLOCKED_ACCOUNT, {
    id: walletId,
  })
}

export const confirmWithdraw = async (
  walletId: Key,
  amount: number,
  secret: string
) => {
  let res = null
  try {
    res = await axios.post(URL_CONFIRM_WITHDRAW, {
      id: walletId,
      balance: transform2ServerAmount(amount),
      secret,
    })
  } catch (e: any) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
  return res
}

export const withdrawByWechat = async (
  walletId: Key,
  amount: number,
  secret: string
) => {
  let id = null
  try {
    const { data: res } = await axios.post<{ data: { id: Key } }>(
      URL_WITHDRAW_BY_WECHAT,
      {
        id: walletId,
        balance: transform2ServerAmount(amount),
        secret,
      }
    )
    id = res.data.id
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
  return id
}

export const withdrawByAlipay = async (
  walletId: Key,
  amount: number,
  secret: string
) => {
  let id = null
  try {
    const { data: res } = await axios.post<{ data: { id: Key } }>(
      URL_WITHDRAW_BY_ALIPAY,
      {
        id: walletId,
        balance: transform2ServerAmount(amount),
        secret,
      }
    )
    id = res.data.id
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
  return id
}

export const withdrawThroughGo = async (id: Key) => {
  return axios.post(
    URL_WITHDRAW_THROUGH_GO,
    {
      id,
    },
    {
      headers: {
        'X-Token': sessionStorage.getItem(LOGIN_TOKEN_KEY),
      },
    }
  )
}

export const withdrawByAlipayThroughGo = async (id: Key) => {
  return axios.post(
    URL_WITHDRAW_BY_ALIPAY_THROUGH_GO,
    {
      id,
    },
    {
      headers: {
        'X-Token': sessionStorage.getItem(LOGIN_TOKEN_KEY),
      },
    }
  )
}

export const queryPosterList = async (params: queryPosterListParams) => {
  const res = await axios.get<Pagination<PosterListPo>>(URL_QUERY_POSTER_LIST, {
    params,
  })
  return transfromPagination(res.data, (list) => {
    return (list || []).map((po) => transformPosterListPo(po))
  })
}

export const addPPoster = () => { }
export const updatePoster = () => { }

export const auditShip = async (params: {
  applyNo: string
  verifyState: AuditShipConclusion
  idList: string[]
  targetAccountId: string
  auditRemark: string
  secret: string
  costPrice: number
}) => {
  try {
    params.costPrice = transform2ServerAmount(params.costPrice)
    const { data: { msg } } = await Axios.post(URL_SHIP_AUDIT, params)
    notification.success({
      message: msg,
    })
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
  }
}

export const addPoster = async (params: PosterListPo) => {
  try {
    await Axios.post<PosterListPo>(URL_ADD_POSTER, params)
    message.success({
      content: '设置成功',
      duration: 2,
    })
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const deletePoster = (id: Key) => {
  Axios.post(URL_DELETE_POSTER, { id })
}

export const sendBackShipment = async (ids: string[]) => {
  try {
    const {
      data: { msg },
    } = await Axios.post<{ idList: string[] }, any>(URL_SEND_BACK_SHIP, {
      idList: ids,
    })
    message.warning(msg)
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const cloneGameDefine = (gameDefineId: Key) => {
  Axios.post(URL_CLONE_GAME_DEFINE, {
    gameDefineId,
  })
}

export const queryReckoning = async (params: ParamsQueryDailyStatistics) => {
  const { accountId, startDate, endDate, pageNo, pageSize } = params
  const dealedParams = {
    ...params,
    accountId: accountId || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    pageNo,
    pageSize,
  }

  const { data: res } = await Axios.post<Result<DailyStatistics>>(
    URL_QUERY_RECKONING,
    {
      ...dealedParams,
      pageSize: params.pageSize - 1,
    }
  )
  const { total } = res?.data || {}
  const transformedResult = transfromPagination<
    DailyStatisticsItem,
    ReckoningItem
  >(res?.data, (list) =>
    (list || []).map((item) => transformDailyStatisticsItem(item))
  )
  const transformedTotal = total && transformDailyStatisticsItem(total)
  console.log(total);

  transformedTotal.accountNo = '总计'
  if (transformedTotal) transformedResult.list.push(transformedTotal)
  return transformedResult
}

export const queryKnapsackStatistic = async (
  params: ParamsQueryKnapsackStatistics
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<KnapsackAllStatisticsDTO>>
  >(URL_QUERY_KNAPSACK_STATISTIC, {
    params,
  })
  const transformedResult = transfromPagination<
    KnapsackAllStatisticsDTO,
    KnapsackStatistic
  >(res?.data, (list) =>
    (list || []).map((item) => transformKnapsackAllStatisticsDTO(item))
  )
  return transformedResult
}

export const queryDailyKnapsackStatistic = async (
  params: ParamsQueryDailyKnapsackStatistics
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<KnapsackAllDailyStatisticsDTO>>
  >(URL_QUERY_DAILY_KNAPSACK_STATISTIC, {
    params,
  })
  const transformedResult = transfromPagination<
    KnapsackAllDailyStatisticsDTO,
    DailyKnapsackStatistic
  >(res?.data, (list) =>
    (list || []).map((item) => transformKnapsackAllDailyStatisticsDTO(item))
  )
  return transformedResult
}

export const queryUserKnapsackStatistic = async (
  params: ParamsQueryUserKnapsackStatistics
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<KnapsackDailyStatisticsByAccountDTO>>
  >(URL_QUERY_USER_KNAPSACK_STATISTIC, {
    params,
  })
  const transformedResult = transfromPagination<
    KnapsackDailyStatisticsByAccountDTO,
    UserKnapsackStatistic
  >(res?.data, (list) =>
    (list || []).map((item) =>
      transformKnapsackDailyStatisticsByAccountDTO(item)
    )
  )
  return transformedResult
}

export const queryUserRealTimeKnapsackStatistic = async (
  params: ParamsQueryUserRealTimeKnapsackStatistics
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<KnapsackDailyStatisticsByAccountDTO>>
  >(URL_QUERY_USER_REAL_TIME_KNAPSACK_STATISTIC, {
    params,
  })
  const transformedResult = transfromPagination<
    KnapsackDailyStatisticsByAccountDTO,
    UserKnapsackStatistic
  >(res?.data, (list) =>
    (list || []).map((item) =>
      transformKnapsackDailyStatisticsByAccountDTO(item)
    )
  )
  return transformedResult
}

export const queryUserRealTimeAmount = async (
  params: ParamsQueryUserRealTimeAmount
) => {
  const { data: res } = await Axios.get<Result<UserRealTimeAmountInterfaceDTO>>(
    URL_QUERY_USER_REAL_TIME_DATA,
    {
      params,
    }
  )

  let arr = []
  arr.push(transformUserRealTimeAmountDTO(res.data))

  return arr
}

export const giveGift = async (params: ParamsGiveGift) => {
  const response = await Axios.post<Result<undefined>>(URL_GIVE_GIFT, params)
  const { data: res } = response
  // const { code, msg } = res
  // if (code !== CODE_REQ_SUCC) throw new Error(msg)
  // if (res)
  message.success({
    content: res.msg,
    duration: 2,
  })
}

export const queryGiveGiftRecords = async (
  params: ParamsQueryGiveGiftRecords
) => {
  const response = await Axios.get<Result<Pagination<GiveGiftsRecordPo>>>(
    URL_QUERY_GIVE_GIFT_RECORDS,
    {
      params,
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<GiveGiftsRecordPo, GiveGiftRecord>(data, (list) =>
    list.map((po) => transformGiveGiftsRecordPo(po))
  )
}

export let UserRole = false
export let VipGradeRole = false
export let allowBuyCard = false
export const queryUserRole = async () => {
  const res = await Axios.get<Result<UserRoleData>>(
    URL_QUERY_USER_ROLE
  )

  if (typeof res !== 'string') {
    UserRole = res.data.data.allowModifyShippedAmount || false //没有不能在发货修改商品价格
    sessionStorage.setItem('userRole', JSON.stringify(res.data.data.allowModifyShippedAmount));
    VipGradeRole = res.data.data.userGradeOnOff || false //没有不能看会员评级
    allowBuyCard = res.data.data.allowBuyCard || false //没有不能代购优惠卡
    const infoData = {
      avatar: res?.data?.data.userDTO?.uri,
      nickName: res?.data?.data.userDTO?.wechatName,
      phoneNum: res?.data?.data.userDTO?.phoneNum,
      id: res?.data?.data.userDTO?.accountNo
    }

    sessionStorage.setItem('userInfo', JSON.stringify(infoData));
    return res.data.data.code
  } else {
    return res
  }
}

export const onSaleInfinityGameDefine = async (
  params: OnSaleInfinityGameDefineParams
) => {
  try {
    const { data: res } = await Axios.post<
      OnSaleInfinityGameDefineParams,
      Result<Result<Key>>
    >(URL_ON_SALE_INFINITY_GAME_DEFINE, params)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const offShelvesInfinityGameDefine = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(
      URL_OFF_SHELVE_INFINITY_GAME_DEFINE,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 厨神榜单文件查询列表
export const queryChefListFileList = async (
  params: ParamsqueryChefListFileLists
) => {
  const response = await Axios.get<Result<Pagination<ChefListFileListPo>>>(
    URL_CHEF_LIST_FILE_LIST,
    {
      params,
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<ChefListFileListPo, KitchenGod>(data, (list) =>
    list.map((po) => transformChefListFileListPo(po))
  )
}

// 厨神榜文件模版下载地址
export const delChefListFile = (record?: KitchenGodsSetting) => {
  window.open(
    record ? transformUri(record.documentUrl || '') : URL_CHEF_LIST_FILE_DEMO
  )
}

export const deleteKitchenGodsSetting = async (id: Key) => {
  try {
    await Axios.post<Result<undefined>>(URL_DEL_CHEF_LIST_FILE, { id })
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 厨神榜单文件内容信息
export const queryChefListDataByFileId = async (
  params: ParamsquerychefListDataByFileId
) => {
  const response = await Axios.get<Result<Pagination<ChefListFileListDto>>>(
    URL_DEL_CHEF_LIST_DARABY_FILEID,
    {
      params,
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<ChefListFileListDto, KitchenGodFileList>(
    data,
    (list) => list.map((po) => transformChefListFileListDto(po))
  )
}

// 查询排行榜配置相关信息
export const queryRankingListManageList = async (
  params: ParamsQueryByRanking
) => {
  const response = await Axios.get<
    Result<Pagination<SaveRankingsPrizePoolDTO>>
  >(URL_QUERY_RANKING_LIST_MANAGE__LIST, {
    params,
  })
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<SaveRankingsPrizePoolDTO, RankingPoster>(
    data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSaveLeaderboardConfigPo(gameDefinePo)
      })
    }
  )
}

// 查询排行榜大奖
export const queryRankingBigPrize = async () => {
  let res = null
  try {
    res = await Axios.get<Result<RankingsBigPrize[]>>(
      URL_QUERY_RANKING_BIH_PRIZE
    )
  } catch (e) {
    message.error(e)
  }
  return res?.data.data
}

// 保存排行榜配置
export const addnRankingListManageDefine = async (
  gameDefine: RankingPoster
): Promise<Key> => {
  const gameDefinePo = transform2SaveLeaderboardConfigDTO(gameDefine)
  const response = await Axios.post<Result<Key>>(
    URL_UPDATE_QUERY_RANKING_GAME,
    gameDefinePo
  )
  const { data: res } = response
  const { data, code, msg } = res
  message.success(msg)
  return data
}

export const updateRankingListManageDefine = async (
  gameDefine: RankingPoster
): Promise<Key> => {
  const gameDefinePo = transform2SaveLeaderboardConfigDTO(gameDefine)
  const response = await Axios.post<Result<Key>>(
    URL_UPDATE_QUERY_RANKING_GAME,
    gameDefinePo
  )
  const { data: res } = response
  const { data, code, msg } = res
  message.success(msg)
  return data
}

// 排行榜删除
export const deleteRankingListManageById = async (id: Key) => {
  try {
    await Axios.post<Result<Key>>(URL_DELETE_GAME_RANKING_BY_ID, {
      id,
    })
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 通过Id获取排行榜配置
export const queryRankingListManageDetails = async (
  id: Key
): Promise<RankingPoster> => {
  const response = await Axios.get<Result<SaveRankingsPrizePoolDTO>>(
    URL_QUERY_RANKING_DETAILS,
    {
      params: {
        id,
      },
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transformSaveLeaderboardConfigPo(data)
}

// 上下架排行榜
export const offShelvesRankingListManage = async (id: Key) => {
  await Axios.post<{ id: Key }, AxiosResponse<null>>(URL_OFF_ON_RANKING_GAME, {
    id,
  })
}

// 获取厨神榜文件列表
export const getChefListFileList = async () => {
  const response = await Axios.get<Result<ChefListFileListPo[]>>(
    URL_GET_CHEF_LIST_FILE_LIST
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return data.map((item) => transformGetChefListFileListPo(item))
}

// 积分抽赏模版查询
export const queryPointsDrawDefineList = async (
  params: ParamsQueryPointsDraw
) => {
  const res = await Axios.get<Pagination<AddOrUpdatePointsDrawDefineDTO>>(
    URL_QUERY_POINTS_DRAW_DEFINE_LIST,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<AddOrUpdatePointsDrawDefineDTO, PointsDrawDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformAddOrUpdatePointsDrawDefineDTO(gameDefinePo)
      })
    }
  )
}

// 新增积分抽赏模版
export const addPointsDrawDefine = async (
  gameDefine: PointsDrawDefine
): Promise<Key> => {
  const gameDefinePo = transform2AddPointsDrawDefineDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATA_POINTS_DRAW_DEFINE,
      gameDefinePo
    )
    message.success(res.msg)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 修改积分抽赏模版
export const updatePointsDrawDefine = async (
  gameDefine: PointsDrawDefine
): Promise<Key> => {
  const gameDefinePo = transform2AddPointsDrawDefineDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATA_POINTS_DRAW_DEFINE,
      gameDefinePo
    )
    message.success(res.msg)
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

// 通过抽赏模版Id获取详情
export const queryPointsDrawDefineDetails = async (
  gameDefineId: Key
): Promise<PointsDrawDefine> => {
  const res = await Axios.get<Result<AddOrUpdatePointsDrawDefineDTO>>(
    URL_QUERY_POINTS_DRAW_DEFINE_BY,
    {
      params: {
        defineId: gameDefineId,
      },
    }
  )
  return transformAddOrUpdatePointsDrawDefineDTO(res.data.data)
}

// 积分抽赏模版上架
export const onSalePointsDrawGameDefine = async (
  params: OnSalePointsDraGameDefineParams
) => {
  try {
    const { data: res } = await Axios.post<
      OnSalePointsDraGameDefineParams,
      AxiosResponse<Result<string>>
    >(URL_ON_POINTS_DRAW_DEFINE, params)

    if (res.code !== CODE_REQ_SUCC) {
      // notification.error({
      //   message: res.msg as string
      // });
      throw new Error(res.msg as string)
    }

    return res.data
  } catch (error) {
    // notification.error({
    //   message: error as string
    // });
    throw new Error(error as string)
  }
}

// 积分抽赏模版删除
export const deletePointsDrawGameDefineByIdWithBackendValidate = (id: Key) => {
  return Axios.post(URL_POINTS_DRA_DELETE_GAME_DEFINE_BY_ID, {
    id,
    check: true,
  })
}

// 积分抽赏模版删除
export const deletePointsDrawGameDefineById = (id: Key) => {
  return Axios.post(URL_POINTS_DRA_DELETE_GAME_DEFINE_BY_ID, {
    id,
    check: false,
  })
}

// 赠予文件模版/单文件下载地址
export const giftUserDemoListFile = (record?: KitchenGodsSetting) => {
  window.open(
    record
      ? transformUri(record.documentUrl || '')
      : transformUri(URL_GIFT_USER_DEMO)
  )
}

// 赠予文件查询列表
export const giftUserFileList = async (
  params: ParamsqueryGiftUserFileLists
) => {
  const response = await Axios.get<Result<Pagination<GiftUserFileListPo>>>(
    URL_GIFT_USER_FILE_LIST,
    {
      params,
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<GiftUserFileListPo, GiftManageFile>(data, (list) =>
    list.map((po) => transformGiftUserFileListPo(po))
  )
}

// 厨神榜单文件内容信息
export const giftUserListDataByFileId = async (
  params: ParamsqueryGiftUserByFileId
) => {
  const response = await Axios.get<Result<Pagination<GiftUserFileListDTO>>>(
    URL_GIFT_USER_FILE_LIST_ID,
    {
      params,
    }
  )
  const { data: res } = response
  const { data, code, msg } = res
  if (code !== CODE_REQ_SUCC) throw new Error(msg)
  return transfromPagination<GiftUserFileListDTO, GiftManageFileList>(
    data,
    (list) => list.map((po) => transformGiftUserFileListDTO(po))
  )
}

// 是否赠予文件
export const uploadGiftUserFileConfirmYes = async (gameDefineId: Key) => {
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_UPLOAD_GIFT_USER_FILE_CONFIRM,
      {
        id: gameDefineId,
        confirm: true,
      }
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
export const uploadGiftUserFileConfirmNo = async (gameDefineId: Key) => {
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_UPLOAD_GIFT_USER_FILE_CONFIRM,
      {
        id: gameDefineId,
        confirm: false,
      }
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 查询任务配置
export const querySignInManage = async (params: ParamsQueryByRanking) => {
  const { data: res } = await Axios.get<
    Result<Pagination<saveSignInRulesConfigDTO>>
  >(URL_QUERY_SIGN_IN_MANAGE, {
    params,
  })

  return transfromPagination<saveSignInRulesConfigDTO, SignInManagePoster>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSaveSignInRulesConfigPo(gameDefinePo)
      })
    }
  )
}

// 保存任务
export const addSignInManage = async (
  gameDefine: SignInManagePoster
): Promise<Key> => {
  const gameDefinePo = transform2SaveSignInRulesConfigDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_SIGN_IN_MANAGE,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
export const updateSignInManage = async (
  gameDefine: SignInManagePoster
): Promise<Key> => {
  const gameDefinePo = transform2SaveSignInRulesConfigDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_SIGN_IN_MANAGE,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 通过Id查询任务
export const querySignInManageID = async (
  id: Key
): Promise<SignInManagePoster> => {
  const { data: res } = await Axios.get<Result<saveSignInRulesConfigDTO>>(
    URL_QUERY_ID_SIGN_IN_MANAGE,
    {
      params: {
        id,
      },
    }
  )
  return transformSaveSignInRulesConfigPo(res.data)
}

// 删除任务
export const deleteSignInManageID = async (id: Key) => {
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_DELETE_SIGN_IN_MANAGE_BY_ID,
      {
        id,
      }
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 上下架任务
export const offonSignInManage = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(
      URL_OFF_ON_SIGN_IN_MANAGE,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 盈亏查询
export const queryProfitLoss = async (params: ParamsQueryProfitLoss) => {
  const { data: res } = await Axios.get<Result<Pagination<ProfitLossDTO>>>(
    URL_QUERY_PROFITLOSS,
    {
      params,
    }
  )
  return transfromPagination<ProfitLossDTO, ProfitLosss>(res.data, (list) => {
    return (list || []).map((dto) => transformProfitLossDTO(dto))
  })
}

// 物品审核 获取审核数据
export const queryAuditList = async (params: QueryAuditListParams) => {
  const { type, state, describe, pageNo, pageSize, giftAccountNo } = params;

  const { data: res } = await Axios.get<Result<Pagination<AuditListPo>>>(
    URL_QUERY_AUDIT_LIST,
    {
      params: {
        type,
        state,
        describe: describe ? describe : undefined,
        pageNo,
        pageSize,
        giftAccountNo: giftAccountNo ? giftAccountNo : undefined,
      },
    }
  )

  return transfromPagination<AuditListPo, ArticleReviewAudit>(
    res.data,
    (list) => {
      // .sort((a, b) => (a.state as number)- (b.state as number))
      return (list || []).map((po) => transformAuditListPo(po))
    }
  )
}

// 审核同意
export const sendAuditConfirmTrue = async (ids: Key[]) => {
  try {
    await Axios.post<{ idList: Key[]; confirm: boolean }, Result<Key>>(
      URL_AUDIT_CONFIRM,
      { idList: ids, confirm: true }
    )
    message.success('审核成功');
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 审核驳回
export const sendAuditConfirmFals = async (ids: Key[]) => {
  try {
    await Axios.post<{ idList: Key[]; confirm: boolean }, Result<Key>>(
      URL_AUDIT_CONFIRM,
      { idList: ids, confirm: false }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 审核文件下载
export const downloadAuditFile = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.click()
}

// 挂起管理
export const sendApplyShipmentPending = async (porps: {
  ids: Key[]
  pendingState: number
  pendingStateDesc: string
}) => {
  const { ids, pendingState, pendingStateDesc } = porps
  try {
    await Axios.post<{ idList: Key[]; pendingState: number }, Key>(
      URL_APPLY_SHIPMENT_PENDING,
      { idList: ids, pendingState, pendingStateDesc }
    )
    message.success('挂起成功')
  } catch (e) {
    message.error(e)
  }
}

// 标记颜色
export const signColorApi = async (porps: SignColorParams) => {
  const { idList, flagColor, flagColorDesc } = porps
  try {
    await Axios.post<SignColorParams, Key>(URL_APPLY_COLOR_FLAG, {
      idList: idList,
      flagColor,
      flagColorDesc,
    })
    message.success('标记成功')
  } catch (e) {
    message.error(e)
  }
}

// 回收记录
export const queryUserRecyclingRecords = async (
  params: ParamsQueryUserRecyclingRecords
) => {
  const res = await Axios.get<Result<Pagination<UserRecyclingRecordsDTO>>>(
    URL_USER_RECYCLING_RECORDS,
    {
      params,
    }
  )
  return transfromPagination<UserRecyclingRecordsDTO, UserRecyclingRecords>(
    res.data.data,
    (list) => {
      return (list || []).map((dto) => transformUserRecyclingRecordsDTO(dto))
    }
  )
}

// 查询物品池子
export const queryItemPoolList = async (params: ParamsQueryByItemPool) => {
  const { data: res } = await Axios.get<Result<Pagination<SaveItemPoolDTO>>>(
    URL_QUERY_ITEM_POOL,
    {
      params,
    }
  )

  return transfromPagination<SaveItemPoolDTO, MainItemPool>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSaveSaveItemPoolDTO(gameDefinePo)
      })
    }
  )
}
// ID查询物品池子
export const queryItemPoolDetails = async (
  gameDefineId: Key
): Promise<ItemPoolsSepc> => {
  const { data: res } = await Axios.get<Result<SaveItemPoolDTO>>(
    URL_QUERY_ITEM_POOL_DETAILS,
    {
      params: {
        id: gameDefineId,
      },
    }
  )
  return transformSaveItemPoolDTO(res.data)
}
// 新增物品池子
export const addItemPool = async (gameDefine: ItemPoolsSepc): Promise<Key> => {
  const gameDefinePo = transform2SaveItemPoolDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_ITEM_POOL,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
// 修改物品池子
export const updateItemPool = async (
  gameDefine: ItemPoolsSepc
): Promise<Key> => {
  const gameDefinePo = transform2SaveItemPoolDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_ITEM_POOL,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
// 删除物品池子
export const deleteItemPool = async (id: Key) => {
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_DELETE_ITEM_POOL_BY_ID,
      {
        id,
      }
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 删除隐藏池
export const deleteHiddenPool = async (id: Key) => {
  try {
    const { data: res } = await Axios.post<Result<string>>(
      URL_DELETE_HIDDEN_POOL_BY_ID,
      {
        id,
      }
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 隐藏池上下架
export const offONHiddenPoolGame = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(
      URL_OFF_ON_HIDDEN_POOL,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 查询隐藏池
export const queryHiddenPoolList = async (params: ParamsQueryByHiddenPool) => {
  const { data: res } = await Axios.get<Result<Pagination<SaveHiddenPoolDTO>>>(
    URL_QUERY_HIDDEN_POOL,
    {
      params,
    }
  )
  return transfromPagination<SaveHiddenPoolDTO, HiddenPoolGame>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSaveHiddenPoolDTO(gameDefinePo)
      })
    }
  )
}

// ID查询隐藏池
export const queryHiddenPoolDetails = async (
  gameDefineId: Key
): Promise<HiddenPoolGame> => {
  const { data: res } = await Axios.get<Result<SaveHiddenPoolDTO>>(
    URL_QUERY_HIDDEN_POOL_DETAILS,
    {
      params: {
        id: gameDefineId,
      },
    }
  )
  return transformSaveHiddenPoolDTO(res.data)
}

// 添加隐藏池
export const addHiddenPool = async (
  gameDefine: HiddenPoolGame
): Promise<Key> => {
  const gameDefinePo = transform2SaveHiddenPoolDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_HIDDEN_POOL,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
// 修改隐藏池
export const updateHiddenPool = async (
  gameDefine: HiddenPoolGame
): Promise<Key> => {
  const gameDefinePo = transform2SaveHiddenPoolDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_HIDDEN_POOL,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 名人堂记录
export const queryPrizeRecords = async (params: ParamsPrizeGameRecords) => {
  const { data: res } = await Axios.get<Result<Pagination<PrizeRecordsPo>>>(
    URL_QUERY_PIRIZE_RECORDS,
    {
      params,
    }
  )
  return transfromPagination<PrizeRecordsPo, PrizeRecords>(res.data, (list) =>
    (list || []).map((po) => transfromPrizeRecordsPo(po))
  )
}

// 用户名人堂记录

export const queryUserPrizeRecords = async (params: ParamsPrizeGameRecords) => {
  try {
    const { data: res } = await Axios.get<Result<Pagination<PrizeRecordsPo>>>(
      URL_QUERY_USER_PIRIZE_RECORDS,
      {
        params,
      }
    )
    return transfromPagination<PrizeRecordsPo, PrizeRecords>(res.data, (list) =>
      (list || []).map((po) => transfromPrizeRecordsPo(po))
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 运维表头
export const queryDailyOperationTitles = async (
  params: ParamsQueryDailyOperationTitles
) => {
  const { data: res } = await Axios.get<Result<{}>>(
    URL_QUERY_DAILY_OPERATION_TITLE,
    {
      params,
    }
  )

  return transfromDailyOperationTitlePo(res.data)
}

// 运维数据
export const queryDailyOperationDatas = async (
  params: ParamsQueryDailyOperations
) => {
  const { data: res } = await Axios.get<Result<Pagination<any>>>(
    URL_QUERY_DAILY_OPERATION_DATA,
    {
      params,
    }
  )
  return transfromPagination<any, any>(res.data, (list) => {
    return list || []
  })
}

// 下载运维数据
export const exportDailyOperationData = async (
  params: ParamsExportDailyOperationTitles
) => {
  // await Axios.get(URL_EXPORT_DAILY_OPERATION_DATA, { params })
  const res = await Axios({
    url: URL_EXPORT_DAILY_OPERATION_DATA,
    responseType: 'blob',
    params,
    method: 'GET',
    timeout: 0,
  })
  return res
}

// 获取行政区划地址列表
export const queryAdministrativeDivision = async (parentCode?: Key) => {
  const addressPoList = await Axios.get<{ parentId: Key }, Result<AddressPo[]>>(
    URL_QUERY_ADMINISTRATIVE_DIVISION_OPTIONS,
    {
      params: {
        parentId: parentCode || SUPERIOR_ADMINISTRATIVE_DIVISION_CODE,
      },
    }
  )
  return addressPoList.data.map((item) => transformAddressPo(item))
}

// 获取用户地址列表
export const queryUserAddressList = async (id?: Key) => {
  const { data: res } = await Axios.get<
    { accountNo: Key },
    ResponseResult<Result<UserAddressPo[]>>
  >(URL_QUERY_USER_ADDRESS_LIST, {
    params: {
      accountNo: id,
    },
  })
  return res.data.map((item) => transformUserAddressPo(item))
}

//查询优惠卷列表
export const queryExChangeCodeList = async (params: ExChangeCodeList) => {
  const res = await Axios.get<Result<Pagination<ExchangCodeListPo>>>(
    URL_QUERY_EXCHANGE_CODE_LIST,
    {
      params,
    }
  )
  return transfromPagination<ExchangCodeListPo, VoucherDefine>(
    res.data.data,
    (list) => {
      return (list || []).map((Po) => transformExChangListDTO(Po))
    }
  )
}

//导出优惠卷列表
export const exportExChangeCodeList = async (
  params: ExportExChangeCodeList
) => {
  // await Axios.get(URL_EXPORT_EXCHANGE_CODE_LIST, { params })
  const res = await Axios({
    url: URL_EXPORT_EXCHANGE_CODE_LIST,
    responseType: 'blob',
    params,
    method: 'GET',
    timeout: 0,
  })
  return res
}

//查询优惠卷列表
export const queryExChangeList = async (params: ParamsQueryByCategory) => {
  const res = await Axios.get<Result<Pagination<GameDefinePo>>>(
    URL_QUERY_EXCHANGE_LIST,
    {
      params,
    }
  )

  return transfromPagination<GameDefinePo, LimitedGameDefine>(
    res.data.data,
    (list) => {
      return (list || []).map((gameDefinePo) =>
        transformExGameDefinePo(gameDefinePo)
      )
    }
  )
}

//优惠卷下架
export const offShelvesExchange = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(
      URL_OFF_SHELVE_EXCHANGE,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

//优惠卷上架
export const onSaleExchange = async (
  params: OnSaleInfinityGameDefineParams
) => {
  try {
    await Axios.post<
      OnSaleInfinityGameDefineParams,
      AxiosResponse<Result<null>>
    >(URL_ON_SALE_EXCHANGE, params)
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

//查询大奖
export const queryGrandPrizeGradeList = async () => {
  const res = await Axios.get<Result<CategoryPO[]>>(
    URL_QUERY_GRANDO_PRIZE_GRADE_LIST
  )
  return (res.data.data || []).map((po) => transfromCategoryPo(po))
}

// 查询批量上架模版
export const queryBatchListingList = async (
  params: ParamsQueryBatchListing
) => {
  const { data: res } = await Axios.get<Result<Pagination<BatchListingPo>>>(
    URL_QUERY_BATECH_LISTING,
    {
      params,
    }
  )

  return transfromPagination<BatchListingPo, BatchListings>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformBatchListingPo(gameDefinePo)
      })
    }
  )
}

// 查询批量上架模版
export const queryBatchListingID = async (
  id: Key
): Promise<SaveBatchListings> => {
  const { data: res } = await Axios.get<Result<SaveBatchListingPo>>(
    URL_QUERY_ID_BATECH_LISTING,
    {
      params: {
        templateId: id,
      },
    }
  )
  return transformSaveBatchListingPo(res.data)
}

// 添加/修改批量上架模版
export const saveBatchListing = async (gameDefine: SaveBatchListings) => {
  const gameDefinePo = transform2SaveBatchListingPo(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<SaveBatchListingPo>>(
      URL_SAVE_BATECH_LISTING,
      gameDefinePo
    )
    return { ...gameDefinePo, ...res.data }
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 批量上架模版删除
export const deleteBatchListingId = async (id: Key) => {
  try {
    await Axios.post<Result<Key>>(URL_DELETE_BATECH_LISTING, {
      id,
    })
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 上下架批量上架模版
export const onSaleBatchListing = async (params: OnSaleBatchListingParams) => {
  try {
    await Axios.post<OnSaleBatchListingParams, AxiosResponse<Result<null>>>(
      URL_OFF_ON_BATECH_LISTING,
      params
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 查询待发货物品详情
export const queryOrderNotYetShippedGoods = async (
  params: ParamsOrderNotYetShippedGoods
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<OrderNotYetShippedGoodPo>>
  >(URL_QUERY_PRODUCT_INGO_BY, {
    params,
  })

  return transfromPagination<
    OrderNotYetShippedGoodPo,
    OrderNotYetShippedGoodPo
  >(res.data, (list) => {
    return (list || []).map((gameDefinePo) => {
      return transformOrderNotYetShippedGoodPo(gameDefinePo)
    })
  })
}

// 下载待发货物品详情
export const exportOrderNotYetShippedGoods = async (
  params: ParamsOrderNotYetShippedGoods
) => {
  // await Axios.get(URL_EXPORT_PRODUCT_INGO_BY, { params })
  const res = await Axios({
    url: URL_EXPORT_PRODUCT_INGO_BY,
    responseType: 'blob',
    params,
    method: 'GET',
    timeout: 0,
  })
  return res
}

// 用户领取记录管理
export const queryUserCollectionRecords = async (
  params: ParamsQueryUserCollectionRecords
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<UserCollectionRecordsPo>>
  >(URL_QUERY_GAME_USER_COLLLECTUON_RECORDS, {
    params,
  })
  return transfromPagination<UserCollectionRecordsPo, UserCollectionRecords>(
    res.data,
    (list) => (list || []).map((po) => transfromUserCollectionRecordsPo(po))
  )
}

// 查询排行榜奖励配置相关信息
export const queryRankingRewardList = async (
  params: ParamsQueryByRankingReward
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<SelectRankingRewardDTO>>
  >(URL_QUERY_RANKING_REWARD_LIST, {
    params,
  })

  return transfromPagination<SelectRankingRewardDTO, SelectRankingRewars>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSelectRankingRewardDTO(gameDefinePo)
      })
    }
  )
}

// 保存排行榜奖励配置
export const addRankingRewardDefine = async (
  gameDefine: AddRankingReward
): Promise<Key> => {
  const gameDefinePo = transform2SaveRankingRewardDTO(gameDefine)
  const { data: res } = await Axios.post<Result<Key>>(
    URL_UPDATE_QUERY_RANKING_REWARD_GAME,
    gameDefinePo
  )
  return res.data
}

export const updateRankingRewardDefine = async (
  gameDefine: AddRankingReward
): Promise<Key> => {
  const gameDefinePo = transform2SaveRankingRewardDTO(gameDefine)
  const { data: res } = await Axios.post<Result<Key>>(
    URL_UPDATE_QUERY_RANKING_REWARD_GAME,
    gameDefinePo
  )
  return res.data
}

// 排行榜奖励删除
export const deleteRankingRewardId = async (id: Key) => {
  try {
    return await Axios.post(URL_DELETE_GAME_RANKING_REWARD_BY_ID, {
      id,
    })
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 上下架排行榜奖励
export const offShelvesRankingReward = async (
  params: OnSaleRankingRewardsParams
) => {
  try {
    await Axios.post<OnSaleRankingRewardsParams, Result<AxiosResponse<null>>>(
      URL_OFF_ON_RANKING_REWARD_GAME,
      params
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const offShelRankingReward = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, Result<AxiosResponse<null>>>(
      URL_OFF_ON_RANKING_REWARD_GAME,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 通过Id获取排行榜奖励配置
export const queryRankingRewardDetails = async (
  id: Key
): Promise<SelectRankingRewarIDwebs> => {
  const { data: res } = await Axios.get<Result<SelectRankingRewardIDDTO>>(
    URL_QUERY_RANKING_REWARD_DETAILS,
    {
      params: {
        id,
      },
    }
  )
  return transformSelectRankingRewardIDDTO(res.data)
}

// 查询排行榜配置相关信息
export const queryRankingListManageReward = async () => {
  const res = await queryRankingListManageList({
    state: 1,
    pageNo: 1,
    pageSize: 999,
  })

  return res.list.map((item) => {
    return {
      id: item.id,
      name: `${item.leaderboardName}/${item.leaderboardTypeDesc}/${item.terminalTypeDesc}`,
    }
  })
}

// 厨神池铲子记录
export const queryShovelRecord = async (params: ParamsShovelRecord) => {
  try {
    const { data: res } = await Axios.get<Result<Pagination<ShovelRecordsPo>>>(
      URL_QUERY_SHOVEL_RECORD,
      {
        params,
      }
    )
    return transfromPagination<ShovelRecordsPo, ShovelRecords>(
      res.data,
      (list) => (list || []).map((po) => transfromShovelRecordsPo(po))
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 查询批量上架模版记录
export const queryBatchListingRecord = async (
  params: ParamsQueryBatchListing
) => {
  const { data: res } = await Axios.get<Result<Pagination<BatchListingPo>>>(
    URL_QUERY_BATECH_LISTING_RECORD,
    {
      params,
    }
  )
  return transfromPagination<BatchListingPo, BatchListings>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformBatchListingPo(gameDefinePo)
      })
    }
  )
}

export const queryBatchListingRecordID = async (
  id: Key
): Promise<SaveBatchListings> => {
  const { data: res } = await Axios.get<Result<SaveBatchListingPo>>(
    URL_QUERY_ID_BATECH_LISTING_RECORD,
    { params: { id } }
  )
  return transformSaveBatchListingPo(res.data)
}

// 饭票抽赏模版查询
export const queryMealticketGame = async (params: ParamsQueryPointsDraw) => {
  const res = await Axios.get<Pagination<AddOrUpdatePointsDrawDefineDTO>>(
    URL_QUERY_MEALTICKET_GAME,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<AddOrUpdatePointsDrawDefineDTO, PointsDrawDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformMealticketGameDTO(gameDefinePo)
      })
    }
  )
}

// 新增饭票抽赏模版
export const addMealticketGame = async (
  gameDefine: PointsDrawDefine
): Promise<Key> => {
  const gameDefinePo = transform2MealticketGameDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATA_MEALTICKET_GAME,
      gameDefinePo
    )
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}

// 修改饭票抽赏模版
export const updateMealticketGame = async (
  gameDefine: PointsDrawDefine
): Promise<Key> => {
  const gameDefinePo = transform2MealticketGameDTO(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATA_MEALTICKET_GAME,
      gameDefinePo
    )
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

// 通过饭票模版Id获取详情
export const queryMealticketGameID = async (
  gameDefineId: Key
): Promise<PointsDrawDefine> => {
  const res = await Axios.get<Result<AddOrUpdatePointsDrawDefineDTO>>(
    URL_QUERY_MEALTICKET_GAME_ID,
    {
      params: {
        defineId: gameDefineId,
      },
    }
  )
  return transformMealticketGameDTO(res.data.data)
}

//饭票抽赏模版上架
export const onSaleMealticketGame = async (
  params: OnSalePointsDraGameDefineParams
) => {
  try {
    const { data: res } = await Axios.post<
      OnSalePointsDraGameDefineParams,
      AxiosResponse<Result<null>>
    >(URL_ON_SALE_MEALTICKET_GAME, params)

    if (res.code !== CODE_REQ_SUCC) {
      // notification.error({
      //   message: res.msg as string
      // });
      throw new Error(res.msg as string)
    }
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 饭票抽赏模版删除
export const deleteMealticketGameFale = (id: Key) => {
  return Axios.post(URL_DELETE_MEALTICKET_GAME, {
    id,
    check: false,
  })
}

export const deleteMealticketGameTrue = (id: Key) => {
  return Axios.post(URL_DELETE_MEALTICKET_GAME, {
    id,
    check: true,
  })
}

// 上架饭票抽赏模版查询
export const queryGameMealticketList = async (
  params: ParamsQueryGame
): Promise<Pagination<GameBox>> => {
  const res = await Axios.get(URL_QUERY_GAME_MEALTICKET_LIST, {
    params,
  })
  return transfromPagination<GamePo, GameBox>(res.data, (list) => {
    return (list || []).map((gamePo) => {
      return transformGamePo2GameMealticke(gamePo)
    })
  })
}

export const topupByMealticket = async (
  accountNo: string,
  mealTicket: number,
  describe: string
) => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_TOPUP_BY_MEALTICKET,
      {
        accountNo,
        mealTicket: transform2ServerAmount(mealTicket),
        describe,
      }
    )
    notification.success({
      message: res.msg as string,
    })
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

//运维类型
export const queryOperationType = async () => {
  // <Pagination<GameBox>
  const {
    data: { data },
  } = await Axios.get<Result<object[]>>(URL_OPERATION_TYPE)

  return data.map((item: object) => {
    return {
      id: Object.keys(item)[0],
      name: Object.values(item)[0],
    }
  })
}

// 修改商品记录
export const queryProductEditRecords = async (
  params: ParamsQueryProductEditRecords
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<ProductEditRecordsPo>>
  >(URL_QUERY_PRODUCT_RECORDS, {
    params,
  })
  return transfromPagination<ProductEditRecordsPo, ProductEditRecords>(
    res.data,
    (list) => (list || []).map((po) => transfromProductEditRecordsPo(po))
  )
}

// 废弃 / 回撤 模版
export const abandonGameDefine = async (id: Key, abandonState: GameState) => {
  try {
    const { data: res } = await Axios.get<Result<string>>(
      URL_ADANDON_GAME_DEFIN,
      {
        params: {
          id,
          abandonState,
        },
      }
    )
    message.success(res.msg)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

export const updateProductIsAbandon = async (
  id: Key,
  abandonState: GameState
) => {
  try {
    const { data: res } = await Axios.get<Result<string>>(
      URL_ADANDON_PRODUCT_DEFIN,
      {
        params: {
          id,
          abandonState,
        },
      }
    )
    message.success(res.msg)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 相关模版
export const queryRelatedTemplate = async (
  params: ParamsQueryProductEditRecords
) => {
  const { data: res } = await Axios.get<Result<Pagination<RelatedTemplatPo>>>(
    URL_QUERY_RELATEDTEMPLATE,
    {
      params,
    }
  )

  return transfromPagination<RelatedTemplatPo, RelatedTemplatPo>(
    res.data,
    (list) => (list || []).map((po) => transfromRelatedTemplate(po))
  )
}

// 实时用户金额统计子表
export const getUserDataBeforeGiftData = async (
  params: ParamsUserDataBeforeGiftData
) => {
  const { data: res } = await Axios.get<
    Result<Pagination<UserRealTimeAmountInterfaceDTO>>
  >(URL_QUERY_USER_GIFT_DATA, {
    params,
  })

  return transfromPagination<
    UserRealTimeAmountInterfaceDTO,
    UserRealTimeAmountInterface
  >(res.data, (list) => {
    return (list || []).map((dto) => transformUserRealTimeAmountDTO(dto))
  })
}

//更新商品状态
export const updatePresale = async (ids: Key[]) => {
  try {
    const { data: res } = await Axios.post<Result<null>>(URL_UPDATE_PRESALE, {
      idList: ids,
    })
    message.success(res.msg)
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

// 预售商品变更为现货，同步拍卖行
export const syncPresale = async (productSpecId: Key) => {
  try {
    const { data: res } = await Axios.get<Result<string>>(URL_SYNC_PRESALE, {
      params: { productSpecId },
    })
    // message.success(res.msg)
  } catch (e) {
    throw new Error(e as string)
  }
}

//查询出所有的配置信息
export const querySystemAllCondition = async (
  params: ParamsQueryAllCondition
) => {
  try {
    const { data: res } = await Axios.get<Result<Pagination<AllConditionDTO>>>(
      URL_QUERY_ALL_CONDITION,
      {
        params,
      }
    )

    return transfromPagination<AllConditionDTO, AllConditionDTO>(
      res.data,
      (list) => {
        return (list || []).map((gameDefinePo) => {
          return transformAllConditiondDTO(gameDefinePo)
        })
      }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
//通过id查询配置信息详情
export const queryConditionByID = async (id: Key) => {
  const res = await Axios.get<Result<ConditionDefineDTO[]>>(
    URL_QUERY_CONDITION_BY_ID,
    {
      params: {
        id,
      },
    }
  )
  return transformConditiondDefineDTO(res.data.data[0])
}
//更新配置信息
export const updateConditionDefine = async (
  gameDefine: ConditionDefineDTO
): Promise<Key> => {
  Object.assign(gameDefine, {
    productList: gameDefine.productList?.map((item) => ({
      productSpecId: item.productSpecId,
    })),
  })
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_CONDITION,
      gameDefine
    )
    message.success(res.msg)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}

//塔模版列表信息
export const queryPagodaGameDefineList = async (
  params: ParamsQueryByCategory
) => {
  const res = await Axios.get<Pagination<AddUnlimitedDrawDefineDTO>>(
    URL_QUERY_PAGODA_GAME_DEFINE_LIST,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<AddUnlimitedDrawDefineDTO, InfinityGameDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformAddUnlimitedDrawDefineDTO(gameDefinePo)
      })
    }
  )
}
//通过Id获取塔模版详情
export const queryPagodaGameDefineDetails = async (
  gameDefineId: Key
): Promise<AddPagodaReward> => {
  const res = await Axios.get<Result<AddPagodaDrawDefineDTO>>(
    URL_QUERY_PAGODA_BY_ID,
    {
      params: {
        defineId: gameDefineId,
      },
    }
  )
  return transformPagodaGame(res.data.data)
}
//增加塔模版
export const addPagodaGameDefine = async (
  gameDefine: AddPagodaReward
): Promise<Key> => {
  const gameDefinePo = transform2PagodaGame(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_PAGODA_GAME_DEFINE,
      gameDefinePo
    )
    message.success(res.msg)
    return res.data
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}
//修改塔模型
export const updatePagodaGameDefine = async (
  gameDefine: AddPagodaReward
): Promise<Key> => {
  const gameDefinePo = transform2PagodaGame(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_UPDATE_PAGODA_GAME_DEFINE,
      gameDefinePo
    )
    message.success(res.msg)
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}
//增加塔模型
export const addPagodaGameDefineList = async (
  gameDefine: AddPagodaReward
): Promise<Key> => {
  const gameDefinePo = transform2AddPagodaGame(gameDefine)
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_Add_PAGODA_GAME_DEFINE,
      gameDefinePo
    )
    message.success(res.msg)
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}
//塔上架
export const onSalePagodaGameDefine = async (
  params: OnSalePointsDraGameDefineParams
) => {
  try {
    await Axios.post<
      OnSaleInfinityGameDefineParams,
      AxiosResponse<Result<null>>
    >(URL_ON_SALE_PAGODA_GAME_DEFINE, params)
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string)
  }
}
//塔下架
export const offShelvesPagodaGameDefine = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(
      URL_OFF_SHELVE_PAGODA_GAME_DEFINE,
      { id }
    )
  } catch (e) {
    // notification.error({
    //   message: e as string,
    // });
    throw new Error(e as string)
  }
}
//查询塔层模版
export const queryPagodaInfinityGameDefineList = async (
  params: ParamsQueryByCategory
) => {
  const res = await Axios.get<Pagination<AddUnlimitedDrawDefineDTO>>(
    URL_QUERY_PAGODA_INFINITY_GAME_DEFINE_LIST,
    {
      params: {
        ...params,
        abandonState: params.abandonState ? params.abandonState : '1',
      },
    }
  )
  return transfromPagination<AddUnlimitedDrawDefineDTO, InfinityGameDefine>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformAddUnlimitedDrawDefineDTO(gameDefinePo)
      })
    }
  )
}
//查询库存商品
export const queryStockList = async (params: ParamsQueryByStock) => {
  const res = await Axios.get<Pagination<StockListDefine>>(
    URL_QUERY_STOCK_LIST,
    {
      params,
    }
  )
  return transfromPagination<StockListDefine, StockListDefine>(
    res.data,
    (list) => {
      return (list || []).map((po) => {
        return transformStockLisDefineDTO(po)
      })
    }
  )
}

//商品入库
export const updateAddStockDefine = async (
  stockDefine: StockListDefine
): Promise<Key> => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_QUERY_ADD_STOCK,
      stockDefine
    )
    message.success(res.msg)
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}
//商品出库
export const updatePutStockDefine = async (
  stockDefine: StockListDefine
): Promise<Key> => {
  try {
    const { data: res } = await Axios.post<Result<Key>>(
      URL_QUERY_PUT_STOCK,
      stockDefine
    )
    message.success(res.msg)
    return res.data
  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string)
  }
}

//查询操作记录
export const queryStockRecord = async (params: ParamsQueryByStockRecord) => {
  const res = await Axios.get<Pagination<StockRecordDefine>>(
    URL_QUERY_STOCK_RECORD,
    {
      params,
    }
  )
  return transfromPagination<StockRecordDefine, StockRecordDefine>(
    res.data,
    (list) => {
      return (list || []).map((po) => {
        return transformStockRecordDTO(po)
      })
    }
  )
}

//导出退款记录
export const exportWithdrawRecords = async (
  params: ParamsExportWithdrawRecords
) => {
  // await Axios.get(URL_EXPORT_WITHDRAW_RECORDS, { params })
  const res = await Axios.get(URL_EXPORT_WITHDRAW_RECORDS, {
    responseType: 'blob',
    params,
    timeout: 0,
  })
  return res
}

export const queryConsoleDataCatalog = async () => {
  const res = await Axios.get<Key[]>(URL_QUERY_CONTROL_DATA_CATALOG)
  return res.data
}

// 管理商品-置顶商品
/* export const upProductInfo = async (productSpecId:Key)=>{
  try{
    const {data} = await Axios.get<Result<null>>(URL_TOP_PRODUCTINFO,{
      params:{
        productSpecId,
      }
    });
    if(data.code !== CODE_REQ_SUCC) throw new Error(data.msg as string);
    message.success(data.msg);
    return data.code;
  }catch(e){
    message.error(e as string);
  }
}

//管理商品-下架商品
export const downProductInfo = async(productSpecId:Key)=>{
  try{
    const {data} = await Axios.get<Result<null>>(URL_DOWN_PRODUCTINFO,{
      params:{
        productSpecId,
      }
    });
    if(data.code !== CODE_REQ_SUCC) throw new Error(data.msg as string);
    message.success(data.msg);
    return data.code;
  }catch(e){
    message.error(e as string);
  }
} */

//周卡查看
export const getWelfareCardList = async (params: WelfareParams) => {
  const { data: res } = await Axios.get<Result<Pagination<CardData>>>(
    URL_QUERY_WELFARE_CARD_LIST,
    {
      params: params,
    }
  )
  return transfromPagination<CardData, tranformCardListItem>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformWelfareCardList(gameDefinePo)
      })
    }
  )
}
//增加周卡
export const addWelfareCard = async (addData: tranformCardListItem) => {
  const data = tranformUpdateCardData(addData)
  const res = await Axios.post<tranformCardListItem, Result<CardData>>(
    URL_ADD_WELFARE_CARD,
    {
      ...data,
    }
  )
  return res?.data
}
//更新周卡
export const updateWelfareCard = async (updateData: tranformCardListItem) => {
  const data = tranformUpdateCardData(updateData)
  const res = await Axios.post<tranformCardListItem, Result<CardData>>(
    URL_UPDATE_WELFARE_CARD,
    {
      ...data,
    }
  )
  return res?.data
}

interface ActionParams {
  id: string
  cardType: string
}
//上架周卡
export const upLoad = async (params: ActionParams) => {
  const { data: res } = await Axios.get<ActionParams, Result<any>>(
    URL_UPLOAD_CARD,
    {
      params,
    }
  )
  message.success(res.msg)
}
//下架周卡
export const downCard = async (params: ActionParams) => {
  const { data: res } = await Axios.get<ActionParams, Result<any>>(
    URL_DOWN_CARD,
    {
      params,
    }
  )
  message.success(res.msg)
}
//删除周卡
export const deleteCard = async (idList: string[]) => {
  const { data: res } = await Axios.post<string[], Result<any>>(
    URL_DELETE_CARD,
    {
      idList,
    }
  )
  message.success(res.msg)
}
//恢复周卡
export const recoverCard = async (id: string) => {
  const { data: res } = await Axios.get<string[], Result<any>>(
    URL_RECOVER_CARD,
    {
      params: { id },
    }
  )
  message.success(res.msg)
}

// 获取优惠卡记录
export const getWelfareCardRecordList = async (
  params: WelfareCardRecordParams
) => {
  const { data: res } = await Axios.get<WelfareCardRecordParams, Result<any>>(
    URL_GET_CARD_RECORD_LIST,
    {
      params: { ...params },
    }
  )
  return transfromPagination<
    WelfareCardRecordListItem,
    TransformWelfareCardRecordListItem
  >(res.data, (list) => {
    return (list || []).map((item) => {
      return transformWelfareCardReacordListItem(item)
    })
  })
}

// 获取优惠卡名称列表
export const getWelfareCardNameList = async () => {
  const { data: res } = await Axios.get<any, Result<any>>(
    URL_GET_CARD_NAME_LIST
  )
  return (res.data || []).map((item: WelfareCardNameListItem) => {
    return transformCardName(item)
  })
}

//已发货订单修改商品价格
export const updateShippedAmount = async (
  updateData: updateShippedAmountData
) => {
  const { data: res } = await Axios.post<updateShippedAmountData, Result<any>>(
    URL_UPDATA_SHIPPED_AMOUNT,
    {
      ...updateData,
    }
  )
  message.success(res.msg)
}

// 代买周卡
export const purchaseOnBehalfOf = async (params: PurchaseOnBehalfOfParams) => {
  const { data: res } = await Axios.post<PurchaseOnBehalfOfParams, Result<any>>(
    URL_PURCHASE_ON_BEHALF_OF,
    {
      ...params,
    }
  )
  if (res.code === RESPONSE_CODE_SUCCEED) message.success(res.msg)
  return res
}

//备注用户
export const updataCommentName = async (params: UpdataCommentNameDTO) => {
  const { data: res } = await Axios.post<UpdataCommentNameDTO, Result<any>>(
    URL_UPDATA_COMMENTNAME,
    {
      ...params,
    }
  )
  if (res.code === RESPONSE_CODE_SUCCEED) message.success(res.msg)
  return res
}

//查询用户品级
export const queryVipGradeList = async (params: ParamsVipGradeList) => {
  const { data: res } = await Axios.get<Result<Pagination<VipGradeListDTO>>>(
    URL_QUERY_VIPGRADE_LIST,
    {
      params,
    }
  )
  return transfromPagination<VipGradeListDTO, VipGrade>(res.data, (list) => {
    return (list || []).map((po) => {
      return transformVipGradeListDTO(po)
    })
  })
}
//新增用户评级
export const insertVipGrade = async (updateData: insertVipGradeDTO) => {
  const { data: res } = await Axios.post<insertVipGradeDTO, Result<any>>(
    URL_INSERT_GVIPGRADE,
    {
      ...updateData,
    }
  )
  message.success(res.msg)
}

//修改用户评级
export const updateVipGrade = async (updateData: insertVipGradeDTO) => {
  const { data: res } = await Axios.post<insertVipGradeDTO, Result<any>>(
    URL_UPDATE_GVIPGRADE,
    {
      ...updateData,
    }
  )
  message.success(res.msg)
}

// 周期活动相关接口
export const queryActivityList = async (params: TransformQueryActivityParams) => {
  const paramsData = transformQueryActivityListParams(params);
  const { data: res } = await Axios.get<Result<Pagination<QueryActivityListItem>>>(URL_QUERY_ACTIVITY_LIST, {
    params: { ...paramsData },
  });
  return transfromPagination<QueryActivityListItem, TransformQueryActivityListItem>(
    res.data, (list) => {
      return (list || []).map((item) => {
        return transformQueryActivityListItem(item);
      })
    }
  )
}

export const queryActivityInfoById = async (id?: Key) => {
  const { data: res } = await Axios.get<Key, Result<Result<QueryActivityByIdDetail>>>(URL_QUERY_ACTIVITY_INFO_BY_ID, {
    params: { id },
  });
  return transformQueryActivityByIdDetail(res?.data);
}

export const updateActivity = async (dataParams: TransformQueryActivityByIdDetail) => {
  const updateData = transformUpdateActivityData(dataParams);
  const { data: res } = await Axios.post<any, Result<any>>(URL_UPDATE_ACTIVITY_INFO, {
    ...updateData,
  });
  message.success(res.msg);
  return res;
}

export const onShelfActivity = async (id?: Key) => {
  const { data: res } = await Axios.post<Key, Result<any>>(URL_ON_SHELF_ACTIVITY, {
    id,
  });
  message.success(res.msg);
  return res;
}

export const offShelfActivity = async (id?: Key) => {
  const { data: res } = await Axios.post<Key, Result<any>>(URL_OFF_ACTIVITY, {
    id,
  });
  message.success(res.msg);
  return res;
}

export const removeActivity = async (id?: Key) => {
  const { data: res } = await Axios.post<Key, Result<any>>(URL_REMOVE_ACTIVTIY, {
    id,
  });
  message.success(res.msg);
  return res;
}

export const resetActivity = async (id?: Key) => {
  const { data: res } = await Axios.post<Key, Result<any>>(URL_RESET_ACTIVITY, {
    id,
  });
  message.success(res.msg);
  return res;
}
//用户指引查询
export const queryUserGuideList = async (params: ParamsQueryUserGuideList) => {
  const { data: res } = await Axios.get<Result<Pagination<UserGuideListDTO>>>(URL_QUERY_USERGUIDE_LIST, {
    params,
  });

  return transfromPagination<UserGuideListDTO, UserGuideListDTO>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformUserGuideListDTO(gameDefinePo);
      });
    },
  );
};
//新增用户指引 
export const insertUserGuideDefine = async (UserGuide: UserGuideListDTO) => {
  const UserGuidePo = transform2InsertUserGuideDefineDTO(UserGuide)
  const { data: res } = await Axios.post<UserGuideListDTO, Result<any>>(URL_INSERT_USERGUIDE, UserGuidePo)
  message.success(res.msg);
}
//修改用户指引
export const updateUserGuideDefine = async (UserGuide: UserGuideListDTO) => {
  const UserGuidePo = transform2InsertUserGuideDefineDTO(UserGuide)
  const { data: res } = await Axios.post<UserGuideListDTO, Result<any>>(URL_UPDATE_USERGUIDE, UserGuidePo)
  message.success(res.msg);
}
//删除用户指引
export const deleteUserGuideDefine = async (id: Key) => {
  const { data: res } = await Axios.post<UserGuideListDTO, Result<any>>(URL_DELETE_USERGUIDE, { id })
  message.success(res.msg);
}
//查询已有的用户指引
export const queryAllMenuInfo = async () => {
  const { data: res } = await Axios.get<any, Result<any>>(URL_QUERY_ALL_MENU_INFO);
  return res.data
}

// 查询酷圈审核列表
export const queryArticleList = async (params: searchArticleParams) => {
  const { data: res } = await Axios.get<Result<Pagination<ArticleData>>>(
    URL_GET_ARTICLE_LIST,
    {
      params,
    }
  )
  return transfromPagination<ArticleData, ArticleData>(res.data, (list) => {
    return (list || []).map((po) => {
      return transformArticleList(po)
    })
  })
}

// 酷圈审核
export const queryAuditArticle = async (params: auditArticleParams[]) => {
  try {
    await Axios.post<Result<string>>(
      URL_AUDIT_ARTICLE,
      params
    )
    message.success('审核成功');
  } catch (e) {
    throw new Error(e as string)
  }
}

//合成池列表查询
export const queryCubePoolList = async (params: ParamsQueryByCubePool) => {
  const { data: res } = await Axios.get<Result<Pagination<SaveItemPoolDTO>>>(
    URL_QUERY_CUBEPOOL_LIST,
    {
      params,
    }
  )
  return transfromPagination<SaveItemPoolDTO, MainItemPool>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transformSaveSaveItemPoolDTO(gameDefinePo)
      })
    }
  )
}

// 编辑合成池 
export const editCubePool = async (gameDefine: SaveCubePoolDTO): Promise<Key> => {
  const gameDefinePo = transform2SaveCubePoolDTO(gameDefine)
  const { data: res } = await Axios.post<Result<Key>>(
    URL_EDIT_CUBEPOOL_LIST,
    gameDefinePo
  )
  message.success(res.msg)
  return res.data
}
//合成池上下架
export const CubeUpOrDown = async (id: Key) => {
  const { data: res } = await Axios.post<ActionParams, Result<any>>(
    URL_CUBE_UP_DOWN,
    {
      id
    }
  )
  message.success(res.msg)
}

// ID查询合成池
export const queryCubePoolDetails = async (
  gameDefineId: Key
): Promise<ItemPoolsSepc> => {
  const { data: res } = await Axios.get<Result<SaveCubePoolDTO>>(
    URL_QUERY_CUBEPOOL_DETAILS,
    {
      params: {
        configId: gameDefineId,
      },
    }
  )
  return transformSaveCubePoolDTO(res.data)
}


//删除合成池
export const deleteCubePool = async (id: Key) => {
  const { data: res } = await Axios.post<Result<string>>(
    URL_DEL_CUBEPOOL_LIST,
    {
      id,
    }
  )
  message.success(res.msg)
  return res.data
}

// 合成表 
export const queryCobeCompoundList = async (params: ParamsPrizeGameRecords) => {
  const { data: res } = await Axios.get<Result<Pagination<CobeCompoundListPo>>>(
    URL_QUERY_CUBE_COMPOUND_LIST,
    {
      params,
    }
  )
  return transfromPagination<CobeCompoundListPo, CobeCompoundListPo>(res.data, (list) =>
    (list || []).map((po) => transfromCobeCompoundListPo(po))
  )
}

//合成 
export const queryCompoundCube = async (params: CompoundCube) => {
  const { data: res } = await Axios.post<Result<string>>(
    URL_COMPOUND_CUBE,
    {
      ...params,
    }
  )
  message.success(res.msg)
  return res.data
}

//合成记录查询 
export const queryCubeRecordList = async (params: ParamsQueryByCubeRecord) => {
  const { data: res } = await Axios.get<Result<Pagination<CubeRecordDTO>>>(
    URL_QUERY_CUBE_RECORDS,
    {
      params,
    }
  )
  return transfromPagination<CubeRecordDTO, CubeRecordDTO>(
    res.data,
    (list) => {
      return (list || []).map((gameDefinePo) => {
        return transfromCobeRecordListPo(gameDefinePo)
      })
    }
  )
}

//查询合成记录详情 relDTOList
export const queryCubeRecordDetails = async (
  gameDefineId: Key
): Promise<relDTO[]> => {
  const { data: res } = await Axios.get<Result<relDTO[]>>(
    URL_QUERY_CUBE_RECORDS_DETAIL,
    {
      params: {
        recordsId: gameDefineId,
      },
    }
  )
  return res.data.map(item => transfromCobeRecordDetailsPo(item))
}
export const queryOrderDetail = async (params: QueryApplyForDeliveryPoListParams) => {
  const res = await Axios.get<QueryApplyForDeliveryPoListParams, Result<Pagination<ApplyForDeliveryPo>>>(URL_QUERY_ORDER_DETAIL, {
    params: {
      ...params,
    }
  });
  return transfromPagination<ApplyForDeliveryPo, OrderDetail>(
    res.data,
    (list) => {
      return (list || []).map((po) => transformOrderDetailList(po))
    }
  )
}
export const updateUserInfo = async (infoData: InfoData) => {
  const { data: res } = await Axios.post<InfoData, Result<any>>(URL_UPDATE_USERINFO, infoData);
  if (res.code === '00000') {
    console.log(res.data);
    const infoData = {
      avatar: res?.data?.uri,
      nickName: res?.data?.weChatName,
      phoneNum: res?.data?.phoneNum,
      id: res?.data?.accountNo
    }
    sessionStorage.setItem('userInfo', JSON.stringify(infoData));
    message.success('修改成功！');
  }
  return res;
}
export const topupByTokens = async (params: TopupByMealticketParams) => {

  const { data: res } = await Axios.post<Result<Key>>(
    URL_TOPUP_BY_TOKENS,
    {
      ...params
    }
  )
  notification.success({
    message: res.msg as string,
  })
  return res.data
}

// 大奖奖项查询
export const queryBigPrize = async () => {
  const { data: res } = await Axios.get<Result<QueryBigPrizeListItem[]>>(URL_QUERY_BIG_PRIZE);
  return (res.data || []).map(item => transformBigPrizeListItem(item))
}

//登入 
export const queryLogin = async () => {
  const url = (window.location.origin === 'https://test.cookactoys.cn' || window.location.origin === 'http://localhost:3000') ?
    "/goapi/v1/qr/returnWxConfigInfo" : URL_QUERY_LOGIN
  const { data: res } = await Axios.get<any, Result<{ appid: string, redirect_uri: string }>>(url);
  return res
}
// 获取怒气数量
export const queryRageInfoList = async (params: RageInfoParams) => {
  const { data: res } = await Axios.get<RageInfoParams, Result<any>>(URL_QUERY_RAGE_NUM, {
    params,
  })
  return res.data;
}
// 最高塔层记录
export const queryTowerFloorRecord = async (queryParams: QueryParams) => {
  const { data: res } = await Axios.get<QueryParams, Result<any>>(URL_TOWER_FLOOR_RECORD, {
    params: { ...queryParams }
  })
  return transfromPagination<TowerFloorRecordList, TowerFloorRecordList>(
    res.data,
    (list) => {
      return (list || []).map((po) => TranformTowerRecordListItem(po))
    }
  )
}


//上架商品 
export const offProductSpec = async (productSpec: ProductSpec) => {
  try {
    const data = transfrom2ProductSpecDTO(productSpec);
    let res;
    if (data) {
      res = await Axios.post(URL_ON_PRODUCT_SPEC, data);
    }
    message.success('上架成功！')
    return res?.data;

  } catch (msg) {
    // notification.error({
    //   message: msg as string,
    // });
    throw new Error(msg as string);
  }
}
//下架商品 
export const onProductSpec = async (id: Key) => {
  try {
    await Axios.post<{ id: Key }, AxiosResponse<Result<null>>>(URL_OFF_PRODUCT_SPEC, { id });
    message.success('下架成功！')
  } catch (e) {
    // notification.error({
    //   message: e as string
    // });
    throw new Error(e as string);
  }
};

//商品补充库存
export const onProductReplenishStock = async (params: ProductReplenishStockParams) => {
  await Axios.post<ProductReplenishStockParams, AxiosResponse<Result<null>>>(URL_PRODUCT_REPLENISH_STOCK, { ...params });
  message.success('添加库存成功！')
}

export const joinBourseBlacklist = async (walletId: Key) => {
  return axios.post<{ id: Key }>(JOIN_BOURSE_BLACKLIST, {
    id: walletId,
  })
}

export const removeBourseBlacklist = async (walletId: Key) => {
  return axios.post<{ id: Key }>(REMOVE_BOURSE_BLACKLIST, {
    id: walletId,
  })
}

export const joinActionBlacklist = async (walletId: Key) => {
  return axios.post<{ id: Key }>(JOIN_ACTION_BLACKLIST, {
    id: walletId,
  })
}

export const removeActionBlacklist = async (walletId: Key) => {
  return axios.post<{ id: Key }>(REMOVE_ACTION_BLACKLIST, {
    id: walletId,
  })
}

// 配置管理上传封面图片
export const configUploadImg = async (params: configUploadImgParams) => {
  const res = await Axios.get<configUploadImgParams, AxiosResponse<Result<null>>>(URL_UPLOAD_COVER_IMAGE, {
    params: { ...params }
  });
  if (res.data.code === '00000') {
    message.success('上传成功')
  };
}

//获取商品封面和详情封面
export const queryProductImgDetails = async (params: productImgDetailsParams) => {
  const { data: res } = await Axios.get<productImgDetailsParams, AxiosResponse<Result<productImgDetailsPo>>>(URL_GET_PRODUCT_IMG_DETAILS, {
    params: { ...params }
  });

  return transformProductImgDetails(res.data);
}

//修改商品封面和详情封面
export const updateProductImgDetails = async (data: updateProductImgDetailsParams) => {
  try {
    await Axios.post<Result<Key>>(URL_UPDATE_PRODUCT_IMG_DETAILS, data);
    // return res.code === RESPONSE_CODE_SUCCEED;
    message.success('修改成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 获取隐藏商品概率配置列表
export const queryHiddenPostConfigList = async (
  params: hiddenPostConfigParams
) => {
  const { data: res } = await Axios.get<Result<Pagination<hiddenPostConfigPo>>>(
    URL_HIDDEN_POST_CONFIG_LIST,
    {
      params,
    }
  )

  return transfromPagination<hiddenPostConfigPo, hiddenPostConfigPo>(res.data, (list) => {
    return (list || []).map((po) => {
      return transformHiddenPostConfig(po)
    })
  })
}

// 获取隐藏商品概率配置详情
export const getHiddenPostConfigById = async (id: Key) => {
  try {
    const { data: res } = await Axios.get<Result<hiddenPostConfigPo>>(URL_GET_HIDDEN_POST_CONFIG_BY_ID, {
      params: { id },
    });
    return transformHiddenPostConfig(res.data)
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 删除隐藏商品概率配置
export const delHiddenPostConfigById = async (id: Key) => {
  try {
    await axios.post<{ id: Key }>(URL_DEL_HIDDEN_POST_CONFIG_BY_ID, {
      id: id,
    })
    message.success('操作成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 上下架隐藏商品概率配置
export const rackUpAndDownHiddenPostConfig = async (id: Key) => {
  try {
    await axios.post<{ id: Key }>(URL_UP_AND_DOWN_HIDDEN_POST_CONFIG, {
      id: id,
    })
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 新增/修改隐藏商品概率配置
export const saveHiddenPostConfig = async (data: saveHiddenPostConfigParams) => {
  try {
    await Axios.post<Result<Key>>(URL_SAVE_HIDDEN_POST_CONFIG, data);
    message.success('操作成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 获取封面与协议相关配置
export const findSystemConfigGivenById = async (id: Key) => {
  try {
    const { data: res } = await Axios.get<Result<ConfigGivenPo>>(URL_FIND_SYSTEM_CONFIG_GIVEN_BY_ID, {
      params: { id },
    });
    return transformConfigGiven(res.data)
  } catch (msg) {
    throw new Error(msg as string);
  }
}

//导出txt文档
export const downloadTxt = async (
  params: { id: Key }
) => {
  // await Axios.get(URL_EXPORT_WITHDRAW_RECORDS, { params })
  const res = await Axios.get(URL_DOWNLOAD_TXT, {
    responseType: 'blob',
    params,
    timeout: 0,
  })
  return res
}

// 获取小程序列表
export const queryOuterAdvertisingConfigList = async (
  params: OuterAdvertisingConfigParams
) => {
  const { data: res } = await Axios.get<Result<Pagination<OuterAdvertisingConfigPo>>>(
    URL_QUERY_OUTER_ADVERTISING_CONFIG_LIST, { params })

  return transfromPagination<OuterAdvertisingConfigPo, OuterAdvertisingConfigPo>(res.data, (list) => {
    return (list || []).map((po) => {
      return {
        ...po,
        uri: transformUri(po.uri),
      }
    })
  })
}

// 增加/修改小程序
export const AddOuterAdvertisingConfig = async (data: AddAppConfigParams) => {
  try {
    await Axios.post<Result<Key>>(URL_ADD_OUTER_ADVERTISING_CONFIG, data);
    message.success('操作成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

export const UpdateOuterAdvertisingConfig = async (data: AddAppConfigParams) => {
  try {
    await Axios.post<Result<Key>>(URL_UPDATE_OUTER_ADVERTISING_CONFIG, data);
    message.success('操作成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

//删除小程序
export const DelAppConfigConfig = async (id: string) => {
  try {
    await Axios.get<{ id: string }, Result<Key>>(URL_DEL_OUTER_ADVERTISING_CONFIG, {
      params: {
        id,
      }
    });
    message.success('删除成功！')
  } catch (msg) {
    throw new Error(msg as string);
  }
}

// 获取小程序评论列表
export const queryAppConfigAppraiseList = async (
  params: AppConfigAppraise
) => {
  const { data: res } = await Axios.get<Result<Pagination<AppConfigAppraisePo>>>(
    URL_QUERY_ADVERTISING_COMMENT_LIST, { params })

  return transfromPagination<AppConfigAppraisePo, AppConfigAppraisePo>(res.data, (list) => {
    return (list || [])
  })
}

// 待发货订单数据导出
export const exportNotShippedOrder = async (
  params: QueryApplyForDeliveryPoListParams
) => {
  const {
    accountNo,
    shipperName,
    productName,
    mobileNo,
    pageNo,
    pageSize,
    state,
    pendingState,
    flagColor,
    flagState,
    duration,
    categoryId,
    ipId,
    priceSort
  } = params
  const [start, end] = duration || [];
  const res = await Axios({
    url: URL_DOWN_WAIT_EXCEL_LIST,
    responseType: 'blob',
    params: {
      ...params,
      accountNo: accountNo || undefined,
      name: shipperName || undefined,
      mobile: mobileNo || undefined,
      productName: productName || undefined,
      pageNo,
      pageSize,
      includeLuckyBag: state,
      pendingState,
      flagColor,
      flagState,
      categoryId,
      ipId,
      priceSort: priceSort || undefined,
      duration: undefined,
      startTime: formatTimestamp(start),
      endTime: formatTimestamp(end),
    },
    method: 'GET',
    timeout: 0,
  })
  return res
}