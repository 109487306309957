import { Redirect, Route, Switch } from 'react-router-dom';
import { menuRoutes, exactMenuRoutes } from './index'
import { LOGIN_USER_ROLE } from '../consts';
import { useEffect, useState } from 'react';

export default function MyRoute () {
  const [role, setRole] = useState<string>();

  useEffect(()=>{
    const emp = sessionStorage.getItem(LOGIN_USER_ROLE) as string;
    emp && setRole(emp);
  },[role]);
  
  return (
    <Switch>
      {
        role && (<Route exact path='/' component={exactMenuRoutes[role].component} />)
      }
      {
        (menuRoutes.filter(i => i.role.some(r => r === role))).map(item => {
          const { key, childRoutes } = item;

          return (
            (childRoutes.filter(i => i.role.some(r => r === role))).map((term, index)=> {
              const { path, component} = term;

              return (<Route key={item.key+index} path={`/${key}/${path}`} component={component} />)
            })
          )        
        })
      }
      {
        role && (
        <>
          <Route path='/' component={exactMenuRoutes[role].component} />
          <Redirect from='*' to='/' />
        </>
        )
      }
    </Switch>
  )
}