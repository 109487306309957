import moment, { Moment } from "moment";

export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const mon = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${mon < 10 ? '0' : ''}${ mon }-${day < 10 ? '0' : ''}${day}`;
};


export const formatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();
  return `${hour < 10 ? '0' : ''}${hour}:${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const formatDatetime = (timestamp: number) => {
  return `${formatDate(timestamp)} ${formatTime(timestamp)}`;
};

export const formatTimestamp = (timestamp: Moment | undefined | number)=>{
  if(timestamp) {
    let time = new Date(moment(timestamp.valueOf()).format("YYYY-MM-DD")).setHours(0)
    return Date.parse(new Date(time).toString())
  }else{
    return timestamp
  }
}

export const formatTimestampEnd = (timestamp: Moment | undefined | number)=>{
  if(timestamp) {
    let time = new Date(moment(timestamp.valueOf()).format("YYYY-MM-DD")).setHours(23,59,59)
    
    return Date.parse(new Date(time).toString())
  }else{
    return timestamp
  }
}