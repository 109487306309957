import { Key } from "react";

interface OrderType{
  id:Key,
  name:string,
}
export const sortFieldsOptions : OrderType[] =[
  {
    id: 'ticketPrice',
    name: '票价'
  },
  {
    id: 'integral',
    name: '积分'
  },
  {
    id: 'mealTicket',
    name: '饭票'
  },
]
export const IntegralTypeOption :OrderType [] =[
  {
    id: '07',
    name: '积分普通抽'
  },
  {
    id: '08',
    name: '积分限抽'
  },
  {
    id: '09',
    name: '积分无限抽'
  },
]
export const MealticketTypeOptions :OrderType [] =[
  {
    id: '15',
    name: '饭票普通抽'
  },
  {
    id: '16',
    name: '饭票限抽'
  },
  {
    id: '14',
    name: '饭票无限'
  },
]
export const allGameTypeOptions :OrderType [] =[
  {
    id: '01',
    name: '竞技套'
  },
  {
    id: '02',
    name: '单抽套'
  },
  {
    id: '03',
    name: '限抽套',
  },
  {
    id: '04',
    name: '循环套',
  },
  // {
  //   id: '05',
  //   name: '套盒',
  // },
  {
    id: '06',
    name: '无限抽（普通）'
  },
  {
    id: '07',
    name: '积分普通抽'
  },
  {
    id: '08',
    name: '积分限抽'
  },
  {
    id: '09',
    name: '积分无限抽'
  },
  {
    id: '10',
    name: '无限抽（限制数量）'
  },
  {
    id: '11',
    name: '大富翁',
  },
  // {
  //   id: '13',
  //   name: '限抽套盒',
  // },
  {
    id: '14',
    name: '饭票无限'
  },
  {
    id: '15',
    name: '饭票普通抽'
  },
  {
    id: '16',
    name: '饭票限抽'
  },
  {
    id: '997',
    name: '合成池'
  },
  {
    id: '998',
    name: '物品池'
  },
  {
    id: '999',
    name: '隐藏池'
  },
  {
    id: '17',
    name: '塔层无限套(常规模式)'
  },
  {
    id: '19',
    name: '塔层无限套(非常规模式)'
  },
]

export const abandonStateOptions: OrderType[] = [
  {
    id: '1',
    name: '未废弃'
  },
  {
    id: '2',
    name: '废弃'
  },
];
