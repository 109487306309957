/* 404 NotFound */
import React from "react";
import "./index.less";

const Img = 'https://cdn.cookactoys.cn/static/404/error.gif';

export default function NotFoundContainer(): JSX.Element {

  return (
    <div className="page-error">
      <div>
        <div className="title">404</div>
        <div className="info">Oh dear</div>
        <div className="info">这里什么也没有</div>
      </div>
      <img src={Img + `?${Date.now()}`} />
    </div>
  );
}
