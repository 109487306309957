import React, { useState } from 'react';
import { LOGIN_BROWSER_ID, LOGIN_USER_ID } from '../../consts';
import {Button,message} from 'antd';
import './index.less';
import { copyTextToClipboard } from '../../utils/copy';
import { nextTick } from 'process';

export default function UnknownComputer () {
  const [browser,setBrowser] = useState(localStorage.getItem(LOGIN_BROWSER_ID))
  const [user,setUser] = useState(localStorage.getItem(LOGIN_USER_ID) && JSON.parse(localStorage.getItem(LOGIN_USER_ID) || '') )
  if(!localStorage.getItem(LOGIN_BROWSER_ID)) {
    setTimeout(()=>{
      setBrowser('')
      setUser('')
      nextTick(()=>{
        setBrowser(localStorage.getItem(LOGIN_BROWSER_ID))
        setUser(localStorage.getItem(LOGIN_USER_ID) && JSON.parse(localStorage.getItem(LOGIN_USER_ID)||'') )
      })
    },1000)
  }
  return (
    <div className='pg-unknownComputer'>
      {/* <div className='pg-unknownComputer-title'>

      </div> */}
      {
        user && (
          <>
            <h1>用户：{user.nickName}</h1>
            <h1>ID：{user.accountNo}</h1>
          </>
        )
      }
      <h1>当前机器码无法访问系统！！</h1>
      <h1>{browser||'正在获取中！请稍后...'}
      {
        browser && (
          <Button type="primary" onClick={()=>{
            message.success('复制成功!!')
            copyTextToClipboard(browser)
             }}>
            复制
          </Button>
        )
      }

      </h1>
    </div>
  );
}
