import { GameType } from "../../../../types/game";
interface Type {
  option: string,
  value: string,
}

export const TYPE_INFINITY_LIMIT = '10';
export const gameTypesOptions: GameType[] = [
  {
    id: '06',
    name: '无限抽（普通）'
  },
  {
    id: TYPE_INFINITY_LIMIT,
    name: '无限抽（限制数量）'
  },
];

export const sortsOptions: Type[] = [
  {
    option: '最小票价在前面',
    value: 'asc'
  },
  {
    option: '最大票价在前面',
    value: 'desc'
  },
];

export const MINE_DISATER_TIME = '1';
export const MINE_DISATER_FREQUENCY = '2';

export const mineDisasterOptions: GameType[] = [
  {
    name: '矿难持续时间',
    id: MINE_DISATER_TIME
  },
  {
    name: 'SSSR中奖次数',
    id: MINE_DISATER_FREQUENCY
  },
];

export const awardIdListOptions: GameType[] = [
  {
    id: '123',
    name: 'SSSR'
  },
  {
    id: '124',
    name: 'SSR'
  },
  {
    id: '125',
    name: 'SR'
  },
  {
    id: '126',
    name: 'R'
  },
];

export const EACH_DRAW = 1;
export const NO_AWARDS = 2;

export const hiddenPoolTypesOptions: GameType[] = [
  {
    id: EACH_DRAW,
    name: '每一抽'
  },
  {
    id: NO_AWARDS,
    name: '个人未出奖项'
  },
];

export const FINAL_REWARD = ["138","137","136","135", "133"];
export const UNCXT_REWARD = ["137","136","135", "133"];



export const carnivalModeTypesOptions: GameType[] = [
  {
    id: 1,
    name: '全局'
  },
  {
    id: 2,
    name: '个人'
  },
];

export const abandonStateOptions: GameType[] = [
  {
    id: '1',
    name: '未废弃'
  },
  {
    id: '2',
    name: '废弃'
  },
];
