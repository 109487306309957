import {
  AuditShipConclusion,
  BusinessType,
  ConsoleDataDurationType,
  GarageKitLock,
  GarageKitState,
  KnapsackStatisticsState,
  RecordType,
  TopupSource,
  WidthdrawAuditState,
  ShippingItemStatus,
} from '.';
import { ProductBaseCategoryId } from './index.d';

export const PREFIX = process.env.NODE_ENV === 'production' ? '/admin/api' : '/api';
export const GO_PREFIX = process.env.NODE_ENV === 'production' ? '/admin/goapi/v1' : '/goapi/v1';
export const QUERY_TEST = `${PREFIX}/test`;

export const CDN_DOMAIN = window.location.host === 'test.cookactoys.cn' ? 'https://cdn.cookactoys.cn' : 'https://cdn.szconst.com';

export const RESPONSE_CODE_SUCCEED = '00000';
export const MAX_SIZE = 3 * 1024 * 1024;

export const PRODUCT_BASE_CATEGORY_ID_IP: ProductBaseCategoryId = '4'; // IP
export const PRODUCT_BASE_CATEGORY_ID_TYPE: ProductBaseCategoryId = '1'; // 现货和预售
export const PRODUCT_BASE_CATEGORY_ID_CATEGORY: ProductBaseCategoryId = '41'; // 分类

export const GAME_BASE_CATEGORY_ID_GRADE = '54'; // 游戏奖品等级

export const INFINITY_GAME_BASE_CATEGORY_ID_GRADE = '122'; // 无限抽游戏奖品登记

export const TOPUP_SOURCE_ADMIN: TopupSource = '1'; // 管理员充值
export const TOPUP_SOURCE_USER: TopupSource = '2'; // 用户充值

export const TOPUP_SOURCE_DISPLAY_MAPPING = {
  [TOPUP_SOURCE_ADMIN]: '管理员',
  [TOPUP_SOURCE_USER]: '用户',
  '3': '赠予',
  '4': '活动赠予',
  '5': '赠予饭票',
  '6': '优惠卡奖励',
  '7': '赠予厨神币',
};

export const TOPUP_PAYMENT_STATE_SUCCESS = 'SUCCESS'; // 支付成功
export const TOPUP_PAYMENT_STATE_REFUND = 'REFUND'; // 转入退款
export const TOPUP_PAYMENT_STATE_NOTPAY = 'NOTPAY'; // 未支付
export const TOPUP_PAYMENT_STATE_CLOSED = 'CLOSED'; // 已关闭
export const TOPUP_PAYMENT_STATE_REVOKED = 'REVOKED'; // 已撤销
export const TOPUP_PAYMENT_STATE_PAYING = 'USERPAYING'; // 用户支付中
export const TOPUP_PAYMENT_STATE_ERROR = 'PAYERROR'; // 支付失败(其他原因，如银行返回失败)
export const TOPUP_PAYMENT_STATE_ACCEPT = 'ACCEPT'; // 已接收，等待扣款

export const TOPUP_PAYMENT_STATE_DISPLAY_MAPPING = {
  [TOPUP_PAYMENT_STATE_SUCCESS]: '支付成功',
  [TOPUP_PAYMENT_STATE_REFUND]: '转入退款',
  [TOPUP_PAYMENT_STATE_NOTPAY]: '未支付',
  [TOPUP_PAYMENT_STATE_CLOSED]: '已关闭',
  [TOPUP_PAYMENT_STATE_REVOKED]: '已撤销',
  [TOPUP_PAYMENT_STATE_PAYING]: '用户支付中',
  [TOPUP_PAYMENT_STATE_ERROR]: '支付失败(其他原因，如银行返回失败)',
  [TOPUP_PAYMENT_STATE_ACCEPT]: '已接收，等待扣款',
};

export const WITHDRAW_STATE_WAITING: WidthdrawAuditState = '1';

export const WITHDRAW_STATE_PASS: WidthdrawAuditState = '2';

export const WITHDRAW_STATE_DISPLAY_MAPPING = {
  [WITHDRAW_STATE_WAITING]: '待审核',
  [WITHDRAW_STATE_PASS]: '已审核',
};

export const BUSINESS_TYPE_MONEY: BusinessType = '1';

export const BUSINESS_TYPE_POINTS: BusinessType = '2';

export const BUSINESS_TYPE_TOKENS: BusinessType = '3';

export const BUSINESS_TYPE_DISPLAY_MAPPING = {
  [BUSINESS_TYPE_MONEY]: '金额',
  [BUSINESS_TYPE_POINTS]: '积分',
  [BUSINESS_TYPE_TOKENS]: '厨神币',
};

export const RECORD_TYPE_GAME: RecordType = '1';
export const RECORD_TYPE_POINTS: RecordType = '2';
export const RECORD_TYPE_RECOVER: RecordType = '3';
export const RECORD_TYPE_DISPLAY_MAPPING = {
  [RECORD_TYPE_GAME]: '抽赏',
  [RECORD_TYPE_POINTS]: '积分兑换',
  [RECORD_TYPE_RECOVER]: '回收',
};

export const GARAGE_KIT_STATE_NORMAL: GarageKitState = '1';
export const GARAGE_KIT_STATE_RECOVERED: GarageKitState = '2';
export const GARAGE_KIT_STATE_APPLY_DELIVER: GarageKitState = '3';
export const GARAGE_KIT_STATE_DELIVERED: GarageKitState = '4';

export const GARAGE_KIT_STATE_DISPLAY_MAPPING = {
  [GARAGE_KIT_STATE_NORMAL]: '正常状态',
  [GARAGE_KIT_STATE_RECOVERED]: '已回收',
  [GARAGE_KIT_STATE_APPLY_DELIVER]: '申请发货',
  [GARAGE_KIT_STATE_DELIVERED]: '已发货',
};

export const GARAGE_KIT_LOCK: GarageKitLock = '1';

export const GARAGE_KIT_UNLOCK: GarageKitLock = '2';

export const CONSOLE_DATE_DURATION_TYPE_CURRENT_DAY: ConsoleDataDurationType =
  '01';
export const CONSOLE_DATE_DURATION_TYPE_CURRENT_MON: ConsoleDataDurationType =
  '02';
export const CONSOLE_DATE_DURATION_TYPE_CURRENT_YEAR: ConsoleDataDurationType =
  '03';

export const KNAPSACK_STATISTICS_STATE_WAITING_STILL_IN_KNAPSACK: KnapsackStatisticsState =
  '1';

export const KNAPSACK_STATISTICS_STATE_WAITING_DELIVERY: KnapsackStatisticsState =
  '2';

export const KNAPSACK_STATISTICS_STATE_MAPPING = {
  [KNAPSACK_STATISTICS_STATE_WAITING_STILL_IN_KNAPSACK]: '背包',
  [KNAPSACK_STATISTICS_STATE_WAITING_DELIVERY]: '待发货',
};

export const SHIP_RES_STATE_OTHER: ShippingItemStatus = '0';
export const SHIP_RES_STATE_ACTIBITY: ShippingItemStatus = '1';
export const SHIP_RES_STATE_MAPPING = {
  [SHIP_RES_STATE_OTHER]: '其他',
  [SHIP_RES_STATE_ACTIBITY]: '活动物品',
};

export const DISCOUNT_TYPE = {
  [SHIP_RES_STATE_OTHER]: '不给余额',
  [SHIP_RES_STATE_ACTIBITY]: '给余额',
};

export const USER_ROLE_ADMIN = '00000'; // 管理员

export const USER_ROLE_GUEST = '99998'; // 游客

export const USER_ROLE_EMPLOYEE = '99997'; // 普通客服

export const USER_ROLE_SENIOR_EMPLOYEE = '99996'; // 高级客服

export const USER_ROLE_SHIPPER = '99995'; // 发货人员

export const USER_ROLE_SENIOR_ADMIN = '99994'; // 高级管理员
export const USER_ROLE_SENIOR_ADMIN_ADD = '99989'; // 高级管理员 有兑换券

export const USER_ROLE_SENIOR_SHIPPER = '99993'; // 高级发货人员

export const USER_ROLE_EMPLOYEE_SENIOR_SHIPPER = '99992'; // 普通客服 + 高级发货人员 + 商品查看

export const USER_ROLE_COMMODITY_SELECT = '99991'; // 商品查看

export const USER_ROLE_OPERATOR = '99990'; // 运维人员

export const USER_ROLE_WITHDRAW = '99988'; //退款人员

export const USER_ROLE_HIGH_GUEST = '99987' //高级游客

export const URL_QUERY_USER_ROLE = `${PREFIX}/checkUser`;

// 控制台页面数据
export const URL_QUERY_CONTROL_DATA = `${PREFIX}/web/getPcHome`;

// 查询分类下拉列表
export const URL_QUERY_PRODUCT_CATEGORY_OPTIONS = `${PREFIX}/checkProductCategoryPoList`;

// 查询商品分类详情
export const URL_QUERY_PRODUCT_CATEGORY_LIST = `${PREFIX}/getProductCategoryPoList`;

// 新增商品分类
export const URL_ADD_PRODUCT_CATEGORY = `${PREFIX}/addProductCategoryPo`;

// 修改商品分类
export const URL_UPDATE_PRODUCT_CATEGORY = `${PREFIX}/updateProductCategoryPo`;

// 删除商品分类
export const URL_DELETE_PRODUCT_CATEGORY_LIST = `${PREFIX}/deleteProductCategoryPoById`;

// 查询商品列表
export const URL_QUERY_PRODUCT_SPEC = `${PREFIX}/product/getProductPoList`;

// 新增商品
export const URL_ADD_PRODUCT_SPEC = `${PREFIX}/product/addProduct`;
// 修改商品
export const URL_UPDATE_PRODUCT_SPEC = `${PREFIX}/product/updateProduct`;

// 修改商品 todo 缺了  updateProduct

// 删除商品列表   deleteProductListByids    删除不做先，或者权限开给我们自己。如果删除和套捆绑了就不给删除。如果游戏套有上架套就不给删除

// 查询积分商品列表
export const URL_QUERY_PRODUCT_FOR_POINTS_LIST = `${PREFIX}/integration/getIntegrationProductPoList`;

// 修改积分商品
export const URL_UPDATE_PRODUCT_FOR_POINTS_LIST = `${PREFIX}/integration/updateIntegrationProductPo`;

// 删除积分商品列表
export const URL_DELETE_PRODUCT_FOR_POINTS_LIST = `${PREFIX}/integration/deleteIntegrationProductPo`;

// 查询游戏模版列表， getGameDefineList

// 新增游戏模版，url修改为addGameDefine会好理解一点  改了

export const URL_ADD_GAME_DEFINE = `${PREFIX}/game/addGameDefine`;

// 更新游戏模版， updateGameDefine

export const URL_UPDATE_GAME_DEFINE = `${PREFIX}/game/updateGameDefine`;

export const URL_UPDATE_INFINITY_GAME_DEFINE = `${PREFIX}/game/addOrUpdateUnlimitedDrawDefine`;

// 删除游戏模版列表，todo 后端缺接口  先不做，删除要加软删除才做

// 游戏上下架状态变更，updateGame

// 游戏上架（游戏模版管理页面），todo url修改为addGame会好理解一点
export const URL_ADD_GAME = `${PREFIX}/game/onTheshelfGame`;

//积分抽赏模版上架
export const URL_ADD_POINTS_GAME = `${PREFIX}/game/onTheShelfPointsDrawDefine`;

// 更新游戏信息
export const URL_UPDATE_GAME = `${PREFIX}/game/updateGame`;

// 查询游戏
export const URL_QUERY_GAME_LIST = `${PREFIX}/game/getGameList`;

// 查询游戏--套盒
export const URL_QUERY_GAME_BOX_LIST = `${PREFIX}/game/getGameModelList`;

//查询游戏--积分
export const URL_QUERY_GAME_POINTS_LIST = `${PREFIX}/game/getPointsDrawGameList`;

//查询游戏--饭票
export const URL_QUERY_GAME_MEALTICKET_LIST = `${PREFIX}/game/getMealTicketDrawGameList`;

export const URL_PULL_OFF_GAME_BOX = `${PREFIX}/game/takeDownGameModel`;

// 查询未生效游戏
export const URL_QUERY_UNENFORCED_GAME_LIST = `${PREFIX}/web/wechatGameList`;

// 查询游戏模板列表
export const URL_QUERY_GAME_DEFINE_LIST = `${PREFIX}/game/getGameDefineList`;

// 查询无限套游戏
export const URL_QUERY_INFINITY_GAME_DEFINE_LIST = `${PREFIX}/game/getUnlimitedDrawDefineList`;

export const URL_QUERY_INFINITY_GAME_DEFINE_DETAILS = `${PREFIX}/game/getUnlimitedDrawDefineByDefineId`;
// 通过ConfigAttributeId查询所有ConfigAttributeValue todo 后端未提供接口   用checkProductCategoryPoList或者getProductCategoryPoList都可以，也可以直接我这边给你把逻辑写完给你单独弄个接口

// 文件上传接口
export const URL_FILE_UPLOAD = `${PREFIX}/fileUpload`;

export const URL_LOGIN = `/core${GO_PREFIX}/qr/login`;

export const URL_QUERY_VIP_MEMBERS = `${PREFIX}/user/getPcUserList`;

export const URL_Change_AVATAR = `${PREFIX}/web/changeAccountAvatar`;

export const URL_TOPUP = `${PREFIX}/user/addBalance`;

export const URL_TOPUP_BY_GRANT = `${PREFIX}/user/giveBalance`;

export const URL_TOPUP_BY_MEALTICKET = `${PREFIX}/user/addMealTicketByAccountId`;

export const URL_QUERY_NOT_YET_SHIPPED_ORDER = `${PREFIX}/web/getApplyForList`;

export const URL_QUERY_RETURN_LIST = `${PREFIX}/web/findReturnInfoList`;

export const URL_QUERY_SHIPPED_ORDER = `${PREFIX}/web/getConsignmentList`;
export const URL_QUERY_DISCOUNT_LIST = `${PREFIX}/web/getDiscountList`;
export const URL_QUERY_EVENT_ITEMS_LIST = `${PREFIX}/web/getEventItemsList`;
export const URL_QUERY_TRANSFER_LIST = `${PREFIX}/web/getTransferList`;

export const URL_QUERY_PRODUCT_TRANSFERED_RECORDS = `${PREFIX}/user/productTransferRecords`;

export const URL_DELETE_PRODUCT_BY_ID = `${PREFIX}/web/deleteProductById;
`;

export const URL_DELETE_GAME_DEFINE_BY_ID = `${PREFIX}/web/deleteGameDefineById`;

export const URL_QUERY_GAME_RECORDS = `${PREFIX}/web/getGameRecordsPOList`;

export const URL_QUERY_TOPUP_RECORDS = `${PREFIX}/web/getOrderPoList`;

export const URL_QUERY_WITHDRAW_RECORDS = `${PREFIX}/web/getWithdrawRecordsPoList`;

export const URL_QUERY_BILLS_RECORDS = `${PREFIX}/web/getAmountRecords`;

export const URL_QUERY_REWARDS_SETTINGS = `${PREFIX}/web/getVipUserRecordPoList`;

export const URL_ADD_REWARD_SETTING = `${PREFIX}/web/insertVipLevelCollocationPo`;

export const URL_DELETE_REWARD_SETTING = `${PREFIX}/web/deleteVipLevelCollocationById`;

export const URL_QUERY_GARAGE_KITS = `${PREFIX}/web/getKnapsackList`;

export const URL_BLOCKED_ACCOUNT = `${PREFIX}/user/suspend`;

export const URL_UNBLOCKED_ACCOUNT = `${PREFIX}/user/unblock`;

export const URL_CONFIRM_WITHDRAW = `${PREFIX}/user/manualWithdrawal`;

export const URL_WITHDRAW_BY_WECHAT = `${PREFIX}/user/manualWithdrawalUser`;

export const URL_WITHDRAW_BY_ALIPAY = `${PREFIX}/user/manualWithdrawalByAlipay`;

export const URL_WITHDRAW_THROUGH_GO = `${GO_PREFIX}/transfer/v3`;

export const URL_WITHDRAW_BY_ALIPAY_THROUGH_GO = `/consts${GO_PREFIX}/ali/transfer`;

export const URL_QUERY_POSTER_LIST = `${PREFIX}/web/getPosterList`;

export const URL_SHIP_AUDIT = `${PREFIX}/web/verifyShipment`;

export const URL_AUDIT_SHIP_AGGREE: AuditShipConclusion = '1';
export const URL_AUDIT_SHIP_DISAGGREE: AuditShipConclusion = '2';
export const URL_AUDIT_SHIP_SALE: AuditShipConclusion = '3';
export const URL_AUDIT_SHIP_TRANSFER: AuditShipConclusion = '4';
export const URL_AUDIT_SHIP_ACTIVE_SHIPMENT: AuditShipConclusion = '7';
export const URL_AUDIT_SHIP_DELIVERY: AuditShipConclusion = '8';

export const URL_ADD_POSTER = `${PREFIX}/web/addPosterList`;

export const URL_QUERY_POSTER = `${PREFIX}/web/getPosterList`;

export const URL_DELETE_POSTER = `${PREFIX}/web/deletePosterList`;

export const URL_SEND_BACK_SHIP = `${PREFIX}/web/returnShipment`;

export const URL_CLONE_GAME_DEFINE = `${PREFIX}/game/copyGameDefine`;

export const URL_QUERY_RECKONING = `${PREFIX}/reportData/getDailyStatisticsPage`;

export const URL_QUERY_KNAPSACK_STATISTIC = `${PREFIX}/reportData/getAllKnapsackStatistics`;

export const URL_QUERY_USER_KNAPSACK_STATISTIC = `${PREFIX}/reportData/getAccountKnapsackDailyStatistics`;

export const URL_QUERY_USER_REAL_TIME_KNAPSACK_STATISTIC = `${PREFIX}/user/getKnapsackByAccountNo`;

export const URL_QUERY_USER_REAL_TIME_DATA = `${PREFIX}/user/getUserData`;
// 实时用户金额统计子表
export const URL_QUERY_USER_GIFT_DATA = `${PREFIX}/web/getUserDataBeforeGiftData`;

export const URL_QUERY_DAILY_KNAPSACK_STATISTIC = `${PREFIX}/reportData/getAllKnapsackDailyStatistics`;

export const URL_GIVE_GIFT = `${PREFIX}/user/giveGiftsForUser`;

export const URL_QUERY_GIVE_GIFT_RECORDS = `${PREFIX}/user/getGiveGiftsRecord`;

export const URL_ON_SALE_INFINITY_GAME_DEFINE = `${PREFIX}/game/onTheShelfUnlimitedDrawDefine`;

export const URL_OFF_SHELVE_INFINITY_GAME_DEFINE = `${PREFIX}/game/takeDownUnlimitedDraw`;

// 厨神榜文件模版下载地址
export const URL_CHEF_LIST_FILE_DEMO =
  'https://cdn.szconst.com/mwUpload/chefListFileDemo.xlsx';
// 厨神榜单文件上传
export const URL_CHEF_LIST_FILE_UPLOAD = `${PREFIX}/chefFileUpload`;
// 厨神榜单文件查询列表
export const URL_CHEF_LIST_FILE_LIST = `${PREFIX}/web/chefListFileList`;
// 厨神榜单文件删除
export const URL_DEL_CHEF_LIST_FILE = `${PREFIX}/web/delChefListFile`;
// 厨神榜单文件内容信息
export const URL_DEL_CHEF_LIST_DARABY_FILEID = `${PREFIX}/web/chefListDataByFileId`;

// 查询排行榜配置相关信息
export const URL_QUERY_RANKING_LIST_MANAGE__LIST = `${PREFIX}/web/leaderboardConfigList`;
// 查询排行榜大奖
export const URL_QUERY_RANKING_BIH_PRIZE = `${PREFIX}/web/getGrandPrizeList`;
// 添加/修改排行榜信息
export const URL_UPDATE_QUERY_RANKING_GAME = `${PREFIX}/web/saveLeaderboardConfig`;
// 排行榜删除
export const URL_DELETE_GAME_RANKING_BY_ID = `${PREFIX}/web/delLeaderboardConfig`;
// 上下架排行榜
export const URL_OFF_ON_RANKING_GAME = `${PREFIX}/web/rackUpAndDownLeaderboardConfig`;
// 通过Id获取排行榜配置
export const URL_QUERY_RANKING_DETAILS = `${PREFIX}/web/getLeaderboardConfigById`;
// 获取厨神榜文件列表
export const URL_GET_CHEF_LIST_FILE_LIST = `${PREFIX}/web/getChefListFileList`;

// 积分抽赏模版查询
export const URL_QUERY_POINTS_DRAW_DEFINE_LIST = `${PREFIX}/game/getPointsDrawDefineList`;
// 通过模版Id获取详情
export const URL_QUERY_POINTS_DRAW_DEFINE_BY = `${PREFIX}/game/getPointsDrawDefineByDefineId`;
// 新增/修改积分抽赏模版
export const URL_UPDATA_POINTS_DRAW_DEFINE = `${PREFIX}/game/addOrUpdatePointsDrawDefine`;
// 积分抽赏模版上架
export const URL_ON_POINTS_DRAW_DEFINE = `${PREFIX}/game/onTheShelfPointsDrawDefine`;
// 积分抽赏模版删除
export const URL_POINTS_DRA_DELETE_GAME_DEFINE_BY_ID = `${PREFIX}/web/deleteGameDefineById`;

// 赠予文件模版下载地址
export const URL_GIFT_USER_DEMO = '/upload/giftUserDemo.xlsx';
// 赠予文件查询列表
export const URL_GIFT_USER_FILE_LIST = `${PREFIX}/web/giftUserFileList`;
// 厨神榜单文件上传
export const URL_GIFT_USER_FILE_UPLOAD = `${PREFIX}/giftUserFileUpload`;
// 厨神榜单文件内容信息
export const URL_GIFT_USER_FILE_LIST_ID = `${PREFIX}/web/giftUserListDataByFileId`;
// 是否赠予文件
export const URL_UPLOAD_GIFT_USER_FILE_CONFIRM = `${PREFIX}/web/uploadGiftUserFileConfirm`;

// 查询签到规则配置
export const URL_QUERY_SIGN_IN_MANAGE = `${PREFIX}/web/getTaskRulesConfigList`;
// 添加/修改签到规则
export const URL_UPDATE_SIGN_IN_MANAGE = `${PREFIX}/web/saveTaskRulesConfig`;
// 通过Id获取签到规则
export const URL_QUERY_ID_SIGN_IN_MANAGE = `${PREFIX}/web/getTaskRulesConfigById`;
// 签到规则删除
export const URL_DELETE_SIGN_IN_MANAGE_BY_ID = `${PREFIX}/web/delTaskRulesConfig`;
// 上下架签到规则
export const URL_OFF_ON_SIGN_IN_MANAGE = `${PREFIX}/web/updateTaskRulesState`;

// 盈亏查询
export const URL_QUERY_PROFITLOSS = `${PREFIX}/web/selectProfitAndLossByGameIdAndTime`;

// 物品审核 获取审核数据
export const URL_QUERY_AUDIT_LIST = `${PREFIX}/web/selectAuditList`;
// 审核
export const URL_AUDIT_CONFIRM = `${PREFIX}/web/auditConfirm`;
// 挂起
export const URL_APPLY_SHIPMENT_PENDING = `${PREFIX}/web/applyShipmentPending`;
//标记颜色
export const URL_APPLY_COLOR_FLAG = `${PREFIX}/web/applyColorFlag`;

// 回收
export const URL_USER_RECYCLING_RECORDS = `${PREFIX}/web/selectReturnGoodsInfoList`;

// 查询物品池子列表
export const URL_QUERY_ITEM_POOL = `${PREFIX}/web/selectMainProductList`;
// 添加/修改物品池子
export const URL_UPDATE_ITEM_POOL = `${PREFIX}/web/saveMainProductConfig`;
// 通过Id获取物品池子
export const URL_QUERY_ITEM_POOL_DETAILS = `${PREFIX}/web/getMainProductConfigById`;
// 删除物品池子
export const URL_DELETE_ITEM_POOL_BY_ID = `${PREFIX}/web/delMainProductConfigById`;

// 查询隐藏池子列表
export const URL_QUERY_HIDDEN_POOL = `${PREFIX}/web/selectHiddenPoolList`;
// 添加/修改隐藏池子
export const URL_UPDATE_HIDDEN_POOL = `${PREFIX}/web/saveHiddenPoolConfig`;
// 通过Id获取隐藏池子
export const URL_QUERY_HIDDEN_POOL_DETAILS = `${PREFIX}/web/getHiddenPoolConfigById`;
// 删除隐藏池子
export const URL_DELETE_HIDDEN_POOL_BY_ID = `${PREFIX}/web/delHiddenPoolConfigById`;
// 上下架隐藏池子
export const URL_OFF_ON_HIDDEN_POOL = `${PREFIX}/web/rackUpAndDownHiddenPoolConfig`;

// 名人堂记录
export const URL_QUERY_PIRIZE_RECORDS = `${PREFIX}/web/getUnlimitedDrawBigPrizeRecordsByGameId`;

// 用户名人堂记录
export const URL_QUERY_USER_PIRIZE_RECORDS = `${PREFIX}/web/getUnlimitedDrawBigPrizeRecordsByAccountNo`;

// 运维表头
export const URL_QUERY_DAILY_OPERATION_TITLE = `${PREFIX}/dailyOperation/queryDailyOperationTitle`;

// 运维数据
export const URL_QUERY_DAILY_OPERATION_DATA = `${PREFIX}/dailyOperation/queryDailyOperationData`;

// 下载数据
export const URL_EXPORT_DAILY_OPERATION_DATA = `${PREFIX}/dailyOperation/exportDailyOperationData`;

// 获取行政区划地址列表
export const URL_QUERY_ADMINISTRATIVE_DIVISION_OPTIONS = `${PREFIX}/common/getAddressList`;
//获取用户地址
export const URL_QUERY_USER_DEFAULT_ADDRESS = `${PREFIX}/web/getDefaultAddressByAccountNo`;
//获取用户地址列表
export const URL_QUERY_USER_ADDRESS_LIST = `${PREFIX}/web/getUserAddressListByAccountNo`;

export const SUPERIOR_ADMINISTRATIVE_DIVISION_CODE = 0;

//券码列表
export const URL_QUERY_EXCHANGE_CODE_LIST = `${PREFIX}/game/getExchangeCodeInfoList`;
//导出卷码列表
export const URL_EXPORT_EXCHANGE_CODE_LIST = `${PREFIX}/game/exportExchangeCodeInfoList`;
// 查询优惠卷列表
export const URL_QUERY_EXCHANGE_LIST = `${PREFIX}/game/getExchangeCodeDefineList`;
//优惠价上架
export const URL_ON_SALE_EXCHANGE = `${PREFIX}/game/onTheShelfExchangeCodeDefine`;
//优惠卷下架
export const URL_OFF_SHELVE_EXCHANGE = `${PREFIX}/game/takeDownExchangeCodeDefine`;
//查询大奖
export const URL_QUERY_GRANDO_PRIZE_GRADE_LIST = `${PREFIX}/web/getGrandPrizeList`;

// 查询批量上架模版
export const URL_QUERY_BATECH_LISTING = `${PREFIX}/game/getBatchShelfTemplateList`;
// 通过Id获取批量上架模版
export const URL_QUERY_ID_BATECH_LISTING = `${PREFIX}/game/getBatchShelfTemplateById`;
// 添加/修改批量上架模版
export const URL_SAVE_BATECH_LISTING = `${PREFIX}/game/saveBatchShelfTemplate`;
// 批量上架模版删除
export const URL_DELETE_BATECH_LISTING = `${PREFIX}/game/delBatchShelfTemplateById`;
// 上下架批量上架模版
export const URL_OFF_ON_BATECH_LISTING = `${PREFIX}/game/onBatchShelfTemplate`;

// 待发货物品详情
export const URL_QUERY_PRODUCT_INGO_BY = `${PREFIX}/web/getProductInfoByToBeShipped`;

// 下载待发货物品详情
export const URL_EXPORT_PRODUCT_INGO_BY = `${PREFIX}/web/exportProductInfoByToBeShipped`;

// 用户领取记录管理
export const URL_QUERY_GAME_USER_COLLLECTUON_RECORDS = `${PREFIX}/web/getAccountReceivedData`;

// 查询排行榜奖励配置相关信息
export const URL_QUERY_RANKING_REWARD_LIST = `${PREFIX}/web/selectLeaderboardRewardConfig`;
// 添加/修改排行榜奖励信息
export const URL_UPDATE_QUERY_RANKING_REWARD_GAME = `${PREFIX}/web/saveLeaderboardRewardConfig`;
// 排行榜奖励删除
export const URL_DELETE_GAME_RANKING_REWARD_BY_ID = `${PREFIX}/web/delLeaderboardRewardById`;
// 上下架排行榜奖励
export const URL_OFF_ON_RANKING_REWARD_GAME = `${PREFIX}/web/rackUpAndDownLeaderboardReward`;
// 通过Id获取排行榜奖励配置
export const URL_QUERY_RANKING_REWARD_DETAILS = `${PREFIX}/web/getLeaderboardRewardConfigById`;

// 厨神池铲子记录
export const URL_QUERY_SHOVEL_RECORD = `${PREFIX}/web/getShovelByCondition`;

// 查询批量上架模版记录
export const URL_QUERY_BATECH_LISTING_RECORD = `${PREFIX}/game/getBatchShelfRecords`;
// 查询批量上架模版记录详情
export const URL_QUERY_ID_BATECH_LISTING_RECORD = `${PREFIX}/game/getBatchShelfRecordsInfo`;

// 饭票抽赏模版查询
export const URL_QUERY_MEALTICKET_GAME = `${PREFIX}/game/getMealTicketDrawDefineList`;
// 饭票通过模版Id获取详情
export const URL_QUERY_MEALTICKET_GAME_ID = `${PREFIX}/game/getMealTicketDrawDefineByDefineId`;
// 新增/修改饭票抽赏模版
export const URL_UPDATA_MEALTICKET_GAME = `${PREFIX}/game/addOrUpdateMealTicketDrawDefine`;
// 饭票抽赏模版上架
export const URL_ON_SALE_MEALTICKET_GAME = `${PREFIX}/game/onTheShelfMealTicketDrawDefine`;
// 饭票抽赏模版删除
export const URL_DELETE_MEALTICKET_GAME = `${PREFIX}/web/deleteGameDefineById`;

//运维类型 /dailyOperation/queryDailyOperationType
export const URL_OPERATION_TYPE = `${PREFIX}/dailyOperation/queryDailyOperationType`;
// 修改商品记录查询
export const URL_QUERY_PRODUCT_RECORDS = `${PREFIX}/product/getModifyProductList`;

// 废弃模版
export const URL_ADANDON_GAME_DEFIN = `${PREFIX}/game/abandonGameDefine`;

export const URL_ADANDON_PRODUCT_DEFIN = `${PREFIX}/product/updateProductIsAbandon`;

//相关模版
export const URL_QUERY_RELATEDTEMPLATE = `${PREFIX}/product/findProductRelationInfo`;

//更新商品状态 预售变现货
export const URL_UPDATE_PRESALE = `${PREFIX}/product/updateProductState`;

// 新预售变现货,第二个接口
export const URL_SYNC_PRESALE = `${PREFIX}/weChat/action/syncPresale`;

//查询出所有的配置信息
export const URL_QUERY_ALL_CONDITION = `${PREFIX}/web/findAllSystemConfigByCondition`;

//修改配置信息
export const URL_UPDATE_CONDITION = `${PREFIX}/web/updateSystemConfig`;

// 通过模版Id获取配置信息详情
export const URL_QUERY_CONDITION_BY_ID = `${PREFIX}/web/findSystemConfigById`;

//塔模版列表信息
export const URL_QUERY_PAGODA_GAME_DEFINE_LIST = `${PREFIX}/game/getTowerDrawDefineList`;

//通过Id获取塔模版详情
export const URL_QUERY_PAGODA_BY_ID = `${PREFIX}/game/getTowerDrawDefineByDefineId`;

//更新/新增塔模版
export const URL_UPDATE_PAGODA_GAME_DEFINE = `${PREFIX}/game/saveTowerDrawDefineByDefineId`;

//塔上架
export const URL_ON_SALE_PAGODA_GAME_DEFINE = `${PREFIX}/game/onTheShelfTowerDrawDefine`;

//塔下架
export const URL_OFF_SHELVE_PAGODA_GAME_DEFINE = `${PREFIX}/game/takeDownTowerDrawDefine`;

//增加塔模版
export const URL_Add_PAGODA_GAME_DEFINE = `${PREFIX}/game/appendTowerFloorGameDefine`;

//查询塔层模版
export const URL_QUERY_PAGODA_INFINITY_GAME_DEFINE_LIST = `${PREFIX}/game/getTowerUnlimitedDrawDefineList`;

//查询商品库存
export const URL_QUERY_STOCK_LIST = `${PREFIX}/product/getStockList`;
//商品库存入库
export const URL_QUERY_ADD_STOCK = `${PREFIX}/product/addStock`;
//商品库存出库
export const URL_QUERY_PUT_STOCK = `${PREFIX}/product/subStock`;
//查看库存操作记录
export const URL_QUERY_STOCK_RECORD = `${PREFIX}/product/getStockOperateRecord`;

/* //网站-置顶商品
export const URL_TOP_PRODUCTINFO = `${PREFIX}/page/topProductInfo`;
//网站-下架商品
export const URL_DOWN_PRODUCTINFO = `${PREFIX}/page/downProductInfo`; */

//导出退款记录
export const URL_EXPORT_WITHDRAW_RECORDS = `${PREFIX}/web/exportWithdrawRecordsPoList`;

//获取控制台数据目录
export const URL_QUERY_CONTROL_DATA_CATALOG = `${PREFIX}/web/getPcHomeAuth`;

// 修改周卡信息
export const URL_UPDATE_WELFARE_CARD = `${PREFIX}/web/updateWelfareCard`;

// 查看周卡详情
export const URL_QUERY_WELFARE_CARD_LIST = `${PREFIX}/web/getWelfareCard`;

// 新增周卡
export const URL_ADD_WELFARE_CARD = `${PREFIX}/web/addWelfareCard`;

// 上架周卡
export const URL_UPLOAD_CARD = `${PREFIX}/web/upLoadWelfareCard`;

//删除周卡
export const URL_DELETE_CARD = `${PREFIX}/web/deleteWelfareCard`;
//恢复周卡
export const URL_RECOVER_CARD = `${PREFIX}/web/updateDeleteStatus`;
// 下架周卡
export const URL_DOWN_CARD = `${PREFIX}/web/downLoadWelfareCard`;
// 获取优惠卡购买记录
export const URL_GET_CARD_RECORD_LIST = `${PREFIX}/web/getUserBuyRecord`;

// 获取优惠卡名称
export const URL_GET_CARD_NAME_LIST = `${PREFIX}/web/getAllCardName`;

//已发货订单修改商品价格
export const URL_UPDATA_SHIPPED_AMOUNT = `${PREFIX}/web/updateShippedAmount`;
// 导入奖励配置
export const URL_UPLOAD_REWARDS_LIST = `${PREFIX}/welfareCardFileUpload`;
// 下载模版
export const URL_DOWNLOAD_TEMPLATE =
  'https://cdn.szconst.com/mwUpload/welfareCardDemo.xlsx';
// 代买优惠卡
export const URL_PURCHASE_ON_BEHALF_OF = `${PREFIX}/web/giveWelfareCard`;
//备注用户
export const URL_UPDATA_COMMENTNAME = `${PREFIX}/user/updateCommentName`;
//会员评级
export const URL_QUERY_VIPGRADE_LIST = `${PREFIX}/user/selectUserGradeList`;
//新增用户评级
export const URL_INSERT_GVIPGRADE = `${PREFIX}/user/insertGrade`;
//修改用户评级
export const URL_UPDATE_GVIPGRADE = `${PREFIX}/user/updateGrade`;
// 周期活动模版管理相关接口
export const URL_QUERY_ACTIVITY_LIST = `${PREFIX}/web/getActivityList`;
export const URL_QUERY_ACTIVITY_INFO_BY_ID = `${PREFIX}/web/getActivityCycleSettingById`;
export const URL_UPDATE_ACTIVITY_INFO = `${PREFIX}/web/saveActivityCycleSetting`;
export const URL_ON_SHELF_ACTIVITY = `${PREFIX}/web/onTheShelfActivity`;
export const URL_OFF_ACTIVITY = `${PREFIX}/web/offShelfActivity`;
export const URL_REMOVE_ACTIVTIY = `${PREFIX}/web/cancelActivity`;
export const URL_RESET_ACTIVITY = `${PREFIX}/web/resetCancelActivity`;

//用户指引查询
export const URL_QUERY_USERGUIDE_LIST = `${PREFIX}/web/selectMenuInstructionList`;
//新增用户指引
export const URL_INSERT_USERGUIDE = `${PREFIX}/web/addMenuInstruction`;
//修改用户指引
export const URL_UPDATE_USERGUIDE = `${PREFIX}/web/updateMenuInstruction`;
//删除用户指引
export const URL_DELETE_USERGUIDE = `${PREFIX}/web/deleteMenuInstruction`;
//查询已有的用户指引
export const URL_QUERY_ALL_MENU_INFO = `${PREFIX}/web/getAllMenuInfoList`;
// 查询酷圈审核列表
export const URL_GET_ARTICLE_LIST = `${PREFIX}/discuss/getArticleAuditList`;
// 酷圈审核
export const URL_AUDIT_ARTICLE = `${PREFIX}/discuss/auditArticle`;
//合成池列表
export const URL_QUERY_CUBEPOOL_LIST = `${PREFIX}/web/getRedeemItemConfigList`;
//编辑合成池
export const URL_EDIT_CUBEPOOL_LIST = `${PREFIX}/web/saveRedeemItemConfig`;
//删除合成池
export const URL_DEL_CUBEPOOL_LIST = `${PREFIX}/web/delRedeemItemConfig`;
// 通过Id获取合成池
export const URL_QUERY_CUBEPOOL_DETAILS = `${PREFIX}/web/getRedeemItemConfigById?`;
//获取可合成信息列表
export const URL_QUERY_CUBE_COMPOUND_LIST = `${PREFIX}/web/getRedeemKnapsackByAccountNo`;
//合成物品
export const URL_COMPOUND_CUBE = `${PREFIX}/web/redeemKnapsackByAccountNo`;
//查看合成记录
export const URL_QUERY_CUBE_RECORDS = `${PREFIX}/web/getRedeemItemRecords`;
//合成池上下架
export const URL_CUBE_UP_DOWN = `${PREFIX}/web/onTheShelfRedeemItemConfig`;
//查看明细
export const URL_QUERY_CUBE_RECORDS_DETAIL = `${PREFIX}/web/getRedeemItemDtlRecords`;
//打印订单详情
export const URL_QUERY_ORDER_DETAIL = `${PREFIX}/web/getConsignmentByGroup`;

export const URL_UPDATE_USERINFO = `${PREFIX}/web/changeNameOrPhoneNum`;
// 厨神币
export const URL_TOPUP_BY_TOKENS = `${PREFIX}/user/addTokensByWalletId`;
// 获取大奖奖项
export const URL_QUERY_BIG_PRIZE = `${PREFIX}/dailyOperation/getBigPrizeList`;
//登入
export const URL_QUERY_LOGIN = `/core${GO_PREFIX}/qr/returnWxConfigInfo`;
// 获取怒气数量
export const URL_QUERY_RAGE_NUM = `${PREFIX}/user/getAccountRageInfo`;
// 最高塔层记录
export const URL_TOWER_FLOOR_RECORD = `${PREFIX}/user/getAccountTowerGameRecord`;

//商品上架 
export const URL_ON_PRODUCT_SPEC = `${PREFIX}/product/upLoad`;

//商品下架 
export const URL_OFF_PRODUCT_SPEC = `${PREFIX}/product/downLoad`;

//商品补充库存
export const URL_PRODUCT_REPLENISH_STOCK = `${PREFIX}/product/restocking`;

//加入交易所黑名单
export const JOIN_BOURSE_BLACKLIST = `${PREFIX}/user/suspendAddedOrder`;
//移出交易所黑名单  
export const REMOVE_BOURSE_BLACKLIST = `${PREFIX}/user/unblockAddedOrder`;

//加入拍卖行黑名单
export const JOIN_ACTION_BLACKLIST = `${PREFIX}/user/suspendAction`;
//移出拍卖行黑名单  
export const REMOVE_ACTION_BLACKLIST = `${PREFIX}/user/unblockAction`;

//商品补充库存
export const URL_UPLOAD_COVER_IMAGE = `${PREFIX}/web/uploadCoverImage`;

//获取商品封面和详情封面
export const URL_GET_PRODUCT_IMG_DETAILS = `${PREFIX}/product/getProductImgDetails`;

//修改商品封面和详情封面
export const URL_UPDATE_PRODUCT_IMG_DETAILS = `${PREFIX}/product/updateProductImgDetails`;

//获取隐藏商品概率配置列表
export const URL_HIDDEN_POST_CONFIG_LIST = `${PREFIX}/web/queryHiddenPostConfigList`;

//获取隐藏商品概率配置详情
export const URL_GET_HIDDEN_POST_CONFIG_BY_ID = `${PREFIX}/web/getHiddenPostConfigById`;

//删除隐藏商品概率配置
export const URL_DEL_HIDDEN_POST_CONFIG_BY_ID = `${PREFIX}/web/delHiddenPostConfigById`;

//上下架隐藏商品概率配置
export const URL_UP_AND_DOWN_HIDDEN_POST_CONFIG = `${PREFIX}/web/rackUpAndDownHiddenPostConfig`;

//新增/修改隐藏商品概率配置
export const URL_SAVE_HIDDEN_POST_CONFIG = `${PREFIX}/web/saveHiddenPostConfig`;

// 获取封面与协议相关配置
export const URL_FIND_SYSTEM_CONFIG_GIVEN_BY_ID = `${PREFIX}/web/findSystemConfigGivenById`;

//下载文本文档
export const URL_DOWNLOAD_TXT = `${PREFIX}/web/fileSystemConfig/download`;

// 得到小程序配置列表
export const URL_QUERY_OUTER_ADVERTISING_CONFIG_LIST = `${PREFIX}/web/getOuterAdvertisingConfigList`;

// 增加小程序信息
export const URL_ADD_OUTER_ADVERTISING_CONFIG = `${PREFIX}/web/addOuterAdvertisingConfig`;

// 修改小程序信息
export const URL_UPDATE_OUTER_ADVERTISING_CONFIG = `${PREFIX}/web/updateOuterAdvertisingConfig`;

// 删除小程序信息
export const URL_DEL_OUTER_ADVERTISING_CONFIG = `${PREFIX}/web/deleteOuterAdvertisingConfig`;

// 获取小程序评价列表
export const URL_QUERY_ADVERTISING_COMMENT_LIST = `${PREFIX}/web/getAdvertisingCommentList`;

// 待发货订单导出
export const URL_DOWN_WAIT_EXCEL_LIST = `${PREFIX}/web/downWaitExcelList`;