import { GameType } from "../../../../types/game";
interface Type {
  option: string,
  value: string,
}

export const FINAL_REWARD = ["138","137","136","135", "133"];

export const mealticketGameTypesOptions: GameType[] = [
  {
    id: '15',
    name: '饭票普通抽'
  },
  {
    id: '16',
    name: '饭票限抽'
  },
  {
    id: '14',
    name: '饭票无限'
  },
];

export const sortsOptions: Type[] = [
  {
    option: '升序',
    value: 'asc'
  },
  {
    option: '降序',
    value: 'desc'
  },
];

export const abandonStateOptions: GameType[] = [
  {
    id: '1',
    name: '未废弃'
  },
  {
    id: '2',
    name: '废弃'
  },
];