import { GameType } from "../../../../types/game";
interface Type {
  option: string,
  value: string,
}

export const GAME_TYPE_BOX = '05';
export const GAME_TYPE_INTEGRAL = '07';
export const GAME_TYPE_INTEGRAL_SINGLE = '08';
export const GAME_TYPE_INTEGRAL_INFINITE = '09';
export const GAME_TYPE_WELFARE = '11';
export const GAME_TYPE_LIMIT_BOX = '13';

export const gameTypesOptions: GameType[] = [
  {
    id: '01',
    name: '竞技套'
  },
  {
    id: '02',
    name: '单抽套'
  },
  {
    id: '03',
    name: '限抽套',
  },
  {
    id: '04',
    name: '循环套',
  },
  {
    id: GAME_TYPE_LIMIT_BOX,
    name: '限抽套盒',
  },
  {
    id: GAME_TYPE_BOX,
    name: '套盒',
  },
  {
    id: GAME_TYPE_WELFARE,
    name: '大富翁',
  }
  // {
  //   id: GAME_TYPE_INTEGRAL,
  //   name: '积分抽赏',
  // },
  // {
  //   id: GAME_TYPE_INTEGRAL_SINGLE,
  //   name: '积分单抽',
  // },
  // {
  //   id: GAME_TYPE_INTEGRAL_INFINITE,
  //   name: '积分无限抽',
  // }
];

export const sortsOptions: Type[] = [
  {
    option: '最小票价在前面',
    value: 'asc'
  },
  {
    option: '最大票价在前面',
    value: 'desc'
  },
];

export const EACH_DRAW = 1;
export const NO_AWARDS = 2;
export const SOLD_OUT_PURCHASES = 3;
export const SOLD_OUT_JOJN=4

export const hiddenPoolTypesOptions: GameType[] = [
  {
    id: EACH_DRAW,
    name: '每一抽'
  },
  {
    id: NO_AWARDS,
    name: '个人未出奖项'
  },
  // {
  //   id: SOLD_OUT_PURCHASES,
  //   name:'售空时根据购买次数'
  // }
];

export const awardIdListOptions: GameType[] = [
  {
    id: '77',
    name: '全局'
  },
  {
    id: '81',
    name: 'First'
  },
  {
    id: '82',
    name: 'Last'
  },
];

export const WELFARE_COMMODITY = 1;
export const WELFARE_START = 4;
export const welfareTypesOptions: GameType[] = [
  {
    id: WELFARE_COMMODITY,
    name: '商品'
  },
  {
    id: 2,
    name: '前进'
  },
  {
    id: 3,
    name: '后退'
  },
  {
    id: WELFARE_START,
    name: '起点'
  },
];

export const abandonStateOptions: GameType[] = [
  {
    id: '1',
    name: '未废弃'
  },
  {
    id: '2',
    name: '废弃'
  },
];
