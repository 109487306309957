import { GameState, GameStateNum } from "../types/game";

export const GAME_STATE_ON_SALE: GameState = '1';
export const GAME_STATE_OFF_SALE: GameState = '2';
export const GAME_STATE_FORBID_SALE:GameState='3'


export const GAME_STATE_ON_SALE_NEW: GameStateNum = 1;
export const GAME_STATE_OFF_SALE_NEW: GameStateNum = 2;

export const LOGIN_TOKEN_KEY = 'token';

export const LOGIN_USER_ROLE = 'role';
export const LOGIN_BROWSER_ID = 'browserId'
export const LOGIN_USER_ID='userId'
export const ROLE_UN_DEVICE = 'unDevice'; //未知设备
export const ROLE_UN_LOGIN = 'unLogin'; //未登入
export const ROLE_ADMIN = 'admin'; // 管理员
export const ROLE_GUEST = 'guest'; // 游客
export const ROLE_EMPLOYEE = 'employee'; // 普通客服
export const ROLE_SENIOR_EMPLOYEE = 'senior_employee'; // 高级客服
export const ROLE_SHIPPER = 'shipper'; // 发货人员
export const ROLE_SENIOR_ADMIN = 'senior_admin'; // 高级管理员
export const ROLE_SENIOR_ADMIN_ADD = 'senior_admin_add'; // 高级管理员  有兑换券
export const ROLE_SENIOR_SHIPPER = 'senior_shipper'; // 高级发货人员
export const ROLE_EMPLOYEE_SENIOR_SHIPPER = 'employee_senior_shipper'; // 普通客服 + 高级发货人员 + 商品查看
export const ROLE_HIGH_GUEST = 'high_guest'
export const ROLE_COMMODITY_SELECT = 'commodity_select'; // 商品查看
export const ROLE_OPERATOR = 'operator'; // 运维人员
export const ROLE_WITHDRAW = 'withdraw'; //退款人员
